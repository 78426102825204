import styles from "./TimesheetPage.module.scss";
import { Week } from "../Week/Week";
import { TimesheetModel } from "../../common/Models/TimesheetModel";
import { IntervalModel } from "../../common/IntervalModel";
import { CreateTimesheetModel } from "../../common/Models/CreateTimesheetModel";
import { FillWeekTimesheet } from "../../common/Models/FillWeekTimesheet";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";

export interface TimesheetPageProps {
  authError: boolean;
  days: TimesheetModel[];
  isFired: boolean;
  hours: number;
  scheduleBegin: string;
  scheduleEnd: string;
  shiftBegin: string;
  shiftEnd: string;
  onValidate: (week: IntervalModel) => void;
  onAdd: (timesheet: CreateTimesheetModel) => void;
  onUpdate: (timesheet: CreateTimesheetModel) => void;
  onGet: (begin: Date, end: Date) => void;
  onFillWeek: (week: FillWeekTimesheet) => void;
  onSetStartDate: (begin: Date) => void;
  onSetEndDate: (end: Date) => void;
  startDate: Date;
  endDate: Date;
  workTypes: WorkTypesModel[];
  onUpdateWorkType: (date: string, workTypeId: WorkTypesModel) => void;
}

export const TimesheetPage = ({
  authError,
  days,
  isFired,
  hours,
  scheduleBegin,
  scheduleEnd,
  shiftBegin,
  shiftEnd,
  onAdd,
  onUpdate,
  onValidate,
  onGet,
  onFillWeek,
  onSetStartDate,
  onSetEndDate,
  onUpdateWorkType,
  startDate,
  endDate,
  workTypes,
}: TimesheetPageProps) => {
  return (
    <div className={styles.TimesheetPage}>
      <Week
        authError={authError}
        days={days}
        isFired={isFired}
        hours={hours}
        scheduleBegin={scheduleBegin}
        scheduleEnd={scheduleEnd}
        shiftBegin={shiftBegin}
        shiftEnd={shiftEnd}
        validate={onValidate}
        add={onAdd}
        update={onUpdate}
        refreshWeek={onGet}
        onFillWeek={onFillWeek}
        onSetStartDate={onSetStartDate}
        onSetEndDate={onSetEndDate}
        onUpdateWorkType={onUpdateWorkType}
        onStart={startDate}
        onEnd={endDate}
        workTypes={workTypes}
      />
    </div>
  );
};
