import {
  TagButton,
  TagCombobox,
  TagEditField,
  TagForm,
  TagText,
} from "@tag/tag-components-react-v2";
import { useState } from "react";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { ProfileModel } from "../../common/Models/ProfileModel";

type DeleteUserProps = {
  user: ProfileModel | null;
  onDeleteEmployeeAdmin: (id: number, leaveDate: Date) => void;
  closeModal: () => void;
  messageDeleteUser: () => void;
  departments: DepartmentModel[];
  setPageOnDeleteLastUser: () => void;
};
export const DeleteUserForm = ({
  user,
  onDeleteEmployeeAdmin,
  closeModal,
  messageDeleteUser,
  departments,
  setPageOnDeleteLastUser,
}: DeleteUserProps) => {
  const [formData, setFormData] = useState<any>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    managerEmail: undefined,
    workingHours: undefined,
    startDate: undefined,
    scheduleBegin: undefined,
    scheduleEnd: undefined,
    department: undefined,
    marca: undefined,
    leaveDate: undefined
  });

  function handleFormData() {

    console.log(formData, user);
    if(formData.leaveDate) {
      onDeleteEmployeeAdmin(user?.id!, formData.leaveDate);
      messageDeleteUser();
      closeModal();
      setPageOnDeleteLastUser();
    }
  }

  const formStyle: React.CSSProperties = { border: "0px" };
  const editFieldStyle: React.CSSProperties = {
    marginTop: "15px",
  };

  return (
    <div>
      <TagForm
        submitButtonHidden
        submitButtonText="Delete"
        submitButtonAccent="access"
        submitButtonAlign="right"
        formStyle={formStyle}
        inline
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            name="firstName"
            label="First Name"
            editor="textbox"
            value={formData.firstName ? formData.firstName : user?.firstName}
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            name="lastName"
            label="Last Name"
            editor="textbox"
            value={formData.lastName ? formData.lastName : user?.lastName}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            name="email"
            label="Email"
            editor="email"
            value={formData.email ? formData.email : user?.email}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            name="managerEmail"
            label="Manager Email"
            editor="email"
            value={
              formData.managerEmail ? formData.managerEmail : user?.managerEmail
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="marca"
            label="Marca"
            editor="textbox"
            disabled={true}
            value={formData.marca ? formData.marca : user?.marca}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TagText
              text="Department"
              textStyle={{ fontWeight: "bold", marginBottom: "3px" }}
            />
            <TagCombobox
              inputStyle={{ width: "220px" }}
              inputDisabled
              disabled={true}
              textField="name"
              valueField="id"
              searchable
              placeholder="Select a department"
              value={
                formData.department ? formData.department : user?.department.id
              }
              data={departments}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            containerStyle={editFieldStyle}
            disabled={true}
            name="startDate"
            label="Start Date"
            editor="datepick"
            value={formData.startDate ? formData.startDate : user?.startDate}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            name="workingHours"
            label="Working Hours"
            editor="number"
            value={
              formData.workingHours ? formData.workingHours : user?.workingHours
            }
          />
        </div>
        <div>
          <TagEditField
            containerStyle={editFieldStyle}
            name="LeaveDate"
            label="Leave Date"
            editor="datepick"
            value={formData.leaveDate ?? user?.leaveDate}
            onValueChange={e => 
              setFormData((formData: any) => ({
              ...formData,
              leaveDate: e.detail.value,
            }))}
          />
        </div>

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            placeholder="Ex: 09:00"
            name="scheduleBegin"
            label="Schedule Begin"
            editor="textbox"
            value={
              formData.scheduleBegin
                ? formData.scheduleBegin
                : user?.scheduleBegin.substring(0, 5)
            }
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            placeholder="Ex: 17:00"
            name="scheduleEnd"
            label="Schedule End"
            editor="textbox"
            value={
              formData.scheduleEnd
                ? formData.scheduleEnd
                : user?.scheduleEnd.substring(0, 5)
            }
          />
        </div> */}

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            placeholder="Office Worker/Home Worker"
            name="workPractice"
            label="Work Practice"
            editor="textbox"
            value="Office Worker"
            // value={
            //   formData.scheduleBegin
            //     ? formData.scheduleBegin
            //     : user?.scheduleBegin.substring(0, 5)
            // }
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            disabled={true}
            placeholder="Timisoara"
            name="officeLocation"
            label="Office Location"
            editor="textbox"
            value="Timisoara"
            // value={
            //   formData.scheduleEnd
            //     ? formData.scheduleEnd
            //     : user?.scheduleEnd.substring(0, 5)
            // }
          />
        </div> */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginLeft: "10px",
            }}
            text="Delete"
            icon="Delete"
            iconAccent="white"
            accent="access"
            onButtonClick={() => {
              handleFormData();
              setFormData({
                firstName: undefined,
                lastName: undefined,
                department: undefined,
                email: undefined,
                managerEmail: undefined,
                workingHours: undefined,
                startDate: undefined,
                scheduleBegin: undefined,
                scheduleEnd: undefined,
                marca: undefined,
                leaveDate: undefined
              });
            }}
          />
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              closeModal();
              setFormData({
                firstName: undefined,
                lastName: undefined,
                department: undefined,
                email: undefined,
                managerEmail: undefined,
                workingHours: undefined,
                startDate: undefined,
                scheduleBegin: undefined,
                scheduleEnd: undefined,
                marca: undefined,
                leaveDate: undefined
              });
            }}
          ></TagButton>
        </div>
      </TagForm>
    </div>
  );
};
