import {
  IChangeArgs,
  TagAlert,
  TagButton,
  TagEditField,
  TagForm,
  TagList,
  TagToast,
} from "@tag/tag-components-react-v2";
import { Divider } from "antd";
import moment from "moment";
import { useState } from "react";
import { ProfileModel } from "../../common/Models/ProfileModel";
import { UpdateWorkHours } from "../../common/Models/UpdateWorkHours";
import { WorkingHoursModel } from "../../common/Models/WorkingHoursModel";

type EditUserFormExtensionProps = {
  closeModal: () => void;
  onUpdateWorkHours: (id: number, workHours: UpdateWorkHours) => void;
  user: ProfileModel | null;
  messageAddUser: () => void;
};

export const EditUserFormExtension = ({
  closeModal,
  onUpdateWorkHours,
  user,
  messageAddUser,
}: EditUserFormExtensionProps) => {
  const [workingHourUpdates, setWorkingHoursUpdates] = useState<any>({
    from: undefined,
    value: undefined,
    scheduleBegin: undefined,
    scheduleEnd: undefined,
  });

  const handleInputChange = (e: CustomEvent<IChangeArgs>) => {
    setWorkingHoursUpdates((curObj: any) => {
      return {
        ...curObj,
        [e.detail.name]: e.detail.value,
      };
    });
  };

  const [startDateSelected, setStartDateSelected] = useState<boolean>(false);
  const [workingHoursSelected, setWorkingHoursSelected] =
    useState<boolean>(false);
  const [scheduleBeginSelected, setScheduleBeginSelected] =
    useState<boolean>(false);
  const [scheduleEndSelected, setScheduleEndSelected] =
    useState<boolean>(false);
  const [errorSchedule, setErrorSchedule] = useState<boolean>(false);

  const handleButton = () => {
    const workHours: UpdateWorkHours = {
      from: workingHourUpdates.from?.toDateString(),
      value: workingHourUpdates.value,
      scheduleBegin: workingHourUpdates.scheduleBegin,
      scheduleEnd: workingHourUpdates.scheduleEnd,
    };

    onUpdateWorkHours(user?.id!, workHours);
    closeModal();
    messageAddUser();
  };

  const formStyle: React.CSSProperties = { border: "0px" };
  const editFieldStyle: React.CSSProperties = {
    marginTop: "15px",
  };

  return (
    <div>
      <TagForm
        submitButtonHidden
        submitButtonText="Confirm"
        submitButtonAccent="viridiangreen"
        submitButtonAlign="right"
        formStyle={formStyle}
        inline
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="value"
            label="Working Hours"
            editor="number"
            value={workingHourUpdates.value}
            onValueChange={(e) => {
              handleInputChange(e);
              setWorkingHoursSelected(true);
            }}
            validation={[{ rule: "matches", args: "[2,4,6,8]$" }]}
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="from"
            label="Starting from"
            editor="datepick"
            value={workingHourUpdates.from}
            onValueChange={(e) => {
              handleInputChange(e);
              setStartDateSelected(true);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Ex: 09:00"
            name="scheduleBegin"
            label="Schedule Begin"
            editor="textbox"
            value={workingHourUpdates.scheduleBegin}
            onValueChange={(e: CustomEvent<IChangeArgs>) => {
              handleInputChange(e);
              setScheduleBeginSelected(true);
            }}
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Ex: 09:00"
            name="scheduleEnd"
            label="Schedule End"
            editor="textbox"
            value={workingHourUpdates.scheduleEnd}
            onValueChange={(e: CustomEvent<IChangeArgs>) => {
              handleInputChange(e);
              setScheduleEndSelected(true);
            }}
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
          }}
        >
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginLeft: "10px",
            }}
            disabled={
              workingHoursSelected &&
              startDateSelected &&
              scheduleBeginSelected &&
              scheduleEndSelected
                ? false
                : true
            }
            text="Save"
            icon="CircleTick"
            iconAccent="white"
            accent={
              workingHoursSelected &&
              startDateSelected &&
              scheduleBeginSelected &&
              scheduleEndSelected
                ? "viridian-green"
                : "navy"
            }
            onButtonClick={() => {
              const diff = moment
                .utc(
                  moment(workingHourUpdates.scheduleEnd, "HH:mm:ss").diff(
                    moment(workingHourUpdates.scheduleBegin, "HH:mm:ss")
                  )
                )
                .format("HH:mm:ss");
              const hours = moment.duration(diff).asHours();
              if (hours == workingHourUpdates.value) {
                setErrorSchedule(false);
                handleButton();
                setWorkingHoursUpdates({
                  from: undefined,
                  value: undefined,
                  scheduleBegin: undefined,
                  scheduleEnd: undefined,
                });
              } else {
                setErrorSchedule(true);
              }
            }}
          />
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              closeModal();
              setErrorSchedule(false);
              setWorkingHoursUpdates({
                from: undefined,
                value: undefined,
                scheduleBegin: undefined,
                scheduleEnd: undefined,
              });
            }}
          ></TagButton>
        </div>
        <Divider orientation="left" className="pt-4">
          Working Hours History
        </Divider>
        <div
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {user?.workingHoursHistory ? (
            <TagList
              containerStyle={{ minHeight: "20px" }}
              leftIcon="Clock"
              leftIconAccent="atoll"
              leftIconRoundedBackground
              primaryField="from"
              primaryFieldType="date"
              leftField1="value"
              leftField1Inline
              data={user?.workingHoursHistory}
            />
          ) : (
            <></>
          )}
        </div>

        {errorSchedule ? (
          <TagToast position="bottom-left">
            <TagAlert
              alertStyle={{ maxWidth: "300px" }}
              type={"warning"}
              text={"Schedule doesn't match the selected Working Hours"}
              alertType="toast"
              onClosed={() => {
                setErrorSchedule(false);
                setWorkingHoursUpdates({
                  from: undefined,
                  value: undefined,
                  scheduleBegin: undefined,
                  scheduleEnd: undefined,
                });
              }}
            ></TagAlert>
          </TagToast>
        ) : (
          <></>
        )}
      </TagForm>
    </div>
  );
};
