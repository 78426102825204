import { useState } from "react";
import styles from "./Week.module.scss";
import {
  TagAlert,
  TagButton,
  TagIcon,
  TagList,
  TagModal,
  TagText,
  TagToggle,
  TagTooltip,
} from "@tag/tag-components-react-v2";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { TimesheetModel } from "../../common/Models/TimesheetModel";
import { TimesheetNavbar } from "../../components/Week/TimesheetNavbar";
import {
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  isThisMonth,
} from "date-fns";
import { IntervalModel } from "../../common/IntervalModel";
import { CreateTimesheetModel } from "../../common/Models/CreateTimesheetModel";
import { ParseDate } from "../../services/timesheet.service";
import { FillWeekTimesheet } from "../../common/Models/FillWeekTimesheet";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";
import { TimePicker } from "antd";
import { ToggleChangeForm } from "./ToggleChangeForm";

const { RangePicker } = TimePicker;
const format = "HH:mm";

var weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export interface WeekProps {
  authError: boolean;
  isFired: boolean;
  days: TimesheetModel[];
  hours: number;
  scheduleBegin: string;
  scheduleEnd: string;
  shiftBegin: string;
  shiftEnd: string;
  validate: (week: IntervalModel) => void;
  add: (timesheet: CreateTimesheetModel) => void;
  update: (timesheet: CreateTimesheetModel) => void;
  onFillWeek: (week: FillWeekTimesheet) => void;
  refreshWeek: (begin: Date, end: Date) => void;
  onSetStartDate: (begin: Date) => void;
  onSetEndDate: (end: Date) => void;
  onStart: Date;
  onEnd: Date;
  workTypes: WorkTypesModel[];
  onUpdateWorkType: (date: string, workTypeId: WorkTypesModel) => void;
}

export const Week = ({
  authError,
  days,
  isFired,
  hours,
  scheduleBegin,
  scheduleEnd,
  shiftBegin,
  shiftEnd,
  refreshWeek,
  update,
  add,
  onFillWeek,
  onSetStartDate,
  onSetEndDate,
  onUpdateWorkType,
  onStart,
  onEnd,
  workTypes,
}: WeekProps) => {
  const [submit, setSubmit] = useState<boolean>(false);
  const [ackError, setAckError] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fill, setFill] = useState<boolean>(false);
  const [toggleChange, setToggleChange] = useState<boolean>(false);
  const [errorLocation, setErrorLocation] = useState<boolean>(false);
  const [toggleChangeModal, setToggleChangeModal] = useState(false);
  const [toggleOption, setToggleOption] = useState<WorkTypesModel | null>(null);
  const [currDate, setCurrDate] = useState<string>();

  const handleCurrentWeek = () => {
    refreshWeek(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 })
    );
    onSetStartDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
    onSetEndDate(endOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const handlePrevOrNextDate = (goToFuture: boolean) => {
    if (goToFuture) {
      refreshWeek(addDays(onStart, 7), addDays(onEnd, 7));
      onSetStartDate(addDays(onStart, 7));
      onSetEndDate(addDays(onEnd, 7));
    } else {
      refreshWeek(subDays(onStart, 7), subDays(onEnd, 7));
      onSetStartDate(subDays(onStart, 7));
      onSetEndDate(subDays(onEnd, 7));
    }
  };
  const workHours = days.find((d) => {
    return d.workingHours;
  });

  const hour = workHours?.workingHours ? workHours.workingHours : hours;

  let currentWorkHours = 0;
  const expectedWorkHours = hour * 5;
  for (let i = 0; i < days.length; i++) {
    let total = 0;
    if (days[i].holidayType) total += hour;
    else if (days[i].dayEnd && days[i].dayBegin)
      total = parseInt(days[i].dayEnd) - parseInt(days[i].dayBegin);

    currentWorkHours += total;
  }

  const lastDayOfTheWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
  const today = ParseDate(new Date());

  const dataWork = workTypes.map((w) => {
    return { ...w, label: w.name, value: w.id };
  });
  return (
    <div className={styles.App}>
      <TimesheetNavbar
        startDate={onStart}
        endDate={onEnd}
        handlePrevOrNextDate={handlePrevOrNextDate}
        handleCurrentWeek={() => handleCurrentWeek()}
      />
      <TagList
        renderItem={({
          date,
          dayBegin,
          dayEnd,
          id,
          isValid,
          holidayType,
          workType,
          timesheetValidation,
        }) => (
          <Row style={{ width: "100%", cursor: "default" }}>
            <Col xs="auto" className="d-flex align-items-center">
              {ParseDate(new Date(date)) === today ? (
                <TagIcon
                  style={{ position: "absolute" }}
                  icon="Access"
                  accent="dolphinblue"
                />
              ) : null}
            </Col>
            <Col>
              <TagText text={ParseDate(new Date(date))} />
            </Col>
            <Col>
              <TagText text={weekDays[new Date(date).getDay()]} />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <TagIcon
                style={{ position: "relative" }}
                icon="Circle"
                accent={
                  (dayBegin && dayEnd && !isValid) || holidayType
                    ? "viridiangreen"
                    : "title"
                }
              />
            </Col>
            <Col xs="auto">
              <Row style={{ alignItems: "center", height: "100%" }}>
                {holidayType || isFired ? (
                  <RangePicker
                    disabled={true}
                    format={format}
                    clearIcon={false}
                    value={[
                      moment(scheduleBegin, format),
                      moment(scheduleEnd, format),
                    ]}
                  />
                ) : (
                  <RangePicker
                    disabled={
                      //new Date(date) > new Date(lastDayOfTheWeek) ||
                      !isThisMonth(new Date(date))
                        ? true
                        : false
                    }
                    disabledHours={
                      hours === 8
                        ? () => {
                            const minHour = parseInt(shiftBegin.split(":")[0]);
                            const maxHour = parseInt(shiftEnd.split(":")[0]);
                            return Array.from(
                              { length: 24 },
                              (v, k) => k
                            ).filter(
                              (hour) => hour < minHour || hour > maxHour
                            );
                          }
                        : () => {
                            const minHour = parseInt(scheduleBegin);
                            const maxHour = parseInt(scheduleEnd);
                            return Array.from(
                              { length: 24 },
                              (v, k) => k
                            ).filter(
                              (hour) => hour < minHour || hour > maxHour
                            );
                          }
                    }
                    hideDisabledOptions
                    minuteStep={15}
                    format={format}
                    clearIcon={false}
                    value={[
                      dayBegin ? moment(dayBegin, format) : null,
                      dayEnd ? moment(dayEnd, format) : null,
                    ]}
                    onChange={(dates) => {
                      const begin = dates?.[0]?.format("HH:mm");
                      const end = dates?.[1]?.format("HH:mm");
                      const timesheet = {
                        id: id,
                        date: date,
                        dayBegin: begin ? begin : dayBegin,
                        dayEnd: end ? end : dayEnd,
                        isValid: false,
                      };

                      const diff = moment
                        .utc(
                          moment(end, "HH:mm:ss").diff(
                            moment(begin, "HH:mm:ss")
                          )
                        )
                        .format("HH:mm:ss");
                      const curr_hours = moment.duration(diff).asHours();
                      const working_hours = expectedWorkHours / 5;

                      let dateBegin = new Date(days[0].date);
                      let dateEnd = new Date(days[4].date);
                      refreshWeek(dateBegin, dateEnd);
                      let beginValue = moment(timesheet.dayBegin, "HH:mm");
                      let endValue = moment(timesheet.dayEnd, "HH:mm");
                      let beginHour = moment(shiftBegin, "HH:mm");
                      let endHour = moment(shiftEnd, "HH:mm");

                      if (working_hours < 8) {
                        if (
                          beginValue.diff(beginHour, "hours") >= 0 &&
                          beginValue.diff(scheduleBegin, "hours") == 0 &&
                          endValue.diff(scheduleEnd, "hours") == 0 &&
                          endValue.diff(endHour, "hours") <= 0 &&
                          curr_hours === working_hours
                        ) {
                          update(timesheet);
                          setError(false);
                          setSave(true);
                          refreshWeek(dateBegin, dateEnd);
                        } else if (
                          timesheet.id === null &&
                          beginValue.diff(beginHour, "hours") >= 0 &&
                          endValue.diff(endHour, "hours") <= 0 &&
                          curr_hours === working_hours
                        ) {
                          add(timesheet);
                          setError(false);
                          refreshWeek(dateBegin, dateEnd);
                          setSave(true);
                        } else {
                          setSave(false);
                          setError(true);
                        }
                      } else {
                        if (
                          beginValue.diff(beginHour, "hours") >= 0 &&
                          endValue.diff(endHour, "hours") <= 0 &&
                          curr_hours === working_hours
                        ) {
                          update(timesheet);
                          refreshWeek(dateBegin, dateEnd);
                          setError(false);
                          setSave(true);
                          refreshWeek(dateBegin, dateEnd);
                        } else if (
                          timesheet.id === null &&
                          beginValue.diff(beginHour, "hours") >= 0 &&
                          endValue.diff(endHour, "hours") <= 0 &&
                          curr_hours === working_hours
                        ) {
                          add(timesheet);
                          refreshWeek(dateBegin, dateEnd);
                          setError(false);
                          setSave(true);
                          refreshWeek(dateBegin, dateEnd);
                        } else {
                          setSave(false);
                          setError(true);
                        }
                      }
                    }}
                  />
                )}
              </Row>
            </Col>
            <Col>
              {dayEnd ? (
                <TagText
                  textStyle={{ marginLeft: "0px" }}
                  text={
                    `${parseInt(dayEnd) - parseInt(dayBegin)} /` +
                    expectedWorkHours / 5
                  }
                />
              ) : holidayType ? (
                <TagText
                  textStyle={{ marginLeft: "0px" }}
                  text={expectedWorkHours / 5 + `/` + expectedWorkHours / 5}
                />
              ) : (
                <TagText
                  textStyle={{ marginLeft: "0px" }}
                  text={`0/` + expectedWorkHours / 5}
                />
              )}
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TagIcon
                icon={holidayType?.icon ? holidayType?.icon : "-"}
                accent={holidayType?.accent ? holidayType?.accent : "disabled"}
              />
              <TagText
                text={holidayType?.shortName ? holidayType?.shortName : "-"}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <TagToggle
                name="Timisoara"
                disabled={
                  moment(new Date(date)).isBefore(new Date().setDate(0)) ||
                  holidayType !== null ||
                  isFired
                    ? true
                    : false
                }
                accent={
                  moment(new Date(date)).isBefore(new Date().setDate(0)) ||
                  holidayType !== null ||
                  isFired
                    ? "navy"
                    : "viridiangreen"
                }
                value={
                  workType?.id.toLocaleString() && holidayType === null
                    ? workType?.id.toLocaleString()
                    : []
                }
                data={dataWork}
                valueField="value"
                textField="label"
                onValueChange={(e) => {
                  const work = e.detail.value;
                  const curr_date = date.toLocaleString();
                  // setToggleChange(false);
                  // setToggleOption(work);
                  setCurrDate(curr_date);
                  onUpdateWorkType(curr_date, work);
                  //setToggleChangeModal(true);
                }}
              />
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {timesheetValidation ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    <TagIcon
                      className="tooltip-target"
                      icon={
                        timesheetValidation.statusLiteral === "Pending"
                          ? "Circle34"
                          : timesheetValidation.statusLiteral === "Approved"
                          ? "CircleTick"
                          : timesheetValidation.statusLiteral === "Rejected"
                          ? "CircleCross"
                          : ""
                      }
                      accent={
                        timesheetValidation.statusLiteral === "Pending"
                          ? "title"
                          : timesheetValidation.statusLiteral === "Approved"
                          ? "viridiangreen"
                          : timesheetValidation.statusLiteral === "Rejected"
                          ? "access"
                          : undefined
                      }
                    />
                  </div>
                  <TagTooltip
                    tooltipFor=".tooltip-target"
                    header={
                      timesheetValidation.statusLiteral === "Pending"
                        ? undefined
                        : `${timesheetValidation.validatedBy}`
                    }
                    content={
                      timesheetValidation.statusLiteral === "Pending"
                        ? "Pending Approval"
                        : `${timesheetValidation.withCredentials}`
                    }
                    width="150px"
                    background="light"
                  ></TagTooltip>
                </div>
              ) : null}
            </Col>
          </Row>
        )}
        data={days}
      />
      <Row style={{ maxWidth: "100%" }}>
        <Col className={styles.remove} xs="auto">
          <div style={{ width: "50px" }}></div>
        </Col>
        <Col className={styles.hours} xs="auto">
          <TagText
            textStyle={{
              fontWeight: "bold",
              fontSize: "1rem",
            }}
            text={"Worked hours:"}
          />
        </Col>
        <Col className={styles.reomve}></Col>
        <Col className={styles.remove} xs="auto">
          <Row
            style={{ alignItems: "center", height: "0", overflowY: "hidden" }}
          >
            <RangePicker />
          </Row>
        </Col>
        <Col className={"ml-4"}>
          <TagText
            textStyle={
              currentWorkHours === expectedWorkHours
                ? {
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }
                : {
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#e5173f",
                  }
            }
            text={`${currentWorkHours}/${expectedWorkHours}`}
          />
        </Col>
        <Col></Col>
        <Col className={styles.remove}></Col>
        <Col className={styles.remove} xs="auto">
          <Row
            style={{
              alignItems: "center",
              height: "0",
              overflowY: "hidden",
              paddingLeft: "30px",
            }}
          >
            <TagToggle
              name="Timisoara"
              data={dataWork}
              valueField="value"
              textField="label"
            />
          </Row>
        </Col>
      </Row>

      <Col>
        <Row className={"pt-4"}>
          <TagButton
            style={{ marginLeft: "20px" }}
            size="large"
            icon="CircleTick"
            text="Fill week"
            accent={
              moment(days[0].date).isAfter(new Date()) || isFired
                ? "navy"
                : "viridiangreen"
            }
            color={"white"}
            disabled={
              moment(days[0].date).isAfter(new Date()) || isFired ? true : false
            }
            iconAccent={"porcelain"}
            onButtonClick={() => {
              let dateBegin = new Date(days[0].date);
              let dateEnd = new Date();

              if (new Date(days[4].date) < new Date())
                dateEnd = new Date(days[4].date);

              refreshWeek(dateBegin, dateEnd);
              const week = {
                begin: dateBegin,
                end: dateEnd,
              };
              refreshWeek(dateBegin, dateEnd);
              onFillWeek(week);
              setFill(true);

              refreshWeek(dateBegin, dateEnd);
            }}
          ></TagButton>
        </Row>
      </Col>

      {toggleOption && currDate ? (
        <TagModal
          heading={"Are you sure you want to change the work location?"}
          borderAccent="access"
          width="small"
          suppressCloseIcon
          onClosed={() => setToggleChangeModal(false)}
          visible={toggleChangeModal}
        >
          <ToggleChangeForm
            toggleOption={toggleOption}
            currDate={currDate}
            onSubmitChange={onUpdateWorkType}
            closeModal={() => {
              setToggleChangeModal(false);
              setToggleOption(null);
            }}
            messageOnSubmit={() => {
              setSubmit(false);
              setSave(false);
              setAckError(false);
              setError(false);
              setFill(false);
              // setToggleChange(true);
            }}
          />
        </TagModal>
      ) : (
        <></>
      )}

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {submit ? (
            <TagAlert
              type={"success"}
              text="Current week acknowlegded"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setSubmit(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {ackError ? (
            <TagAlert
              type={"danger"}
              text="Current week is not completed!"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setAckError(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {save ? (
            <TagAlert
              type={"success"}
              text="Schedule Updated!"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setSave(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {error ? (
            <TagAlert
              type={"danger"}
              text="Work interval doesn't match your working hours!"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setError(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {fill ? (
            <TagAlert
              type={"success"}
              text="Week has been filled!"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setFill(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {toggleChange ? (
            <TagAlert
              type={"success"}
              text="Work location has been successfully modified! Your manager will be notified about this change!"
              inline={false}
              closeable={true}
              width="110%"
              onClosed={() => {
                setToggleChange(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"ml-2 pt-2"}>
          {authError ? (
            <TagAlert
              type={"danger"}
              text="Authentication Failed! Contact Admin!"
              inline={false}
              closeable={true}
              width="150%"
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {errorLocation ? (
            <TagAlert
              type={"warning"}
              text="Update your working hours!"
              inline={false}
              closeable={true}
              width="150%"
              onClosed={() => {
                setError(false);
              }}
              showDuration={6000}
            />
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
