import {
  IChangeArgs,
  IComboChangeArgs,
  TagAlert,
  TagButton,
  TagCombobox,
  TagEditField,
  TagForm,
  TagList,
  TagModal,
  TagPager,
  TagSearchbox,
  TagText,
  TagToast,
} from "@tag/tag-components-react-v2";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { HolidayModel } from "../../common/Models/HolidayModel";
import { HolidaysAdminModel } from "../../common/Models/HolidaysAdminModel";

export interface LeavesPageProps {
  holidaysManager: HolidaysAdminModel[];
  onDeleteHolidayManager: (id: string) => void;
  messageManagerDelete: any;
  onAddHolidayManager: (holiday: HolidayModel, email: string) => void;
  employeesForSearch: any;
  messageForManager: any;
}

export const Leaves = ({
  holidaysManager,
  onDeleteHolidayManager,
  messageManagerDelete,
  onAddHolidayManager,
  employeesForSearch,
  messageForManager,
}: LeavesPageProps) => {
  const [email, setEmail] = useState<string | undefined>();
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [deleteHolidayFormModal, setDeleteHolidayFormModal] = useState(false);
  const [messageOnDelete, setMessageOnDelete] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState<string | null>(null);
  const [messageOnAddHoliday, setMessageOnAddHoliday] =
    useState<boolean>(false);
  const [object, setObject] = useState({
    id: null,
    type: null,
    begin: null,
    end: null,
  });

  holidaysManager.sort((a, b) =>
    a.profile.fullName.localeCompare(b.profile.fullName)
  );

  const handleSearch = (e: any) => {
    setSearchString(e.detail.criteria);
    setPage(1);
  };

  const pageSize = 5;

  const filteredHolidays = holidaysManager.filter(
    (a) =>
      a.profile.firstName.toLowerCase().indexOf(searchString.toLowerCase()) >
        -1 ||
      a.profile.lastName.toLowerCase().indexOf(searchString.toLowerCase()) >
        -1 ||
      a.profile.email.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.profile.marca.toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );

  const pageOfHolidays = filteredHolidays
    .slice((page - 1) * pageSize, page * pageSize)
    .map((obj) => ({
      ...obj,
      begin: obj.holiday.begin,
      end: obj.holiday.end,
      typeName: obj.holiday.type ? obj.holiday.type.shortName : "",
      accent: obj.holiday.type ? obj.holiday.type.accent : "",
      icon: obj.holiday.type ? obj.holiday.type.icon : "",
      model: obj,
      profile: obj.profile.fullName,
      id: obj.holiday.id,
    }));

  const holidaysCount = filteredHolidays.length;
  const holidaysPageCount = Math.ceil(holidaysCount / pageSize);

  const currentBegin = new Date();

  const handleHoliday = (id: string) => {
    setDeleteHolidayFormModal(true);
    setSelectedHoliday(id);
  };

  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "start",
        flexWrap: "wrap",
      }}
    >
      <div className="col-xs-auto pl-4">
        <TagForm inline submitButtonHidden formStyle={{ border: "none" }}>
          <div style={{ margin: "10px 0px 10px 0px" }}>
            <TagCombobox
              inputDisabled
              clearButton
              searchable
              value={email}
              textField="fullName"
              valueField="email"
              placeholder="Enter Employee Name"
              data={employeesForSearch}
              onValueChange={(e: CustomEvent<IComboChangeArgs>) => {
                setEmail(e.detail.value);
              }}
            />
          </div>

          <div style={{ margin: "10px 0px 10px 0px" }}>
            <TagEditField
              value={object.begin ? object.begin : currentBegin}
              name="dateIn"
              placeholder="Select a working date..."
              label="Start"
              editor="datepick"
              onValueChange={(e: CustomEvent<IChangeArgs>) =>
                setObject({
                  begin: e.detail.value?.toDateString(),
                  end: object.end,
                  type: object.type,
                  id: null,
                })
              }
            />
          </div>

          <div style={{ margin: "10px 0px 10px 0px" }}>
            <TagEditField
              value={object.end ? object.end : currentBegin}
              name="dateOut"
              placeholder="Select a working date..."
              label="End"
              editor="datepick"
              onValueChange={(e: CustomEvent<IChangeArgs>) =>
                setObject({
                  begin: object.begin,
                  end: e.detail.value?.toDateString(),
                  type: object.type,
                  id: null,
                })
              }
            />
          </div>

          <div style={{ margin: "10px 0px 10px 0px" }}>
            <TagText text="Others" textStyle={{ fontWeight: "bold" }}></TagText>

            <TagCombobox
              value={object.type ? object.type : 1}
              textField="name"
              valueField="id"
              placeholder="Pick a disciplinary action"
              data={[
                { id: 1, name: "CO – Annual Leave" },
                { id: 3, name: "CPL – Special Leave (RO)" },
                { id: 8, name: "D – Delegation" },
                { id: 9, name: "BB – Big Break"}
              ]}
              onValueChange={(e: CustomEvent<IComboChangeArgs>) =>
                setObject({
                  begin: object.begin,
                  end: object.end,
                  type: e.detail.value,
                  id: null,
                })
              }
            />
          </div>
          <TagButton
            style={{ paddingTop: "10px" }}
            text="Save"
            disabled={email === undefined}
            size="default"
            accent={email === undefined ? "navy" : "viridian-green"}
            icon="CircleTick"
            type="submit"
            iconAccent={"porcelain"}
            onClick={() => {
              const holidayforManager = {
                id: null,
                begin: object.begin ? object.begin : currentBegin,
                end: object.end ? object.end : currentBegin,
                typeId: object.type ? object.type : 1,
                type: null,
              };
              if (email !== undefined) {
                onAddHolidayManager(holidayforManager, email);
                setMessageOnDelete(false);
                setMessageOnAddHoliday(true);
              }

              setObject({ id: null, begin: null, end: null, type: null });
              setEmail(undefined);
            }}
          ></TagButton>
        </TagForm>
      </div>
      <div className="col offset-1">
        <TagSearchbox
          style={{ flexGrow: 1, paddingRight: "10px" }}
          placeholder="Search by Name, Email or Marca..."
          value={searchString}
          clearButton={true}
          onSearch={(e) => handleSearch(e)}
        />
        <TagEditField
          value={"Records: " + holidaysCount.toString()}
          name="Total"
          editor="textbox"
          disabled={true}
          inline={true}
        />
        <TagList
          containerStyle={{ minHeight: "285px" }}
          leftIcon="User"
          leftIconAccent="atoll"
          leftIconRoundedBackground
          primaryField="profile"
          primaryFieldInline
          pillAlign="right"
          pill1Field="typeName"
          pill1FieldType="string"
          pill1FieldAccentField="accent"
          data={pageOfHolidays}
          pageSize={pageSize}
          rightField2="begin"
          rightField3="end"
          rightField2Type="date"
          rightField3Type="date"
          rightField2Inline
          rightField3Inline
          button1Icon="Delete"
          button1HostStyle={{ order: 2 }}
          onButtonClick={(e) => {
            handleHoliday(e.detail.item.id!);
            if (filteredHolidays.length === 1) {
              setPage(page - 1);
            }
          }}
        />
        <TagPager
          page={page}
          pageCount={holidaysPageCount}
          accent="viridiangreen"
          showFirstLast
          onPageRequest={(e) => setPage(e.detail.page)}
        />
      </div>

      <TagModal
        heading="Are you sure?"
        borderAccent="access"
        width="small"
        suppressCloseIcon
        onClosed={() => setDeleteHolidayFormModal(false)}
        visible={deleteHolidayFormModal}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginLeft: "10px",
            }}
            text="Delete"
            icon="Delete"
            iconAccent="white"
            accent="access"
            onButtonClick={() => {
              onDeleteHolidayManager(selectedHoliday!);
              setDeleteHolidayFormModal(false);
              setMessageOnDelete(true);
              setMessageOnAddHoliday(false);
            }}
          />
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              setDeleteHolidayFormModal(false);
              //  setSelectedHoliday(null);
            }}
          ></TagButton>
        </div>
      </TagModal>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageOnDelete && messageManagerDelete ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageManagerDelete.statusCode == 200 ? "success" : "warning"
                }
                text={
                  messageManagerDelete.statusCode == 200
                    ? "Leave Deleted!"
                    : "Something went wrong!"
                }
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnDelete(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageForManager && messageOnAddHoliday ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={messageForManager.status == 200 ? "success" : "warning"}
                text={
                  messageForManager.status == 200
                    ? messageForManager.data
                    : messageForManager.data
                }
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnAddHoliday(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
