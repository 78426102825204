import {
  TagAlert,
  TagButton,
  TagFilePicker,
  TagToast,
} from "@tag/tag-components-react-v2";

import { Col, Row } from "react-bootstrap";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { useState } from "react";
import { Divider } from "antd";

export interface ReportsPageProps {
  onExportReport: (begin: Date, end: Date) => void;
  onImportProfile: (file: any) => void;
  onImportXD: (file: any) => void;
  importProfileMessage: any;
  importXDMessage: any;
}

export const ReportsPage = ({
  onExportReport,
  onImportProfile,
  onImportXD,
  importProfileMessage,
  importXDMessage,
}: ReportsPageProps) => {
  const currentDay = moment(new Date());
  const [startReport, setStartReport] = useState<moment.Moment | null>(null);
  const [endReport, setEndReport] = useState<moment.Moment | null>(null);
  const [generateMessage, setGenerateMessage] = useState<boolean>(false);
  const [messageImport, setMessageImport] = useState<boolean>(false);
  const [messageImportXD, setMessageImportXD] = useState<boolean>(false);

  const isInvalid = startReport === null || endReport === null;

  const handleReport = () => {
    onExportReport(
      new Date(moment(startReport).format()),
      new Date(moment(endReport).format())
    );
    setStartReport(currentDay);
    setEndReport(currentDay);
    setStartReport(null);
    setEndReport(null);
  };

  return (
    <div
      style={{
        padding: "10px 15px 0px 15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Divider orientation="left">Import Profiles</Divider>
          <TagFilePicker
            accept=".csv"
            onFileChange={(e) => {
              setGenerateMessage(false);
              setMessageImportXD(false);
              setMessageImport(true);
              onImportProfile(e.detail.files[0]);
            }}
          />
        </div>
      </div>

      <div className="pt-4">
        <Divider orientation="left">
          Select a date range to generate a report
        </Divider>
        <DatePicker
          format={"DD-MM-YYYY"}
          value={startReport}
          onChange={(value) => setStartReport(value)}
        />
        <DatePicker
          format={"DD-MM-YYYY"}
          value={endReport}
          onChange={(value) => setEndReport(value)}
        />
        <TagButton
          disabled={isInvalid}
          size="large"
          icon="CircleTick"
          type="submit"
          text="Generate Report"
          accent={isInvalid ? "navy" : "viridian-green"}
          color={"white"}
          iconAccent={"porcelain"}
          onButtonClick={() => {
            setMessageImportXD(false);
            setMessageImport(false);
            setGenerateMessage(true);
            handleReport();
          }}
        />
        <div className="pt-4">
          <Divider orientation="left">Check Leaves based on XD export</Divider>
          <TagFilePicker
            accept=".xlsx"
            onFileChange={(e) => {
              setGenerateMessage(false);
              setMessageImport(false);
              setMessageImportXD(true);
              onImportXD(e.detail.files[0]);
            }}
          />
        </div>
      </div>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {generateMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type="success"
                text="Your download will start in a few seconds..."
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessage(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageImport && importProfileMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  importProfileMessage.status == 200 &&
                  importProfileMessage.downloadable !== true
                    ? "success"
                    : "warning"
                }
                text={
                  importProfileMessage.status == 200 &&
                  importProfileMessage.downloadable == true
                    ? "Something went wrong! A log file with errors will start downloading shortly!"
                    : importProfileMessage.data
                }
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setMessageImport(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageImportXD && importXDMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  importXDMessage.status !== 200 &&
                  importXDMessage.downloadable !== true
                    ? "warning"
                    : "success"
                }
                text={
                  importXDMessage.status !== 200 &&
                  importXDMessage.downloadable !== true
                    ? "Something went wrong! Check the file again!"
                    : "Your download will start in a few seconds..."
                }
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setMessageImportXD(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
