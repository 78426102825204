import { useState } from "react";
import { ContentList } from "../OffDaysPage/ContentList";
import { DayOffForm } from "./DaysOffForm";
import { EditForm } from "./EditForm";
import { DeleteForm } from "./DeleteForm";
import {
  TagAlert,
  TagButton,
  TagModal,
  TagToast,
} from "@tag/tag-components-react-v2";
import { HolidayModel } from "../../common/Models/HolidayModel";
import { EditHolidayModel } from "../../common/Models/EditHolidayModel";

export interface DaysOffPageProps {
  holidays: HolidayModel[];
  holidayMessage: any;
  isFired: boolean;
  onAdd: (holiday: HolidayModel) => void;
  onDelete: (holiday: HolidayModel) => void;
  onEdit: (holiday: EditHolidayModel) => void;
}

export const DaysOffPage = (props: DaysOffPageProps) => {
  const [dayOffFormCreateModal, setDayOffFormCreateModal] = useState(false);
  const [dayOffFormEditModal, setDayOffFormEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState<HolidayModel | null>(
    null
  );
  const [deleteHoliday, setDeleteHoliday] = useState<HolidayModel | null>(null);
  const [message, setMessage] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<boolean>(false);
  const [dateOverMessage, setDateOverMessage] = useState<boolean>(false);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <TagButton
          style={{
            paddingRight: "15px",
            paddingTop: "10px",
            paddingBottom: "20px",
          }}
          accent={props.isFired ? "navy" : "viridiangreen"}
          text="Book Leave"
          icon="Plus"
          size={"large"}
          iconAccent="porcelain"
          disabled={props.isFired ? true : false}
          onClick={() => {
            if (!props.isFired) setDayOffFormCreateModal(true);
          }}
        />
      </div>
      <ContentList
        holidays={props.holidays}
        onEditButton={(a) => {
          if (!props.isFired) {
            setSelectedHoliday(a);
            setDayOffFormEditModal(true);
          }
        }}
        onDeleteButton={(a) => {
          if (!props.isFired) {
            setDeleteHoliday(a);
            setDeleteModal(true);
          }
        }}
      />

      <TagModal
        heading="Add a new day off"
        borderAccent="atoll"
        width="small"
        suppressCloseIcon={true}
        onClosed={() => setDayOffFormCreateModal(false)}
        visible={dayOffFormCreateModal}
      >
        <DayOffForm
          holiday={selectedHoliday}
          onCancelButtonClick={() => {
            setSelectedHoliday(null);
            setDayOffFormCreateModal(false);
          }}
          onSubmitButtonClick={(a) => {
            props.onAdd(a);
            setDayOffFormCreateModal(false);
            setDateOverMessage(false);
            setDeleteMessage(false);
            setMessage(true);
          }}
          onDateOver={() => {
            setDayOffFormCreateModal(false);
            setDeleteMessage(false);
            setMessage(false);
            setDateOverMessage(true);
          }}
        />
      </TagModal>
      <TagModal
        heading="Edit Day Off"
        borderAccent="atoll"
        width="small"
        visible={dayOffFormEditModal}
        suppressCloseIcon
      >
        <EditForm
          holiday={selectedHoliday}
          onCancelButtonClick={() => {
            setSelectedHoliday(null);
            setDateOverMessage(false);
            setDayOffFormEditModal(false);
          }}
          onEditButtonClick={(a) => {
            props.onEdit(a);
            setDayOffFormEditModal(false);
            setSelectedHoliday(null);
            setDateOverMessage(false);
            setDeleteMessage(false);
            setMessage(true);
          }}
        />
      </TagModal>
      <TagModal
        heading="Are you sure you want to delete?"
        borderAccent="access"
        width="small"
        visible={deleteModal}
        suppressCloseIcon
      >
        <DeleteForm
          holiday={deleteHoliday}
          onCancelButtonClick={() => {
            setDeleteHoliday(null);
            setDeleteModal(false);
          }}
          onDeleteButtonClick={(a) => {
            props.onDelete(a);
            setDeleteModal(false);
            setDateOverMessage(false);
            setSelectedHoliday(null);
            setMessage(false);
            setDeleteMessage(true);
          }}
        />
      </TagModal>

      {message && props.holidayMessage ? (
        <TagToast position="bottom-left">
          <TagAlert
            type={props?.holidayMessage.status == 200 ? "success" : "warning"}
            text={props.holidayMessage.data}
            showDuration={6000}
            alertType="toast"
            onClosed={() => {
              setMessage(false);
            }}
            closeable
          ></TagAlert>
        </TagToast>
      ) : (
        <></>
      )}

      {deleteMessage ? (
        <TagToast position="bottom-left">
          <TagAlert
            type="success"
            text="Leave Deleted!"
            showDuration={6000}
            alertType="toast"
            onClosed={() => {
              setDeleteMessage(false);
            }}
            closeable
          ></TagAlert>
        </TagToast>
      ) : (
        <></>
      )}

      {dateOverMessage ? (
        <TagToast position="bottom-left">
          <TagAlert
            type="warning"
            text="You can't add leaves in the previous months!"
            showDuration={6000}
            alertType="toast"
            onClosed={() => {
              setDateOverMessage(false);
            }}
            closeable
          ></TagAlert>
        </TagToast>
      ) : (
        <></>
      )}
    </>
  );
};
