import {
  IComboChangeArgs,
  TagAlert,
  TagBadge,
  TagButton,
  TagCombobox,
  TagIcon,
  TagList,
  TagModal,
  TagStat,
  TagText,
  TagToast,
  TagTooltip,
} from "@tag/tag-components-react-v2";
import { addDays, subDays, startOfWeek, endOfWeek } from "date-fns";
import styles from "./ManagersPage.module.scss";

import { Col, Row } from "react-bootstrap";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { IncompleteTimesheetsModel } from "../../common/Models/IncompleteTimesheetsModel";
import { ManagerStatsModel } from "../../common/Models/ManagerStatsModel";
import { ValidationManyModel } from "../../common/Models/ValidationManyModel";
import { UpdateTimesheetAdminModel } from "../../common/Models/UpdateTimesheetAdminModel";
import { Leaves } from "./Leaves";
import { HolidaysAdminModel } from "../../common/Models/HolidaysAdminModel";
import { HolidayModel } from "../../common/Models/HolidayModel";

export interface ManagersPageProps {
  onExportManagersReport: (begin: Date, end: Date) => void;
  badApples: IncompleteTimesheetsModel;
  statsForManager: ManagerStatsModel[];
  onGetManagerStats: (begin: Date, end: Date) => void;
  statsForManagerStartDate: Date;
  statsForManagerEndDate: Date;
  onSetStatsForManagerStartDate: (begin: Date) => void;
  onSetStatsForManagerEndDate: (end: Date) => void;
  onManagerValidateMany: (days: ValidationManyModel[]) => void;
  onManagerValidateAll: (begin: Date, end: Date, statusCode: number) => void;
  messageForManagerUpdateTimesheet: any;
  onUpdateTimesheetManager: (
    id: number,
    day: UpdateTimesheetAdminModel
  ) => void;
  holidaysManager: HolidaysAdminModel[];
  onDeleteHolidayManager: (id: string) => void;
  messageManagerDelete: any;
  onAddHolidayManager: (holiday: HolidayModel, email: string) => void;
  messageForManager: any;
}

export const ManagersPage = ({
  onExportManagersReport,
  badApples,
  statsForManager,
  statsForManagerStartDate,
  statsForManagerEndDate,
  onSetStatsForManagerStartDate,
  onSetStatsForManagerEndDate,
  onManagerValidateMany,
  onManagerValidateAll,
  onUpdateTimesheetManager,
  messageForManagerUpdateTimesheet,
  holidaysManager,
  onDeleteHolidayManager,
  messageManagerDelete,
  onAddHolidayManager,
  messageForManager,
}: ManagersPageProps) => {
  const currentDay = moment(new Date());
  const [startReport, setStartReport] = useState<moment.Moment | null>(null);
  const [endReport, setEndReport] = useState<moment.Moment | null>(null);
  const [generateMessage, setGenerateMessage] = useState<boolean>(false);
  const [selected, setSelected] = useState<ValidationManyModel[]>([]);
  const [comboValue, setComboValue] = useState<string | undefined>(undefined);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [
    generateMessageForUpdateTimesheet,
    setGenerateMessageForUpdateTimsheet,
  ] = useState<boolean>(false);
  const [dayState, setDayState] = useState<any>({
    id: "",
    date: "",
    dayBegin: undefined,
    dayEnd: undefined,
    workTypeId: null,
    profileId: 0,
  });

  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(10);

  const increment = () => {
    setValue((cur) => cur + 1);
    setValue2((cur) => cur + 1);
  };
  const employeesForSearch = statsForManager
    .filter((e) => e.employee.isFired === false)
    .map((e) => ({
      id: e.employee.id,
      fullName: e.employee.fullName,
      email: e.employee.email,
    }));
  const isInvalid = startReport === null || endReport === null;

  const badApplesMonth = badApples.thisMonth.map((obj) => ({
    ...obj,
    name: obj.employee.firstName + " " + obj.employee.lastName,
    email: obj.employee.email,
  }));

  const badApplesWeek = badApples.lastWeek.map((obj) => ({
    ...obj,
    name: obj.employee.firstName + " " + obj.employee.lastName,
    email: obj.employee.email,
  }));

  const handleReport = () => {
    onExportManagersReport(
      new Date(moment(startReport).format()),
      new Date(moment(endReport).format())
    );
    setStartReport(currentDay);
    setEndReport(currentDay);
    setStartReport(null);
    setEndReport(null);
  };

  const handlePrevOrNextDate = (goToFuture: boolean) => {
    if (goToFuture) {
      onSetStatsForManagerStartDate(addDays(statsForManagerStartDate, 7));
      onSetStatsForManagerEndDate(addDays(statsForManagerEndDate, 7));
    } else {
      onSetStatsForManagerStartDate(subDays(statsForManagerStartDate, 7));
      onSetStatsForManagerEndDate(subDays(statsForManagerEndDate, 7));
    }
  };

  const handleCurrentWeek = () => {
    onSetStatsForManagerStartDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
    onSetStatsForManagerEndDate(endOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const data = statsForManager[0]?.days;

  const handleButtonClick = (day: any, employeeId: number) => {
    const { date, uiKey, id, dayBegin, dayEnd, holidayType } = day;

    const key = selected
      .map(function (e) {
        return e.id;
      })
      .indexOf(uiKey);

    if (key === -1) {
      selected.push({
        id: uiKey,
        ownedBy: employeeId,
        date: date,
        statusCode: "",
        dayBegin: dayBegin,
        dayEnd: dayEnd,
        idPontaj: id,
        holidayType: holidayType,
      });
    } else {
      selected.splice(key, 1);
    }

    setSelected((prevState) => [...prevState]);
  };

  const handleValidation = () => {
    if (comboValue !== undefined) {
      const d = selected.map((obj) => ({ ...obj, statusCode: comboValue }));
      onManagerValidateMany(d);
      setSelected([]);
      setComboValue(undefined);
    }
  };

  const uiSelect = selected.map(function (e) {
    return e.id;
  });

  const statsForManagerWithUiKey: any = statsForManager.map((l) => {
    l.days = l.days.map((d) => ({
      uiKey:
        new Date(d.date).getDate().toLocaleString() +
        l.employee.id.toLocaleString(),
      ...d,
    }));
    return l;
  });

  const holidayOrNot = selected.filter(function (e) {
    return e.holidayType;
  });

  const makeTimes = () => {
    return Array.from(Array(57)).map((_v, i) => {
      const hours = 7 + Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      return {
        id: i + 1,
        time: `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`,
      };
    });
  };
  const dataForCombo = makeTimes();

  useEffect(() => {
    if (selected.length === 1) {
      const { date, dayBegin, dayEnd, idPontaj, ownedBy } = selected[0];

      if (dayBegin !== null && dayEnd !== null) {
        setDayState((prev: any) => ({
          ...prev,
          // ...dayState,
          profileId: ownedBy,
          date: new Date(date).toDateString(),
          dayBegin: dayBegin.slice(0, -3)!,
          dayEnd: dayEnd.slice(0, -3)!,
          id: idPontaj,
          workTypeId: null,
        }));
      } else {
        setDayState((prev: any) => ({
          ...prev,
          // ...dayState,
          profileId: ownedBy,
          date: new Date(date).toDateString(),
          dayBegin: undefined,
          dayEnd: undefined,
          id: "",
          workTypeId: null,
        }));
      }
    } else {
      setDayState((prev: any) => ({
        ...prev,
        // ...dayState,
        profileId: 0,
        date: "",
        dayBegin: undefined,
        dayEnd: undefined,
        id: "",
        workTypeId: null,
      }));
      increment();
    }
  }, [selected]);

  const handleSubmit = () => {
    const dayForEdit = {
      id: dayState.id,
      date: dayState.date,
      dayBegin: dayState.dayBegin,
      dayEnd: dayState.dayEnd,
      workTypeId: null,
    };
    const dayForAdd = {
      id: null,
      date: dayState.date,
      dayBegin: dayState.dayBegin,
      dayEnd: dayState.dayEnd,
      workTypeId: null,
    };
    if (dayState.id !== "") {
      onUpdateTimesheetManager(dayState.profileId, dayForEdit);
      setGenerateMessageForUpdateTimsheet(true);
    } else {
      onUpdateTimesheetManager(dayState.profileId, dayForAdd);
      setGenerateMessageForUpdateTimsheet(true);
    }
    setSelected([]);
    setDayState(() => ({
      ...dayState,
      profileId: 0,
      date: "",
      dayBegin: undefined,
      dayEnd: undefined,
      id: "",
      workTypeId: null,
    }));
  };

  return (
    <div style={{ padding: "10px 15px 0px 15px" }}>
      <div>
        <Divider orientation="left">
          Select a date range to generate a report
        </Divider>
        <DatePicker
          format={"DD-MM-YYYY"}
          value={startReport}
          onChange={(value) => setStartReport(value)}
        />
        <DatePicker
          format={"DD-MM-YYYY"}
          value={endReport}
          onChange={(value) => setEndReport(value)}
        />
        <TagButton
          disabled={isInvalid}
          size="large"
          icon="CircleTick"
          type="submit"
          text="Generate Report"
          accent={isInvalid ? "navy" : "viridian-green"}
          color={"white"}
          iconAccent={"porcelain"}
          onButtonClick={() => {
            setGenerateMessage(true);
            handleReport();
          }}
        />
      </div>
      <div>
        <Divider orientation="left" className="pt-4">
          Number of incomplete timesheets
        </Divider>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            margin: "0px auto",
            verticalAlign: "baseline",
          }}
        >
          <div
            style={{
              marginTop: "0",
            }}
          >
            <TagStat
              style={{
                width: "280px",
                height: "130px",
                paddingBottom: "10px",
                paddingLeft: "30px",
              }}
              label="Incomplete timesheets last week"
              value={badApplesWeek.length.toString()}
              accent={badApplesWeek.length > 0 ? "access" : "viridiangreen"}
              orientation="vertical"
            />
            <Divider orientation="left">
              Employees with incomplete timesheets last week
            </Divider>
            {badApplesWeek ? (
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <TagList
                  containerStyle={{
                    paddingLeft: "10px",
                  }}
                  leftIcon="User"
                  leftIconAccent="atoll"
                  leftIconRoundedBackground
                  primaryField="name"
                  leftField1="email"
                  leftField1Inline
                  data={badApplesWeek}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <TagStat
              style={{
                width: "340px",
                height: "130px",
                paddingBottom: "10px",
                paddingLeft: "80px",
              }}
              label="Incomplete timesheets this month"
              value={badApplesMonth.length.toString()}
              accent={badApplesMonth.length > 0 ? "access" : "viridiangreen"}
              orientation="vertical"
            />
            <Divider orientation="left" className="pl-5">
              Employees with incomplete timesheets this month
            </Divider>
            {badApplesMonth ? (
              <div
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <TagList
                  containerStyle={{ paddingLeft: "60px" }}
                  leftIcon="User"
                  leftIconAccent="atoll"
                  leftIconRoundedBackground
                  primaryField="name"
                  leftField1="email"
                  leftField1Inline
                  data={badApplesMonth}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div>
        <Divider orientation="left" className="pt-4">
          Leaves
        </Divider>
        <Leaves
          holidaysManager={holidaysManager}
          onDeleteHolidayManager={onDeleteHolidayManager}
          messageManagerDelete={messageManagerDelete}
          onAddHolidayManager={onAddHolidayManager}
          employeesForSearch={employeesForSearch}
          messageForManager={messageForManager}
        />
      </div>
      <div>
        <Divider orientation="left">Team stats</Divider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="tooltip-target">
            <TagIcon
              style={{ cursor: "pointer" }}
              accent="viridiangreen"
              className={styles.navIcon}
              icon="Home"
              onClick={() => handleCurrentWeek()}
            />
          </div>
          <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>

          <div
            style={{
              display: "flex",
              width: "90px",
              justifyContent: "space-between",
            }}
          >
            <div className="tooltip-target2">
              <TagIcon
                style={{ cursor: "pointer" }}
                icon="CircleTick"
                accent="viridiangreen"
                className={styles.navIcon}
                onClick={() => setModalApprove(true)}
              />
            </div>
            <div className="tooltip-target3">
              <TagIcon
                style={{ cursor: "pointer" }}
                icon="CircleCross"
                accent="access"
                className={styles.navIcon}
                onClick={() => setModalReject(true)}
              />
            </div>
            <TagModal
              width="small"
              heading="Are you sure you want to Approve All Week?"
              visible={modalApprove}
              onClosed={() => setModalApprove(false)}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TagButton
                  buttonStyle={{
                    marginTop: "15px",
                    marginBottom: "-6px",
                    marginLeft: "10px",
                  }}
                  text="Save"
                  icon="CircleTick"
                  iconAccent="white"
                  accent="viridian-green"
                  onButtonClick={() => {
                    onManagerValidateAll(
                      statsForManagerStartDate,
                      statsForManagerEndDate,
                      1
                    );
                    setModalApprove(false);
                  }}
                />
                <TagButton
                  buttonStyle={{
                    marginTop: "15px",
                    marginBottom: "-6px",
                    marginRight: "10px",
                  }}
                  text="Exit"
                  accent="title"
                  icon="Exit"
                  iconAccent="porcelain"
                  onButtonClick={() => {
                    setModalApprove(false);
                  }}
                ></TagButton>
              </div>
            </TagModal>
            <TagModal
              width="small"
              heading="Are you sure you want to Reject All Week?"
              visible={modalReject}
              onClosed={() => setModalReject(false)}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TagButton
                  buttonStyle={{
                    marginTop: "15px",
                    marginBottom: "-6px",
                    marginLeft: "10px",
                  }}
                  text="Save"
                  icon="CircleCross"
                  iconAccent="white"
                  accent="access"
                  onButtonClick={() => {
                    onManagerValidateAll(
                      statsForManagerStartDate,
                      statsForManagerEndDate,
                      2
                    );
                    setModalReject(false);
                  }}
                />
                <TagButton
                  buttonStyle={{
                    marginTop: "15px",
                    marginBottom: "-6px",
                    marginRight: "10px",
                  }}
                  text="Exit"
                  accent="title"
                  icon="Exit"
                  iconAccent="porcelain"
                  onButtonClick={() => {
                    setModalReject(false);
                  }}
                ></TagButton>
              </div>
            </TagModal>
          </div>
          <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <TagCombobox
              disabled={selected.length > 0 ? false : true}
              style={{ width: "150px" }}
              inputDisabled
              textField="name"
              valueField="id"
              placeholder="Select validation"
              data={[
                { id: 1, name: "Approve" },
                { id: 2, name: "Reject" },
              ]}
              value={comboValue}
              onValueChange={(e: CustomEvent<IComboChangeArgs>) =>
                setComboValue(e.detail.value)
              }
            />

            <TagButton
              style={{ paddingRight: "15px" }}
              accent={
                comboValue === undefined || selected.length === 0
                  ? "grey-1"
                  : "viridiangreen"
              }
              disabled={
                comboValue === undefined || selected.length === 0 ? true : false
              }
              text="Save"
              onButtonClick={() => handleValidation()}
            />

            <div className="tooltip-target4">
              <TagIcon
                style={{ cursor: "pointer" }}
                icon="Undo"
                accent={selected.length > 0 ? "viridiangreen" : "grey-3"}
                className={styles.navIcon}
                onClick={() => setSelected([])}
              />
            </div>

            <div
              style={{
                position: "relative",
                height: "25px",
                width: "25px",
                backgroundColor: "white",
                borderRadius: "3px",
              }}
            >
              <div style={{ position: "absolute", top: "-22px", right: "6px" }}>
                <TagBadge
                  style={{ backgroundColor: "white" }}
                  value={selected.length.toString()}
                  size="small"
                  accent={selected.length > 0 ? "viridiangreen" : "grey-5"}
                />
              </div>
            </div>
          </div>

          <TagTooltip
            tooltipFor=".tooltip-target"
            content="Current Week"
            width="150px"
            background="light"
            position="top"
          ></TagTooltip>

          <TagTooltip
            tooltipFor=".tooltip-target2"
            content="Approve All Week"
            position="top"
            width="150px"
            background="light"
          ></TagTooltip>
          <TagTooltip
            tooltipFor=".tooltip-target3"
            content="Reject All Week"
            position="top"
            width="150px"
            background="light"
          ></TagTooltip>
          <TagTooltip
            tooltipFor=".tooltip-target4"
            content="Clear Select"
            position="top"
            width="150px"
            background="light"
          ></TagTooltip>
          <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>
          <div style={{ display: "flex" }}>
            <TagCombobox
              key={value}
              inputStyle={{ textAlign: "center" }}
              disabled={
                selected.length === 0 ||
                selected.length > 1 ||
                holidayOrNot.length > 0
                  ? true
                  : false
              }
              searchable
              clearButton
              style={{ width: "120px" }}
              listWidth={120}
              inputDisabled
              textField="time"
              valueField="id"
              placeholder="Start time"
              value={dayState.dayBegin}
              data={dataForCombo}
              onValueChange={(e: CustomEvent<IComboChangeArgs>) => {
                setDayState((prev: any) => ({
                  ...prev,
                  dayBegin: e.detail.text,
                }));
              }}
            />

            <TagCombobox
              key={value2}
              inputStyle={{ textAlign: "center" }}
              disabled={
                selected.length === 0 ||
                selected.length > 1 ||
                holidayOrNot.length > 0
                  ? true
                  : false
              }
              searchable
              clearButton
              style={{ width: "120px" }}
              listWidth={120}
              inputDisabled
              textField="time"
              valueField="time"
              placeholder="End time"
              value={dayState.dayEnd}
              data={dataForCombo}
              onValueChange={(e) => {
                setDayState((prev: any) => ({
                  ...prev,
                  dayEnd: e.detail.value,
                }));
              }}
            />
          </div>
          <TagButton
            text="Save"
            style={{ paddingBottom: "3px" }}
            accent={
              selected.length === 0 ||
              selected.length > 1 ||
              dayState.dayBegin === undefined ||
              dayState.dayEnd === undefined ||
              holidayOrNot.length > 0
                ? "grey-1"
                : "viridiangreen"
            }
            disabled={
              selected.length === 0 ||
              selected.length > 1 ||
              dayState.dayBegin === undefined ||
              dayState.dayEnd === undefined ||
              holidayOrNot.length > 0
                ? true
                : false
            }
            onButtonClick={() => handleSubmit()}
          />
          <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <TagIcon
              icon="ChevronLeft"
              className={styles.navIcon}
              accent="title"
              size="default"
              iconStyle={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
              onClick={() => handlePrevOrNextDate(false)}
            />

            <TagText
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              text={
                statsForManagerStartDate.getDate() +
                " " +
                monthNames[statsForManagerStartDate.getMonth()] +
                " - " +
                statsForManagerEndDate.getDate() +
                " " +
                monthNames[statsForManagerEndDate.getMonth()] +
                " " +
                statsForManagerEndDate.getFullYear()
              }
            ></TagText>
            <TagIcon
              icon="ChevronRight"
              className={styles.navIcon}
              accent="title"
              size="default"
              iconStyle={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
              onClick={() => handlePrevOrNextDate(true)}
            />
          </div>
        </div>
        <div>
          <Row>
            <Row
              style={{
                minWidth: "1050px",
                marginLeft: "15px",
                color: "#4b4e52"
              }}  
            >
              <Col style={{minWidth: "300px", color: "#4b4e52" }}>
                My Team
              </Col>
                {data?.map((d, index) => {
                  return (
                    <Col
                      key={index}
                      style={{
                        color: "#4b4e52",
                        fontSize: "1rem"
                    }}
                    >
                      {daysOfWeek[index] +
                        " " +
                        new Date(d.date).getDate() +
                        "th"}
                    </Col>
                  );
                })}
            </Row>
          </Row>
          <Row>
            {statsForManagerWithUiKey.map((s: any, index: any) => {
              return (
                <Row
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "1050px",
                    marginLeft: "15px",
                  }}
                >
                  <Col
                    style={{
                      minWidth: "300px",
                      border: "1px solid #4b4e52",
                      color: "#4b4e52",
                      fontSize: "1rem",
                    }}
                  >
                    {s.employee.fullName}
                  </Col>
                  {s.days.map((d: any, index: any) => {
                    return (
                      <Col
                        className={styles.tooltip}
                        onClick={() => {
                          handleButtonClick(d, s.employee.id);
                        }}
                        style={
                          uiSelect.includes(d.uiKey)
                            ? {
                                border: "1px solid #4b4e52",
                                color: "#4b4e52",
                                backgroundColor: "#8ec5fc",
                                fontSize: "0.9rem",
                              }
                            : d.timesheetValidation?.statusLiteral ===
                              "Rejected"
                            ? {
                                border: "1px solid #4b4e52",
                                color: "white",
                                backgroundColor: "#e5173f",
                                fontSize: "0.9rem",
                              }
                            : d.timesheetValidation?.statusLiteral ===
                              "Approved"
                            ? {
                                border: "1px solid #4b4e52",
                                color: "white",
                                backgroundColor: "#099",
                                fontSize: "0.9rem",
                              }     
                              : d.holidayType?.name ===
                              "Sarbatoare nationala"
                            ? {
                                border: "1px solid #4b4e52",
                                color: "white",
                                backgroundColor: "#099",
                                fontSize: "0.9rem",
                              }                       
                            : {
                                border: "1px solid #4b4e52",
                                color: "#4b4e52",
                                fontSize: "0.9rem",
                              }
                        }
                        key={index}
                      >
                        {d.id === null && d.holidayType === null
                          ? "-"
                          : d.holidayType?.shortName ||
                            d.dayBegin.slice(0, -3) +
                              " - " +
                              d.dayEnd.slice(0, -3)}
                        {d.timesheetValidation !== null &&
                        d.timesheetValidation?.validatedBy !== "unknown" ? (
                          <div className={styles.tooltiptext}>
                            <p>{d.timesheetValidation?.validatedBy}</p>
                            <p>{`${d.timesheetValidation?.withCredentials} - ${d.timesheetValidation?.statusLiteral}`}</p>
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Row>
        </div>
        <div style={{ paddingTop: "100px" }}></div>
      </div>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {generateMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type="success"
                text="Your download will start in a few seconds..."
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessage(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageForManagerUpdateTimesheet &&
          generateMessageForUpdateTimesheet ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageForManagerUpdateTimesheet.status === 200
                    ? "success"
                    : "warning"
                }
                text={
                  messageForManagerUpdateTimesheet.status === 200
                    ? "Day Updated!"
                    : "Something went wrong!"
                }
                showDuration={3000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessageForUpdateTimsheet(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
