import {
  TagAlert,
  TagButton,
  TagStat,
  TagToast,
} from "@tag/tag-components-react-v2";
import { Col, Divider, Row } from "antd";
import { StatsModel } from "../../common/Models/StatsModel";
import { ProfileModel } from "../../common/Models/ProfileModel";

import { Carousel } from "antd";
import styles from "./StatisticsPage.module.scss";
import { DepartmentsChart } from "./DepartmentsChart";
import { HolidaysChart } from "./HolidaysChart";
import { useState } from "react";

export interface StatiticsPageProps {
  employees: ProfileModel[];
  statistics: StatsModel | undefined;
  onExportIncompleteTimesheet: () => void;
}

export const StatisticsPage = ({
  employees,
  statistics,
  onExportIncompleteTimesheet,
}: StatiticsPageProps) => {
  const [generateMessage, setGenerateMessage] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    // className: SlickMain,
    //dotsClass: "button__bar",
    arrows: false,
  };

  const settingsWithModules = {
    ...settings,
    dotsClass: styles.button__bar,
  };

  return (
    <div
      style={{
        padding: "10px 15px 0px 15px",
      }}
    >
      <Divider orientation="left">Statistics</Divider>
      <div className={styles.parentContainer}>
        <Carousel {...settingsWithModules}>
          <div className={styles.slideContainer}>
            <div className={styles.slides}>
              <TagStat
                className={styles.stats}
                style={{ padding: "10px" }}
                label="Active employees"
                value={statistics!.totalEployees}
                accent="viridiangreen"
                orientation="vertical"
              />
              <TagStat
                className={styles.stats}
                style={{ padding: "10px" }}
                label="New hires 2021 "
                value={employees
                  .filter(
                    (d) => new Date(d.startDate) >= new Date("2021/01/01")
                  )
                  .length.toString()}
                accent="viridiangreen"
                orientation="vertical"
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TagStat
                  className={styles.stats}
                  style={{ padding: "10px" }}
                  label="Incomplete Timesheets last week/this month"
                  value={`${statistics!.badApplesWeek}/${
                    statistics!.badApplesMonth
                  }`}
                  accent="viridiangreen"
                  orientation="vertical"
                />
                <TagButton
                  icon="Download2"
                  iconAccent="viridiangreen"
                  onButtonClick={() => {
                    setGenerateMessage(true);
                    onExportIncompleteTimesheet();
                  }}
                />
              </div>

              <TagStat
                className={styles.stats}
                style={{ padding: "10px" }}
                label="FT/PT"
                value={`${statistics!.fullTimers.toString()}/${statistics!.partTimers.toString()}`}
                accent="viridiangreen"
                orientation="vertical"
              />

              <TagStat
                className={styles.stats}
                style={{ padding: "10px" }}
                label="Home/Office Today"
                value={`${statistics!.homeOfficeCount.toString()}/${statistics!.officeCount.toString()}`}
                accent="viridiangreen"
                orientation="vertical"
              />
            </div>
          </div>
        </Carousel>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className={"pt-4"}
      >
        <DepartmentsChart statistics={statistics} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HolidaysChart statistics={statistics} />
      </div>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {generateMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type="success"
                text="Your download will start in a few seconds..."
                showDuration={3000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessage(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
