import {
    TagButton,
    TagForm,
} from "@tag/tag-components-react-v2";


type DeleteUserProps = {
    id: string | null;
    onDeleteHolidayAdmin: (id: string) => void;
    closeModal: () => void;
    messageDeleteHoliday: () => void;
};
export const DeleteHolidayForm = ({
    id,
    onDeleteHolidayAdmin,
    closeModal,
    messageDeleteHoliday,
}: DeleteUserProps) => {

    function handleFormData() {
        onDeleteHolidayAdmin(id!);
        messageDeleteHoliday();
        closeModal();
    }

    const formStyle: React.CSSProperties = { border: "0px" };
    const editFieldStyle: React.CSSProperties = {
        marginTop: "15px",
    };

    return (
        <div>
            <TagForm
                submitButtonHidden
                submitButtonText="Delete"
                submitButtonAccent="access"
                submitButtonAlign="right"
                formStyle={formStyle}
                inline
            >

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <TagButton
                        buttonStyle={{
                            marginTop: "15px",
                            marginBottom: "-6px",
                            marginLeft: "10px",
                        }}
                        text="Delete"
                        icon="Delete"
                        iconAccent="white"
                        accent="access"
                        onButtonClick={() => handleFormData()}
                    />
                    <TagButton
                        buttonStyle={{
                            marginTop: "15px",
                            marginBottom: "-6px",
                            marginRight: "10px",
                        }}
                        text="Exit"
                        accent="title"
                        icon="Exit"
                        iconAccent="porcelain"
                        onClick={() => {
                            closeModal();
                        }}
                    ></TagButton>
                </div>
            </TagForm>
        </div>
    );
};
