import { useEffect, useState } from "react";
import { useCookieState } from "use-cookie-state";
import styles from "./Router.module.scss";
import { Nav } from "react-bootstrap";
import { TagCard, TagIcon } from "@tag/tag-components-react-v2";
import {
  BrowserRouter,
  NavLink,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ProfilePage } from "../ProfilePage/ProfilePage";
import { TimesheetPage } from "../TimesheetPage/TimesheetPage";
import { SettingsPage } from "../SettingsPage/SettingsPage";
import { DevelopersPage } from "../DevelopersPage/DevelopersPage";
import { DaysOffPage } from "../OffDaysPage/DaysOffPage";
import { ManagersPage } from "../Managers/ManagersPage";
import { loginRequest } from "../../msalAuthConfig";
import { msalAuth } from "../../services/msalAuth.service";
import { timesheet_service } from "../../services/timesheet.service";
import { holiday_service } from "../../services/holiday.service";
import { profile_service } from "../../services/profile.service";
import { managers_service } from "../../services/managers.service";
import { admin_service } from "../../services/admin.service";
import { dev_service } from "../../services/dev.service";
import { useMsal } from "@azure/msal-react";
import * as jwt from "jsonwebtoken";
import { ProfileModel } from "../../common/Models/ProfileModel";
import { TimesheetModel } from "../../common/Models/TimesheetModel";
import { HolidayModel } from "../../common/Models/HolidayModel";
import { EditHolidayModel } from "../../common/Models/EditHolidayModel";
import { IntervalModel } from "../../common/IntervalModel";
import { StatsModel } from "../../common/Models/StatsModel";
import { DevLogsModel } from "../../common/Models/DevLogsModel";
import { startOfWeek, endOfWeek } from "date-fns";
import { CreateTimesheetModel } from "../../common/Models/CreateTimesheetModel";
import { AddTimesheetModel } from "../../common/Models/AddTimesheetModel";
import { ReportsPage } from "../ReportsPage/ReportsPage";
import { EmployeesPage } from "../EmployeesPage/EmployeesPage";
import { Helmet } from "react-helmet";
import { FillWeekTimesheet } from "../../common/Models/FillWeekTimesheet";
import { HolidaysAdminModel } from "../../common/Models/HolidaysAdminModel";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";
import { EmployeeModel } from "../../common/Models/EmployeeModel";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { StatisticsPage } from "../StatisticsPage/StatisticsPage";
import { UpdateWorkHours } from "../../common/Models/UpdateWorkHours";
import { IncompleteTimesheetsModel } from "../../common/Models/IncompleteTimesheetsModel";
import { ManagerStatsModel } from "../../common/Models/ManagerStatsModel";
import { ValidationManyModel } from "../../common/Models/ValidationManyModel";
import { UpdateTimesheetAdminModel } from "../../common/Models/UpdateTimesheetAdminModel";

let curr = new Date();
let currentWeek = [];
for (let i = 1; i <= 5; i++) {
  let first = curr.getDate() - curr.getDay() + i;
  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
  currentWeek.push(day);
}

let lastWeekMonday = new Date(new Date().setDate(new Date().getDate() - 7 + 1));
let lastWeek = [];
for (let i = 1; i <= 5; i++) {
  let first = lastWeekMonday.getDate() - lastWeekMonday.getDay() + i;
  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
  lastWeek.push(day);
}

let lastTwoWeeksMonday = new Date(
  new Date().setDate(new Date().getDate() - 14 + 1)
);
let lastTwoWeeks = [];
for (let i = 1; i <= 5; i++) {
  let first = lastTwoWeeksMonday.getDate() - lastTwoWeeksMonday.getDay() + i;
  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
  lastTwoWeeks.push(day);
}

export const Router = () => {
  const { instance } = useMsal();
  const [token, setToken] = useState<string | null>(null);
  const [profile, setProfile] = useState<ProfileModel | null>(null);
  const [holidays, setHolidays] = useState<HolidayModel[] | null>(null);
  const [holidaysAdmin, setHolidaysAdmin] = useState<
    HolidaysAdminModel[] | null
  >(null);
  const [holidaysManager, setHolidaysManager] = useState<
    HolidaysAdminModel[] | null
  >(null);
  const [refreshHolidays, setRefreshHolidays] = useState<number>(0);
  const [refreshProfile, setRefreshProfile] = useState<number>(0);
  const [refreshWeek, setRefreshWeek] = useState<number>(0);
  const [refreshEmployees, setRefreshEmployees] = useState<number>(0);
  const [refreshManagers, setRefreshManagers] = useState<number>(0);
  const [tokenCookie, setTokenCookie] = useCookieState<string>("token", "");
  const [holidayMessage, setHolidayMessage] = useState<any>();
  const [authError, setAuthError] = useState<boolean>(false);
  const [importProfileMessage, setImportProfileMessage] = useState<any>();
  const [importXDMessage, setImportXDMessage] = useState<any>();
  const [messageForAdmin, setMessageForAdmin] = useState<any>();
  const [messageForManager, setMessageForManager] = useState<any>();
  const [messageForAdminUpdateTimesheet, setMessageForAdminUpdateTimesheet] =
    useState<any>();
  const [
    messageForManagerUpdateTimesheet,
    setMessageForManagerUpdateTimesheet,
  ] = useState<any>();
  const [messageAdminDelete, setMessageAdminDelete] = useState<any>();
  const [messageManagerDelete, setMessageManagerDelete] = useState<any>();
  const [workTypes, setWorkTypes] = useState<WorkTypesModel[]>();
  const [employees, setEmployees] = useState<ProfileModel[] | null>(null);
  const [statistics, setStats] = useState<StatsModel>();
  const [logs, setLogs] = useState<DevLogsModel[]>();
  const [departments, setDepartments] = useState<DepartmentModel[]>();
  const [messageAddEmployeeAdmin, setMessageAddEmployeeAdmin] = useState<any>();
  const [messageUpdateWorkLocation, setMessageUpdateWorkLocation] =
    useState<any>();
  const [messageDeleteEmployeeAdmin, setMessageDeleteEmployeeAdmin] =
    useState<any>();
  const [badApples, setBadApples] = useState<IncompleteTimesheetsModel>();

  const [week, setWeek] = useState<TimesheetModel[] | null>(null);
  const [statsForManager, setStatsForManager] = useState<
    ManagerStatsModel[] | null
  >(null);
  const [statsForAdmin, setStatsForAdmin] = useState<
    ManagerStatsModel[] | null
  >(null);
  const [startDate, setStartDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState<Date>(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const [statsForManagerStartDate, setStatsForManagerStartDate] =
    useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [statsForManagerEndDate, setStatsForManagerEndDate] = useState<Date>(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const [statsForAdminStartDate, setStatsForAdminStartDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [statsForAdminEndDate, setStatsForAdminEndDate] = useState<Date>(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const [loading, setLoading] = useState(true);
  const [roleForManager, setRoleForManager] = useState<any>([]);
  const refreshHolidayPage = (token: string | null) => {
    if (token) {
      holiday_service.getHolidays(token).then((a) => setHolidays(a));
    }
  };

  const refreshManagersPage = (token: string | null) => {
    if (profile?.roles.includes("Manager") && tokenCookie) {
      managers_service.getBadApples(tokenCookie).then((a) => setBadApples(a));
    }
  };

  const refreshProfilePage = (token: string | null) => {
    if (token) {
      profile_service.getProfile(token).then((a) => setProfile(a));
    }
  };

  const refreshEmployeesPage = (token: string | null) => {
    if (profile?.roles.includes("Admin") && token) {
      admin_service.getEmployees(token).then((a) => setEmployees(a));
    }
  };

  const refreshDevelopersPage = (token: string | null) => {
    if (profile?.roles.includes("Developer") && token) {
      dev_service.getLogs(tokenCookie).then((a) => setLogs(a));
    }
  };

  const refreshAdminPage = (token: string | null) => {
    if (token) {
      admin_service.getHolidaysAdmin(token).then((a) => setHolidaysAdmin(a));
    }
  };

  const onUpdateSchedule = (begin: string, end: string) => {
    if (tokenCookie) {
      profile_service.updateSchedule(begin, end, tokenCookie).then(() => {
        refreshProfilePage(tokenCookie);
        setRefreshProfile(refreshProfile + 1);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onAddHoliday = (holiday: HolidayModel) => {
    if (tokenCookie) {
      holiday_service.addHoliday(holiday, tokenCookie).then((a) => {
        refreshHolidayPage(tokenCookie);
        setHolidayMessage(a);
        setRefreshHolidays(refreshHolidays + 1);
        setRefreshWeek(refreshWeek + 1);
      });
    }
  };

  const onEditHoliday = (holiday: EditHolidayModel) => {
    if (tokenCookie) {
      holiday_service.editHoliday(holiday, tokenCookie).then((a) => {
        refreshHolidayPage(tokenCookie);
        setRefreshHolidays(refreshHolidays + 1);
        setHolidayMessage(a);
        setRefreshWeek(refreshWeek + 1);
      });
    }
  };

  const onDeleteHoliday = (holiday: HolidayModel) => {
    if (holiday.id && tokenCookie) {
      holiday_service.deleteHoliday(holiday.id, tokenCookie).then(() => {
        refreshHolidayPage(tokenCookie);
        setRefreshHolidays(refreshHolidays + 1);
        setRefreshWeek(refreshWeek + 1);
      });
    }
  };

  const onExportReport = (begin: Date, end: Date) => {
    if (tokenCookie) {
      admin_service.exportReport(begin, end, tokenCookie);
    }
  };

  const onExportIncompleteTimesheet = () => {
    if (tokenCookie) {
      admin_service.exportIncompleteTimesheet(tokenCookie);
    }
  };

  const onExportManagersReport = (begin: Date, end: Date) => {
    if (tokenCookie) {
      managers_service.exportManagersReport(begin, end, tokenCookie);
    }
  };

  const onManagerValidateMany = (days: ValidationManyModel[]) => {
    if (tokenCookie) {
      managers_service.managerValidateMany(days, tokenCookie).then((a) => {
        refreshManagersPage(tokenCookie);
        setRefreshManagers(refreshManagers + 1);
        setRefreshWeek(refreshWeek + 1);
      });
    }
  };

  const onManagerValidateAll = (begin: Date, end: Date, statusCode: number) => {
    if (tokenCookie) {
      managers_service
        .managerValidateAll(begin, end, statusCode, tokenCookie)
        .then((a) => {
          refreshManagersPage(tokenCookie);
          setRefreshManagers(refreshManagers + 1);
          setRefreshWeek(refreshWeek + 1);
        });
    }
  };

  const onUpdateTimesheetManager = (
    id: number,
    day: UpdateTimesheetAdminModel
  ) => {
    if (tokenCookie) {
      managers_service
        .updateTimesheetManager(id, day, tokenCookie)
        .then((a) => {
          refreshManagersPage(tokenCookie);
          setRefreshManagers(refreshManagers + 1);
          setRefreshWeek(refreshWeek + 1);
          setRefreshEmployees(refreshEmployees + 1);
          setMessageForManagerUpdateTimesheet(a);
        });
    }
  };

  const onDeleteHolidayManager = (id: string) => {
    if (tokenCookie) {
      managers_service.deleteHolidayManager(id, tokenCookie).then((a) => {
        setMessageManagerDelete(a);
        refreshAdminPage(tokenCookie);
        refreshHolidayPage(tokenCookie);
        setRefreshHolidays(refreshHolidays + 1);
        setRefreshWeek(refreshWeek + 1);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onAddHolidayManager = (holiday: HolidayModel, email: string) => {
    if (tokenCookie) {
      managers_service
        .addHolidayManager(holiday, tokenCookie, email)
        .then((a) => {
          refreshHolidayPage(tokenCookie);
          refreshAdminPage(tokenCookie);
          setMessageForManager(a);
          setRefreshHolidays(refreshHolidays + 1);
          setRefreshWeek(refreshWeek + 1);
          refreshDevelopersPage(tokenCookie);
        });
    }
  };

  const onImportProfile = (file: any) => {
    if (tokenCookie) {
      admin_service.importProfile(file, tokenCookie).then((a) => {
        setImportProfileMessage(a);
        setRefreshEmployees(refreshEmployees + 1);
        refreshManagersPage(tokenCookie);
        setRefreshManagers(refreshManagers + 1);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onImportXD = (file: any) => {
    if (tokenCookie) {
      admin_service.importXD(file, tokenCookie).then((a) => {
        setImportXDMessage(a);
      });
    }
  };

  const onAddHolidayAdmin = (holiday: HolidayModel, email: string) => {
    if (tokenCookie) {
      admin_service.addHolidayAdmin(holiday, tokenCookie, email).then((a) => {
        refreshHolidayPage(tokenCookie);
        refreshAdminPage(tokenCookie);
        setMessageForAdmin(a);
        setRefreshHolidays(refreshHolidays + 1);
        setRefreshWeek(refreshWeek + 1);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onDeleteHolidayAdmin = (id: string) => {
    if (tokenCookie) {
      admin_service.deleteHolidayAdmin(id, tokenCookie).then((a) => {
        setMessageAdminDelete(a);
        refreshAdminPage(tokenCookie);
        refreshHolidayPage(tokenCookie);
        setRefreshHolidays(refreshHolidays + 1);
        setRefreshWeek(refreshWeek + 1);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onAddOrEditEmployeeAdmin = (employee: EmployeeModel, id: number) => {
    if (tokenCookie) {
      admin_service
        .addOrEditEmployeeAdmin(employee, tokenCookie, id)
        .then((a) => {
          setMessageAddEmployeeAdmin(a);
          setRefreshEmployees(refreshEmployees + 1);
          refreshProfilePage(tokenCookie);
          setRefreshProfile(refreshProfile + 1);
          setRefreshWeek(refreshWeek + 1);
          refreshManagersPage(tokenCookie);
          setRefreshManagers(refreshManagers + 1);
          refreshDevelopersPage(tokenCookie);
        });
    }
  };

  const onUpdateWorkHours = (id: number, workHours: UpdateWorkHours) => {
    if (tokenCookie) {
      admin_service.updateWorkingHours(id, workHours, tokenCookie).then((a) => {
        setMessageAddEmployeeAdmin(a);
        setRefreshEmployees(refreshEmployees + 1);
        refreshProfilePage(tokenCookie);
        setRefreshWeek(refreshWeek + 1);
        refreshEmployeesPage(tokenCookie);
        refreshDevelopersPage(tokenCookie);
      });
    }
  };

  const onUpdateWorkLocation = async (id: string, workTypeId: number) => {
    if (tokenCookie) {
      admin_service
        .updateWorkLocationSchedule(id, workTypeId, tokenCookie)
        .then((a) => {
          setMessageUpdateWorkLocation(a);
          setRefreshEmployees(refreshEmployees + 1);
          refreshProfilePage(tokenCookie);
          setRefreshProfile(refreshProfile + 1);
          setRefreshWeek(refreshWeek + 1);
          refreshEmployeesPage(tokenCookie);
          refreshDevelopersPage(tokenCookie);
        });
    }
  };

  const onDeleteEmployeeAdmin = (id: number, leaveDate: Date) => {
    if (tokenCookie) {
      admin_service
        .deleteEmployeeAdmin(id, tokenCookie, leaveDate)
        .then((a) => {
          setMessageDeleteEmployeeAdmin(a);
          setRefreshEmployees(refreshEmployees + 1);
          refreshManagersPage(tokenCookie);
          setRefreshManagers(refreshManagers + 1);
          refreshDevelopersPage(tokenCookie);
        });
    }
  };

  const onAdminValidateMany = (days: ValidationManyModel[]) => {
    if (tokenCookie) {
      admin_service.adminValidateMany(days, tokenCookie).then((a) => {
        refreshManagersPage(tokenCookie);
        setRefreshManagers(refreshManagers + 1);
        setRefreshWeek(refreshWeek + 1);
        setRefreshEmployees(refreshEmployees + 1);
      });
    }
  };

  const onAdminValidateAll = (begin: Date, end: Date, statusCode: number) => {
    if (tokenCookie) {
      admin_service
        .adminValidateAll(begin, end, statusCode, tokenCookie)
        .then((a) => {
          refreshManagersPage(tokenCookie);
          setRefreshManagers(refreshManagers + 1);
          setRefreshWeek(refreshWeek + 1);
          setRefreshEmployees(refreshEmployees + 1);
        });
    }
  };

  const onAdminFillWeekAll = (begin: Date, end: Date) => {
    if (tokenCookie) {
      admin_service.adminFillWeekAll(begin, end, tokenCookie).then((a) => {
        refreshManagersPage(tokenCookie);
        setRefreshManagers(refreshManagers + 1);
        setRefreshWeek(refreshWeek + 1);
        setRefreshEmployees(refreshEmployees + 1);
      });
    }
  };

  const onUpdateTimesheetAdmin = (
    id: number,
    day: UpdateTimesheetAdminModel
  ) => {
    if (tokenCookie) {
      admin_service.updateTimesheetAdmin(id, day, tokenCookie).then((a) => {
        refreshManagersPage(tokenCookie);
        setRefreshManagers(refreshManagers + 1);
        setRefreshWeek(refreshWeek + 1);
        setRefreshEmployees(refreshEmployees + 1);
        setMessageForAdminUpdateTimesheet(a);
      });
    }
  };

  useEffect(() => {
    if (token === null)
      msalAuth.aquireToken(instance, loginRequest).then((a) => {
        setToken(a);
      });
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      if (decoded && typeof decoded !== "string") {
        msalAuth.login(decoded.upn).then((a) => {
          setTokenCookie(a);
        });
      }
    }
  }, [token]);

  useEffect(() => {
    if (tokenCookie) {
      const tokenApp = jwt.decode(tokenCookie);
      if (tokenApp && typeof tokenApp !== "string") {
        const { role } = tokenApp;

        setRoleForManager(role);
      }
    }
  }, [tokenCookie]);

  const role = roleForManager;

  useEffect(() => {
    if (role.includes("User") && tokenCookie) {
      timesheet_service.getWeek(tokenCookie, startDate, endDate).then((a) => {
        setWeek(a);
      });
    }
  }, [tokenCookie, startDate, endDate, refreshWeek, role]);

  const onGetTimesheet = (begin: Date, end: Date) => {
    if (role.includes("User") && tokenCookie) {
      timesheet_service
        .getWeek(tokenCookie, begin, end)
        .then((a) => setWeek(a));
    } else {
      setAuthError(true);
    }
  };

  const currentDay = new Date();
  useEffect(() => {
    if (role.includes("User") && tokenCookie) {
      timesheet_service.getCurrentDay(tokenCookie, currentDay).then((a) => {});
    }
  }, [tokenCookie, startDate, endDate, refreshWeek, role]);

  const onValidateRange = (week: IntervalModel) => {
    timesheet_service.validateRange(week, tokenCookie).then(() => {
      setRefreshWeek(refreshWeek + 1);
    });
  };

  const onAddTimesheet = (timesheet: AddTimesheetModel) => {
    timesheet_service.addTimesheet(timesheet, tokenCookie).then(() => {
      setRefreshWeek(refreshWeek + 1);
    });
  };

  const onUpdateTimesheet = (timesheet: CreateTimesheetModel) => {
    return timesheet_service
      .updateTimesheet(timesheet, tokenCookie)
      .then(() => {
        setRefreshWeek(refreshWeek + 1);
      });
  };

  const onFillWeekTimesheet = (week: FillWeekTimesheet) => {
    timesheet_service.fiilWeekTimesheet(week, tokenCookie).then(() => {
      setRefreshWeek(refreshWeek + 1);
    });
  };

  const onUpdateWorkType = (date: string, workTypeId: WorkTypesModel) => {
    timesheet_service.updateWorkType(date, workTypeId, tokenCookie).then(() => {
      setRefreshWeek(refreshWeek + 1);
    });
  };

  useEffect(() => {
    if (role.includes("User") && tokenCookie) {
      profile_service.getProfile(tokenCookie).then((a) => {
        setProfile(a);
        return a;
      });
    }
  }, [tokenCookie, refreshProfile, refreshEmployees, role]);

  useEffect(() => {
    if (role.includes("User") && tokenCookie) {
      holiday_service.getHolidays(tokenCookie).then((a) => setHolidays(a));
    }
  }, [tokenCookie, refreshHolidays, role]);

  useEffect(() => {
    if (profile?.roles.includes("Admin") && tokenCookie) {
      admin_service
        .getHolidaysAdmin(tokenCookie)
        .then((a) => setHolidaysAdmin(a));
    }
  }, [tokenCookie, profile]);

  useEffect(() => {
    if (profile?.roles.includes("Developer") && tokenCookie) {
      dev_service.getLogs(tokenCookie).then((a) => setLogs(a));
    }
  }, [
    tokenCookie,
    profile,
    refreshEmployees,
    employees,
    refreshHolidays,
    holidaysAdmin,
  ]);

  useEffect(() => {
    if (tokenCookie) {
      timesheet_service.getWorkTypes(tokenCookie).then((a) => setWorkTypes(a));
    }
  }, [tokenCookie, refreshWeek]);

  useEffect(() => {
    if (profile?.roles.includes("Admin") && tokenCookie) {
      admin_service.getEmployees(tokenCookie).then((a) => setEmployees(a));
    }
  }, [tokenCookie, profile, refreshEmployees]);

  useEffect(() => {
    if (profile?.roles.includes("Admin") && tokenCookie) {
      admin_service.getStats(tokenCookie).then((a) => setStats(a));
    }
  }, [tokenCookie, profile, refreshEmployees]);

  useEffect(() => {
    if (tokenCookie) {
      admin_service.getDepartments(tokenCookie).then((a) => setDepartments(a));
    }
  }, [tokenCookie]);

  useEffect(() => {
    if (role.includes("Manager") && tokenCookie) {
      managers_service.getBadApples(tokenCookie).then((a) => setBadApples(a));
    }
  }, [tokenCookie, refreshManagers, role]);

  const onGetManagerStats = (begin: Date, end: Date) => {
    if (tokenCookie) {
      managers_service
        .getValidationStatsForManager(tokenCookie, begin, end)
        .then((a) => setStatsForManager(a));
    } else {
      setAuthError(true);
    }
  };

  useEffect(() => {
    if (role.includes("Manager") && tokenCookie) {
      managers_service
        .getHolidaysManager(tokenCookie)
        .then((a) => setHolidaysManager(a));
    }
  }, [tokenCookie, refreshHolidays, role]);

  useEffect(() => {
    if (role.includes("Manager") && tokenCookie) {
      managers_service
        .getValidationStatsForManager(
          tokenCookie,
          statsForManagerStartDate,
          statsForManagerEndDate
        )
        .then((a) => {
          setStatsForManager(a);
        });
    }
  }, [
    tokenCookie,
    statsForManagerStartDate,
    statsForManagerEndDate,
    refreshManagers,
    refreshHolidays,
    role,
  ]);

  useEffect(() => {
    setLoading(true);
    if (role.includes("User") && tokenCookie) {
      admin_service
        .getValidationStatsForAdmin(
          tokenCookie,
          statsForAdminStartDate,
          statsForAdminEndDate
        )
        .then((a) => {
          setStatsForAdmin(a);
          setLoading(false);
        });
    }
  }, [
    tokenCookie,
    statsForAdminStartDate,
    statsForAdminEndDate,
    refreshManagers,
    refreshEmployees,
    refreshHolidays,
    role,
  ]);

  return (
    <BrowserRouter>
      <Nav
        defaultActiveKey="/home"
        className={"flex-column " + styles.navbar}
        style={{ justifyContent: "space-between", flexDirection: "column" }}
      >
        <div>
          <NavLink
            hidden={role?.includes("Manager") && !role?.includes("User")}
            to="/timesheet"
            className={styles.item}
            activeClassName={styles.active}
          >
            <TagIcon icon="CalendarBlank" />
            <span className="pl-3">Timesheet</span>
          </NavLink>
          <NavLink
            hidden={role?.includes("Manager") && !role?.includes("User")}
            to="/profile"
            className={styles.item}
            activeClassName={styles.active}
          >
            <TagIcon icon="CircleUser" />
            <span className="pl-3">Profile</span>
          </NavLink>
          <NavLink
            hidden={role?.includes("Manager") && !role?.includes("User")}
            to="/leaves"
            className={styles.item}
            activeClassName={styles.active}
          >
            <TagIcon icon="WheeledSuitcase" />
            <span className="pl-3">Leaves</span>
          </NavLink>
          <NavLink
            to="/managers"
            className={styles.item}
            activeClassName={styles.active}
            hidden={true && !role.includes("Manager")}
          >
            <TagIcon icon="Presentation" />
            <span className="pl-3">Managers</span>
          </NavLink>
          <NavLink
            to="/employees"
            className={styles.item}
            activeClassName={styles.active}
            hidden={true && !profile?.roles.includes("Admin")}
          >
            <TagIcon icon="Users" />
            <span className="pl-3">Employees</span>
          </NavLink>
          <NavLink
            to="/reports"
            className={styles.item}
            activeClassName={styles.active}
            hidden={true && !profile?.roles.includes("Admin")}
          >
            <TagIcon icon="Locked" />
            <span className="pl-3">Admin</span>
          </NavLink>
          <NavLink
            to="/statistics"
            className={styles.item}
            activeClassName={styles.active}
            hidden={true && !profile?.roles.includes("Admin")}
          >
            <TagIcon icon="BarChart" />
            <span className="pl-3">Statistics</span>
          </NavLink>
          <NavLink
            to="/logs"
            className={styles.item}
            activeClassName={styles.active}
            hidden={true && !profile?.roles.includes("Developer")}
          >
            <TagIcon icon="Tools" />
            <span className="pl-3">Developers</span>
          </NavLink>
        </div>
        <NavLink
          to="/logout"
          className={styles.item + " pb-4"}
          activeClassName={styles.active}
          onClick={() => {
            instance.logout();
            setTokenCookie("");
          }}
        >
          <TagIcon icon="Exit" />
          <span className="pl-3">Logout</span>
        </NavLink>
      </Nav>

      <div className={styles["app-container"]}>
        <Switch>
          <Route exact path="/timesheet">
            {week && profile && workTypes ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {/* {role.includes("Manager") && !role.includes("User") ? (
                  <Redirect to="/managers" />
                ) : (
                  <></>
                )} */}
                <TimesheetPage
                  authError={authError}
                  days={week}
                  isFired={profile.isFired}
                  hours={profile.workingHours}
                  workTypes={workTypes}
                  scheduleBegin={profile.scheduleBegin}
                  scheduleEnd={profile.scheduleEnd}
                  shiftBegin={profile.department.shiftBegin}
                  shiftEnd={profile.department.shiftEnd}
                  onValidate={onValidateRange}
                  onFillWeek={onFillWeekTimesheet}
                  onAdd={onAddTimesheet}
                  onUpdate={onUpdateTimesheet}
                  onGet={onGetTimesheet}
                  onSetStartDate={setStartDate}
                  onSetEndDate={setEndDate}
                  onUpdateWorkType={onUpdateWorkType}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/profile">
            {profile ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {/* {role.includes("Manager") && !role.includes("User") ? (
                  <Redirect to="/managers" />
                ) : (
                  <></>
                )} */}
                <ProfilePage
                  email={profile.email}
                  fullName={profile.fullName}
                  managerEmail={profile.managerEmail}
                  startDate={profile.startDate}
                  department={profile.department}
                  shiftBegin={profile.department.shiftBegin}
                  shiftEnd={profile.department.shiftEnd}
                  scheduleBegin={profile.scheduleBegin}
                  scheduleEnd={profile.scheduleEnd}
                  workingHours={profile.workingHours}
                  workPractice={profile.workPractice}
                  officeLocation={profile.officeLocation}
                  isFired={profile.isFired}
                  workLocationSchedule={profile.workLocationSchedule}
                  onUpdate={onUpdateSchedule}
                />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/leaves">
            {holidays ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {/* {role.includes("Manager") && !role.includes("User") ? (
                  <Redirect to="/managers" />
                ) : (
                  <></>
                )} */}
                {profile ? (
                  <DaysOffPage
                    holidays={holidays}
                    holidayMessage={holidayMessage}
                    isFired={profile?.isFired}
                    onAdd={onAddHoliday}
                    onDelete={onDeleteHoliday}
                    onEdit={onEditHoliday}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/managers">
            {badApples && statsForManager && holidaysManager ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {!role.includes("Manager") ? <Redirect to="/" /> : <></>}
                <ManagersPage
                  onExportManagersReport={onExportManagersReport}
                  badApples={badApples}
                  statsForManager={statsForManager}
                  onGetManagerStats={onGetManagerStats}
                  statsForManagerStartDate={statsForManagerStartDate}
                  statsForManagerEndDate={statsForManagerEndDate}
                  onSetStatsForManagerStartDate={setStatsForManagerStartDate}
                  onSetStatsForManagerEndDate={setStatsForManagerEndDate}
                  onManagerValidateMany={onManagerValidateMany}
                  onManagerValidateAll={onManagerValidateAll}
                  onUpdateTimesheetManager={onUpdateTimesheetManager}
                  messageForManagerUpdateTimesheet={
                    messageForManagerUpdateTimesheet
                  }
                  holidaysManager={holidaysManager}
                  onDeleteHolidayManager={onDeleteHolidayManager}
                  messageManagerDelete={messageManagerDelete}
                  onAddHolidayManager={onAddHolidayManager}
                  messageForManager={messageForManager}
                />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/reports">
            {holidaysAdmin && employees ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {!profile?.roles.includes("Admin") ? (
                  <Redirect to="/" />
                ) : (
                  <></>
                )}
                <ReportsPage
                  onExportReport={onExportReport}
                  onImportProfile={onImportProfile}
                  onImportXD={onImportXD}
                  importProfileMessage={importProfileMessage}
                  importXDMessage={importXDMessage}
                />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/employees">
            {holidaysAdmin &&
            employees &&
            departments &&
            workTypes &&
            statsForAdmin ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {!profile?.roles.includes("Admin") ? (
                  <Redirect to="/" />
                ) : (
                  <></>
                )}
                <EmployeesPage
                  onAddHolidayAdmin={onAddHolidayAdmin}
                  messageForAdmin={messageForAdmin}
                  holidaysAdmin={holidaysAdmin}
                  onDeleteHolidayAdmin={onDeleteHolidayAdmin}
                  messageAdminDelete={messageAdminDelete}
                  employees={employees}
                  onAddOrEditEmployeeAdmin={onAddOrEditEmployeeAdmin}
                  messageAddEmployeeAdmin={messageAddEmployeeAdmin}
                  onDeleteEmployeeAdmin={onDeleteEmployeeAdmin}
                  messageDeleteEmployeeAdmin={messageDeleteEmployeeAdmin}
                  departments={departments}
                  onUpdateWorkHours={onUpdateWorkHours}
                  onUpdateWorkLocation={onUpdateWorkLocation}
                  messageUpdateWorkLocation={messageUpdateWorkLocation}
                  workTypes={workTypes}
                  statsForAdmin={statsForAdmin}
                  statsForAdminStartDate={statsForAdminStartDate}
                  statsForAdminEndDate={statsForAdminEndDate}
                  onSetStatsForAdminStartDate={setStatsForAdminStartDate}
                  onSetStatsForAdminEndDate={setStatsForAdminEndDate}
                  onAdminValidateMany={onAdminValidateMany}
                  onAdminValidateAll={onAdminValidateAll}
                  onUpdateTimesheetAdmin={onUpdateTimesheetAdmin}
                  onAdminFillWeekAll={onAdminFillWeekAll}
                  loading={loading}
                  messageForAdminUpdateTimesheet={
                    messageForAdminUpdateTimesheet
                  }
                />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/statistics">
            {statistics && employees ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>

                {!profile?.roles.includes("Admin") ? (
                  <Redirect to="/" />
                ) : (
                  <></>
                )}

                <StatisticsPage
                  employees={employees}
                  statistics={statistics}
                  onExportIncompleteTimesheet={onExportIncompleteTimesheet}
                />
              </>
            ) : (
              <></>
            )}
          </Route>

          <Route path="/settings">
            <>
              <Helmet>
                <title>Access RO Timesheet</title>
              </Helmet>
              <SettingsPage />
            </>
          </Route>
          <Route path="/logs">
            {logs ? (
              <>
                <Helmet>
                  <title>Access RO Timesheet</title>
                </Helmet>
                {!profile?.roles.includes("Developer") ? (
                  <Redirect to="/" />
                ) : (
                  <></>
                )}
                <DevelopersPage logs={logs} />
              </>
            ) : (
              <></>
            )}
          </Route>
          <Route path="/">
            {role?.includes("Manager") &&
            !role?.includes("User") &&
            profile === null ? (
              <Redirect to="/managers" />
            ) : profile ? (
              <div>
                <TagCard
                  version={2}
                  accent="dolphinblue"
                  backgroundImage="access"
                >
                  <h3 className="pl-2">Hello, {profile.fullName}</h3>
                  <h5 className="pl-2">Welcome to Access RO Timesheet</h5>
                  <h6 className="pl-2 pb-2" style={{ color: "PowderBlue" }}>
                    {" "}
                    Version 3.0 (September 2021)
                  </h6>
                </TagCard>
              </div>
            ) : (
              <></>
            )}
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};
