import {
  IComboChangeArgs,
  TagAlert,
  TagBadge,
  TagButton,
  TagCombobox,
  TagIcon,
  TagModal,
  TagPager,
  TagSearchbox,
  TagText,
  TagToast,
  TagTooltip,
} from "@tag/tag-components-react-v2";
import { addDays, endOfWeek, startOfWeek, subDays } from "date-fns";
import styles from "./ValidateEmployees.module.scss";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ManagerStatsModel } from "../../common/Models/ManagerStatsModel";
import { ValidationManyModel } from "../../common/Models/ValidationManyModel";
import { Loading } from "./Loading";
import { UpdateTimesheetAdminModel } from "../../common/Models/UpdateTimesheetAdminModel";

export interface ValidateEmployeesProps {
  statsForAdmin: ManagerStatsModel[];
  statsForAdminStartDate: Date;
  statsForAdminEndDate: Date;
  onSetStatsForAdminStartDate: (begin: Date) => void;
  onSetStatsForAdminEndDate: (en: Date) => void;
  onAdminValidateMany: (days: ValidationManyModel[]) => void;
  onAdminValidateAll: (begin: Date, end: Date, statusCode: number) => void;
  onAdminFillWeekAll: (begin: Date, end: Date) => void;
  onUpdateTimesheetAdmin: (id: number, day: UpdateTimesheetAdminModel) => void;
  messageForAdminUpdateTimesheet: any;
  loading: boolean;
}

export const ValidateEmployees = ({
  statsForAdmin,
  statsForAdminStartDate,
  statsForAdminEndDate,
  onSetStatsForAdminStartDate,
  onSetStatsForAdminEndDate,
  onAdminValidateMany,
  onAdminValidateAll,
  onUpdateTimesheetAdmin,
  onAdminFillWeekAll,
  messageForAdminUpdateTimesheet,
  loading,
}: ValidateEmployeesProps) => {
  const [selected, setSelected] = useState<ValidationManyModel[]>([]);
  const [comboValue, setComboValue] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [modalApprove, setModalApprove] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [modalForFillWeek, setModalForFillWeek] = useState(false);
  const [
    generateMessageForUpdateTimesheet,
    setGenerateMessageForUpdateTimsheet,
  ] = useState<boolean>(false);

  const [dayState, setDayState] = useState<any>({
    id: "",
    date: "",
    dayBegin: undefined,
    dayEnd: undefined,
    workTypeId: null,
    profileId: 0,
  });

  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(10);

  const increment = () => {
    setValue((cur) => cur + 1);
    setValue2((cur) => cur + 1);
  };

  statsForAdmin.sort((a, b) =>
    a.employee.fullName.localeCompare(b.employee.fullName)
  );

  const pageSize = 10;

  const pageSearch = statsForAdmin.filter(
    (a) =>
      a.employee.fullName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );

  const pageOfData = pageSearch
    .slice((page - 1) * pageSize, page * pageSize)
    .map((l) => {
      l.days = l.days.map((d) => ({
        uiKey:
          new Date(d.date).getDate().toLocaleString() +
          l.employee.id.toLocaleString(),
        ...d,
      }));
      return l;
    });

  const countAfterSearch = pageSearch.length;
  const pageCountSearch = Math.ceil(countAfterSearch / pageSize);

  const handleSearch = (e: any) => {
    setSearchString(e.detail.criteria);
    setPage(1);
  };

  const handlePrevOrNextDate = (goToFuture: boolean) => {
    if (goToFuture) {
      onSetStatsForAdminStartDate(addDays(statsForAdminStartDate, 7));
      onSetStatsForAdminEndDate(addDays(statsForAdminEndDate, 7));
    } else {
      onSetStatsForAdminStartDate(subDays(statsForAdminStartDate, 7));
      onSetStatsForAdminEndDate(subDays(statsForAdminEndDate, 7));
    }
  };

  const handleCurrentWeek = () => {
    onSetStatsForAdminStartDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
    onSetStatsForAdminEndDate(endOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const data = statsForAdmin[0]?.days;

  const handleButtonClick = (day: any, employeeId: number) => {
    const { date, uiKey, id, dayBegin, dayEnd, holidayType } = day;

    const key = selected
      .map(function (e) {
        return e.id;
      })
      .indexOf(uiKey);

    if (key === -1) {
      selected.push({
        id: uiKey,
        ownedBy: employeeId,
        date: date,
        statusCode: "",
        dayBegin: dayBegin,
        dayEnd: dayEnd,
        idPontaj: id,
        holidayType: holidayType,
      });
    } else {
      selected.splice(key, 1);
    }

    setSelected((prevState) => [...prevState]);
  };

  const handleValidation = () => {
    if (comboValue !== undefined) {
      const d = selected.map((obj) => ({ ...obj, statusCode: comboValue }));
      onAdminValidateMany(d);
      setSelected([]);
      setComboValue(undefined);
    }
  };

  const uiSelect = selected.map(function (e) {
    return e.id;
  });

  const holidayOrNot = selected.filter(function (e) {
    return e.holidayType;
  });

  const makeTimes = () => {
    return Array.from(Array(57)).map((_v, i) => {
      const hours = 7 + Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      return {
        id: i + 1,
        time: `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`,
      };
    });
  };
  const dataForCombo = makeTimes();
  useEffect(() => {
    if (selected.length === 1) {
      const { date, dayBegin, dayEnd, idPontaj, ownedBy } = selected[0];

      if (dayBegin !== null && dayEnd !== null) {
        setDayState((prev: any) => ({
          ...prev,
          // ...dayState,
          profileId: ownedBy,
          date: new Date(date).toDateString(),
          dayBegin: dayBegin.slice(0, -3)!,
          dayEnd: dayEnd.slice(0, -3)!,
          id: idPontaj,
          workTypeId: null,
        }));
      } else {
        setDayState((prev: any) => ({
          ...prev,
          // ...dayState,
          profileId: ownedBy,
          date: new Date(date).toDateString(),
          dayBegin: undefined,
          dayEnd: undefined,
          id: "",
          workTypeId: null,
        }));
      }
    } else {
      setDayState((prev: any) => ({
        ...prev,
        // ...dayState,
        profileId: 0,
        date: "",
        dayBegin: undefined,
        dayEnd: undefined,
        id: "",
        workTypeId: null,
      }));
      increment();
    }
  }, [selected]);

  const handleSubmit = () => {
    const dayForEdit = {
      id: dayState.id,
      date: dayState.date,
      dayBegin: dayState.dayBegin,
      dayEnd: dayState.dayEnd,
      workTypeId: null,
    };
    const dayForAdd = {
      id: null,
      date: dayState.date,
      dayBegin: dayState.dayBegin,
      dayEnd: dayState.dayEnd,
      workTypeId: null,
    };
    if (dayState.id !== "") {
      onUpdateTimesheetAdmin(dayState.profileId, dayForEdit);
      setGenerateMessageForUpdateTimsheet(true);
    } else {
      onUpdateTimesheetAdmin(dayState.profileId, dayForAdd);
      setGenerateMessageForUpdateTimsheet(true);
    }
    setSelected([]);
    setDayState(() => ({
      ...dayState,
      profileId: 0,
      date: "",
      dayBegin: undefined,
      dayEnd: undefined,
      id: "",
      workTypeId: null,
    }));
  };

  return (
    <div style={{ width: "100%", height: "490px" }}>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="tooltip-target">
          <TagIcon
            style={{ cursor: "pointer" }}
            accent="viridiangreen"
            className={styles.navIcon}
            icon="Home"
            onClick={() => handleCurrentWeek()}
          />
        </div>

        <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>

        <div
          style={{
            display: "flex",
            width: "90px",
            justifyContent: "space-between",
          }}
        >
          <div className="tooltip-target2">
            <TagIcon
              style={{ cursor: "pointer" }}
              icon="CircleTick"
              accent="viridiangreen"
              className={styles.navIcon}
              onClick={() => setModalApprove(true)}
            />
          </div>
          <div className="tooltip-target3">
            <TagIcon
              style={{ cursor: "pointer" }}
              icon="CircleCross"
              accent="access"
              className={styles.navIcon}
              onClick={() => setModalReject(true)}
            />
          </div>

          <TagModal
            width="small"
            heading="Are you sure you want to Approve All Week?"
            visible={modalApprove}
            onClosed={() => setModalApprove(false)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginLeft: "10px",
                }}
                text="Save"
                icon="CircleTick"
                iconAccent="white"
                accent="viridian-green"
                onButtonClick={() => {
                  onAdminValidateAll(
                    statsForAdminStartDate,
                    statsForAdminEndDate,
                    1
                  );
                  setModalApprove(false);
                }}
              />

              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginRight: "10px",
                }}
                text="Exit"
                accent="title"
                icon="Exit"
                iconAccent="porcelain"
                onButtonClick={() => {
                  setModalApprove(false);
                }}
              ></TagButton>
            </div>
          </TagModal>
          <TagModal
            width="small"
            heading="Are you sure you want to Reject All Week?"
            visible={modalReject}
            onClosed={() => setModalReject(false)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginLeft: "10px",
                }}
                text="Save"
                icon="CircleCross"
                iconAccent="white"
                accent="access"
                onButtonClick={() => {
                  onAdminValidateAll(
                    statsForAdminStartDate,
                    statsForAdminEndDate,
                    2
                  );
                  setModalReject(false);
                }}
              />
              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginRight: "10px",
                }}
                text="Exit"
                accent="title"
                icon="Exit"
                iconAccent="porcelain"
                onButtonClick={() => {
                  setModalReject(false);
                }}
              ></TagButton>
            </div>
          </TagModal>
        </div>
        <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TagCombobox
            disabled={selected.length > 0 ? false : true}
            style={{ width: "150px" }}
            inputDisabled
            textField="name"
            valueField="id"
            placeholder="Select validation"
            data={[
              { id: 1, name: "Approve" },
              { id: 2, name: "Reject" },
            ]}
            value={comboValue}
            onValueChange={(e: CustomEvent<IComboChangeArgs>) =>
              setComboValue(e.detail.value)
            }
          />

          <TagButton
            style={{ paddingRight: "15px", paddingBottom: "2px" }}
            accent={
              comboValue === undefined || selected.length === 0
                ? "grey-1"
                : "viridiangreen"
            }
            disabled={
              comboValue === undefined || selected.length === 0 ? true : false
            }
            text="Save"
            onButtonClick={() => handleValidation()}
          />

          <div className="tooltip-target4">
            <TagIcon
              style={{ cursor: "pointer" }}
              icon="Undo"
              accent={selected.length > 0 ? "viridiangreen" : "grey-3"}
              className={styles.navIcon}
              onClick={() => {
                setSelected([]);

                increment();
              }}
            />
          </div>

          <div
            style={{
              position: "relative",
              height: "25px",
              width: "25px",
              backgroundColor: "white",
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-22px",
                right: "6px",
              }}
            >
              <TagBadge
                style={{ backgroundColor: "white" }}
                value={selected.length.toString()}
                size="small"
                accent={selected.length > 0 ? "viridiangreen" : "grey-5"}
              />
            </div>
          </div>
        </div>

        <TagTooltip
          tooltipFor=".tooltip-target"
          content="Current Week"
          width="150px"
          background="light"
          position="top"
        ></TagTooltip>

        <TagTooltip
          tooltipFor=".tooltip-target2"
          content="Approve All Week"
          position="top"
          width="150px"
          background="light"
        ></TagTooltip>
        <TagTooltip
          tooltipFor=".tooltip-target3"
          content="Reject All Week"
          position="top"
          width="150px"
          background="light"
        ></TagTooltip>
        <TagTooltip
          tooltipFor=".tooltip-target4"
          content="Clear Select"
          position="top"
          width="150px"
          background="light"
        ></TagTooltip>
        <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>
        <div style={{ display: "flex" }}>
          <TagCombobox
            key={value}
            inputStyle={{ textAlign: "center" }}
            disabled={
              selected.length === 0 ||
              selected.length > 1 ||
              holidayOrNot.length > 0
                ? true
                : false
            }
            searchable
            clearButton
            style={{ width: "120px" }}
            listWidth={120}
            inputDisabled
            textField="time"
            valueField="id"
            placeholder="Start time"
            value={dayState.dayBegin}
            data={dataForCombo}
            onValueChange={(e: CustomEvent<IComboChangeArgs>) => {
              setDayState((prev: any) => ({
                ...prev,
                dayBegin: e.detail.text,
              }));
            }}
          />

          <TagCombobox
            key={value2}
            inputStyle={{ textAlign: "center" }}
            disabled={
              selected.length === 0 ||
              selected.length > 1 ||
              holidayOrNot.length > 0
                ? true
                : false
            }
            searchable
            clearButton
            style={{ width: "120px" }}
            listWidth={120}
            inputDisabled
            textField="time"
            valueField="time"
            placeholder="End time"
            value={dayState.dayEnd}
            data={dataForCombo}
            onValueChange={(e) => {
              setDayState((prev: any) => ({
                ...prev,
                dayEnd: e.detail.value,
              }));
            }}
          />
        </div>
        <TagButton
          text="Save"
          style={{ paddingBottom: "3px" }}
          accent={
            selected.length === 0 ||
            selected.length > 1 ||
            dayState.dayBegin === undefined ||
            dayState.dayEnd === undefined ||
            holidayOrNot.length > 0
              ? "grey-1"
              : "viridiangreen"
          }
          disabled={
            selected.length === 0 ||
            selected.length > 1 ||
            dayState.dayBegin === undefined ||
            dayState.dayEnd === undefined ||
            holidayOrNot.length > 0
              ? true
              : false
          }
          onButtonClick={() => handleSubmit()}
        />
        <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>
        <div className="tooltip-target5">
          <TagIcon
            style={{ cursor: "pointer" }}
            icon="Fields"
            accent="viridiangreen"
            className={styles.navIcon}
            onClick={() => setModalForFillWeek(true)}
          />
          <TagTooltip
            tooltipFor=".tooltip-target5"
            content="Fill Week All"
            position="top"
            width="150px"
            background="light"
          ></TagTooltip>
          <TagModal
            width="small"
            heading="Are you sure you want to Fill Week for All?"
            visible={modalForFillWeek}
            onClosed={() => setModalForFillWeek(false)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginLeft: "10px",
                }}
                text="Save"
                icon="CircleTick"
                iconAccent="white"
                accent="viridian-green"
                onButtonClick={() => {
                  onAdminFillWeekAll(
                    statsForAdminStartDate,
                    statsForAdminEndDate
                  );
                  setModalForFillWeek(false);
                }}
              />
              <TagButton
                buttonStyle={{
                  marginTop: "15px",
                  marginBottom: "-6px",
                  marginRight: "10px",
                }}
                text="Exit"
                accent="title"
                icon="Exit"
                iconAccent="porcelain"
                onButtonClick={() => {
                  setModalForFillWeek(false);
                }}
              ></TagButton>
            </div>
          </TagModal>
        </div>

        <div style={{ color: "gray", opacity: 0.3, lineHeight: 2.2 }}>|</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TagIcon
            icon="ChevronLeft"
            accent="title"
            size="default"
            className={styles.navIcon}
            iconStyle={{
              cursor: "pointer",
              height: "20px",
              width: "20px",
            }}
            onClick={() => (loading ? undefined : handlePrevOrNextDate(false))}
          />

          <TagText
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            text={
              statsForAdminStartDate.getDate() +
              " " +
              monthNames[statsForAdminStartDate.getMonth()] +
              " - " +
              statsForAdminEndDate.getDate() +
              " " +
              monthNames[statsForAdminEndDate.getMonth()] +
              " " +
              statsForAdminEndDate.getFullYear()
            }
          ></TagText>
          <TagIcon
            icon="ChevronRight"
            accent="title"
            size="default"
            className={styles.navIcon}
            iconStyle={{
              cursor: "pointer",
              height: "20px",
              width: "20px",
            }}
            onClick={() => (loading ? undefined : handlePrevOrNextDate(true))}
          />
        </div>
      </div>
      <TagSearchbox
        style={{ flexGrow: 1, paddingRight: 10, width: "100%" }}
        placeholder="Search by Name"
        value={searchString}
        clearButton={true}
        onSearch={(e) => handleSearch(e)}
      />
      <div>
        <Row>
          <Row
            style={{
              minWidth: "1050px",
              marginLeft: "15px"
            }}
          >
            <Col style={{ minWidth: "300px", color: "#4b4e52" }}>Access Romania</Col>
              {data?.map((d, index) => {
                return (
                  <Col
                    key={index}
                    style={{ color: "#4b4e52", fontSize: "15px" }}
                  >
                    {daysOfWeek[index] +
                      " " +
                      new Date(d.date).getDate() +
                      "th"}
                  </Col>
                );
              })}
          </Row>
        </Row>
        <div style={{ height: "240px" }}>
          <Row>
            {loading ? (
              <Loading />
            ) : (
              pageOfData.map((s, index) => {
                return (
                  <Row
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      minWidth: "1050px",
                      marginLeft: "15px",
                    }}
                  >
                    <Col
                      style={{
                        minWidth: "300px",
                        border: "1px solid #4b4e52",
                        color: "#4b4e52",
                        fontSize: "0.9rem",
                      }}
                    >
                      {s.employee.fullName}
                    </Col>
                    {s.days.map((d: any, index) => {
                      return (
                        <Col
                          className={styles.tooltip}
                          onClick={() => {
                            handleButtonClick(d, s.employee.id);
                          }}
                          style={
                            uiSelect.includes(d.uiKey)
                              ? {
                                  border: "1px solid #4b4e52",
                                  color: "#4b4e52",
                                  backgroundColor: "#8ec5fc",
                                  fontSize: "0.9rem",
                                }
                              : d.timesheetValidation?.statusLiteral ===
                                "Rejected"
                              ? {
                                  border: "1px solid #4b4e52",
                                  color: "white",
                                  backgroundColor: "#e5173f",
                                  fontSize: "0.9rem",
                                }
                              : d.timesheetValidation?.statusLiteral ===
                                "Approved"
                              ? {
                                  border: "1px solid #4b4e52",
                                  color: "white",
                                  backgroundColor: "#099",
                                  fontSize: "0.9rem",
                                }
                                : d.holidayType?.name ===
                              "Sarbatoare nationala"
                            ? {
                                border: "1px solid #4b4e52",
                                color: "white",
                                backgroundColor: "#099",
                                fontSize: "0.9rem",
                              }           
                              : {
                                  border: "1px solid #4b4e52",
                                  color: "#4b4e52",
                                  fontSize: "0.9rem",
                                }
                          }
                          key={index}
                        >
                          {d.id === null && d.holidayType === null
                            ? "-"
                            : d.holidayType?.shortName ||
                              d.dayBegin.slice(0, -3) +
                                " - " +
                                d.dayEnd.slice(0, -3)}
                          {d.timesheetValidation !== null &&
                          d.timesheetValidation?.validatedBy !== "unknown" ? (
                            <div className={styles.tooltiptext}>
                              <p>{d.timesheetValidation?.validatedBy}</p>
                              <p>{`${d.timesheetValidation?.withCredentials} - ${d.timesheetValidation?.statusLiteral}`}</p>
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
                  </Row>
                );
              })
            )}
          </Row>
        </div>
        <TagPager
          page={page}
          pageCount={pageCountSearch}
          accent="viridiangreen"
          showFirstLast
          onPageRequest={(e) => setPage(e.detail.page)}
        />
      </div>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageForAdminUpdateTimesheet &&
          generateMessageForUpdateTimesheet ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageForAdminUpdateTimesheet.status === 200
                    ? "success"
                    : "warning"
                }
                text={
                  messageForAdminUpdateTimesheet.status === 200
                    ? "Day Updated!"
                    : "Something went wrong!"
                }
                showDuration={3000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessageForUpdateTimsheet(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
