import { useState } from "react";
import styles from "./SettingsPage.module.scss";
import { Divider } from "antd";
import {
  IChangeArgs,
  TagAlert,
  TagButton,
  TagEditField,
  TagFormGroup,
  TagToast,
} from "@tag/tag-components-react-v2";
import { Col } from "react-bootstrap";

export const SettingsPage = () => {
  const key1 = "begin_day_notif_title";
  const key2 = "end_day_notif_title";
  const key3 = "end_week_notif_title";

  const [value1, setValue1] = useState<string>(
    localStorage.getItem(key1) || "Start your shift!"
  );
  const [value2, setValue2] = useState<string>(
    localStorage.getItem(key2) || "Your shift ended!"
  );
  const [value3, setValue3] = useState<string>(
    localStorage.getItem(key3) || "Don't forget to fill this week's timesheet!"
  );
  const [saveMessage, setSaveMessage] = useState<boolean>(false);

  return (
    <div className={styles.SettingsPage}>
      <Divider orientation="left">Notification local settings</Divider>
      <Col>
        <TagFormGroup>
          <TagEditField
            label="Begin day notification title"
            value={value1}
            onValueChange={(a: CustomEvent<IChangeArgs>) =>
              setValue1(a.detail.value)
            }
          />
        </TagFormGroup>
        <TagFormGroup>
          <TagEditField
            label="End day notification title"
            value={value2}
            onValueChange={(a: CustomEvent<IChangeArgs>) =>
              setValue2(a.detail.value)
            }
          />
        </TagFormGroup>
        <TagFormGroup>
          <TagEditField
            label="End week notification title"
            value={value3}
            onValueChange={(a: CustomEvent<IChangeArgs>) =>
              setValue3(a.detail.value)
            }
          />
        </TagFormGroup>

        <TagButton
          size="large"
          icon="Save"
          text="Save"
          accent={"viridiangreen"}
          color={"white"}
          iconAccent={"porcelain"}
          onButtonClick={() => {
            setSaveMessage(true);
            localStorage.setItem(key1, value1);
            localStorage.setItem(key2, value2);
            localStorage.setItem(key3, value3);

          }}
        />

        {saveMessage ? (
          <TagToast position='bottom-left'>
            <TagAlert
              type='success'
              text='Notifications saved successfully!'
              alertType='toast'
              showDuration={4000}
              onClosed={() => {
                setSaveMessage(false);
              }
              }
            >
            </TagAlert>
          </TagToast>
        ) : (
          <></>
        )}
      </Col>
    </div>
  );
};
