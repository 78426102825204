import {
  TagButton,
  TagForm,
  TagList,
  TagText,
  TagToggle,
} from "@tag/tag-components-react-v2";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ProfileModel } from "../../common/Models/ProfileModel";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";

type WorkLocationExtensionProps = {
  closeModal: () => void;
  user: ProfileModel | null;
  workTypes: WorkTypesModel[];
  onUpdateWorkLocation: (id: string, workTypeId: number) => Promise<void>;
  messageUpdateWorkLocation: () => void;
};

export const WorkLocationExtension = ({
  closeModal,
  onUpdateWorkLocation,
  messageUpdateWorkLocation,
  user,
  workTypes,
}: WorkLocationExtensionProps) => {
  const formStyle: React.CSSProperties = { border: "0px" };

  const dataWork = workTypes.map((w) => {
    return { ...w, label: w.name, value: w.id };
  });

  const [workTypeTemp, setWorkTypeTemp] = useState<number>();

  return (
    <div>
      <TagForm
        submitButtonHidden
        submitButtonText="Confirm"
        submitButtonAccent="viridiangreen"
        submitButtonAlign="right"
        formStyle={formStyle}
        inline
      >
        <div style={{ paddingTop: "20px" }}></div>
        <TagList
          containerStyle={{ padding: 0 }}
          renderItem={({ id, dayOfWeek, workType }) => (
            <Row style={{ width: "100%", padding: "5px 0" }}>
              <Col>
                <TagText
                  type="feature"
                  accent="midnightblue"
                  text={dayOfWeek}
                />
              </Col>
              <Col>
                <TagToggle
                  accent="viridiangreen"
                  name="Work Location"
                  value={workType.id.toLocaleString()}
                  data={dataWork}
                  onValueChange={(e) => {
                    setWorkTypeTemp(e.detail.value);
                    const workTypeId = e.detail.value;
                    onUpdateWorkLocation(id, workTypeId).then(
                      () => (workType.id = workTypeId)
                    );
                    messageUpdateWorkLocation();
                  }}
                />
              </Col>
            </Row>
          )}
          data={user?.workLocationSchedule}
        />

        <div
          style={{
            paddingTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TagButton
            buttonStyle={{
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              closeModal();
            }}
          ></TagButton>
        </div>
      </TagForm>
    </div>
  );
};
