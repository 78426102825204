import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { baseUri } from "../msalAuthConfig";


const aquireToken = async (
    instance: IPublicClientApplication,
    request: SilentRequest
): Promise<string> => {
    let token: string = "";
    await instance
        .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
        .then(
            (res) => {
                token = res.accessToken;
            },
            (_) => {
                setTimeout(() => instance.loginRedirect(request), 500);
            }
        );
    return token;
};

const getLogs = async (token: string) => {
    const url = baseUri + `/api/Dev/GetLogs`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + token,
        }
    })

    return response.json();
}

export const dev_service = {
    aquireToken: aquireToken,
    getLogs: getLogs,
  };



