
import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { ProfileModel } from "../common/Models/ProfileModel";
import { baseUri } from "../msalAuthConfig";


const aquireToken = async (
  instance: IPublicClientApplication,
  request: SilentRequest
): Promise<string> => {
  let token: string = "";
  await instance
    .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
    .then(
      (res) => {
        token = res.accessToken;
      },
      (_) => {
        setTimeout(() => instance.loginRedirect(request), 500);
      }
    );
  return token;
};

const getProfile = async (token: string): Promise<ProfileModel> => {
  const response = await fetch(baseUri + "/api/Profile/Get", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const updateSchedule = async (
  begin: string,
  end: string,
  token: string
): Promise<void> => {
  const schedule = {
    scheduleBegin: begin,
    scheduleEnd: end,
  };

  const response = await fetch(baseUri + "/api/Profile/UpdateSchedule", {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "text/json",
      Accept: "text/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(schedule), // body data type must match "Content-Type" header
  });
  return Promise.resolve();
};

export const profile_service = {
  aquireToken: aquireToken,
  getProfile: getProfile,
  updateSchedule: updateSchedule
};