import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { HolidayModel } from "../common/Models/HolidayModel";
import { EditHolidayModel } from "../common/Models/EditHolidayModel";
import { baseUri } from "../msalAuthConfig";


const aquireToken = async (
  instance: IPublicClientApplication,
  request: SilentRequest
): Promise<string> => {
  let token: string = "";
  await instance
    .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
    .then(
      (res) => {
        token = res.accessToken;
      },
      (_) => {
        setTimeout(() => instance.loginRedirect(request), 500);
      }
    );
  return token;
};

const getHolidays = async (token: string): Promise<HolidayModel[]> => {
  const response = await fetch(baseUri + "/api/Holiday/GetAll", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const addHoliday = async (
  holiday: HolidayModel,
  token: string
): Promise<any> => {
  const response = await fetch(baseUri + "/api/Holiday/AddOrUpdate", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "text/json",
      Accept: "text/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(holiday), // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return { data: data, status: response.status };
};

const editHoliday = async (
  holiday: EditHolidayModel,
  token: string
): Promise<any> => {
  const response = await fetch(baseUri + "/api/Holiday/AddOrUpdate", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "text/json",
      Accept: "text/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(holiday), // body data type must match "Content-Type" header
  });
  const data = await response.json();
  return { data: data, status: response.status };
};

const deleteHoliday = async (id: string, token: string): Promise<void> => {
  const response = await fetch(baseUri + "/api/Holiday/Delete", {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "text/json",
      Accept: "text/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(id), // body data type must match "Content-Type" header
  });
  return Promise.resolve();
};

export const holiday_service = {
  aquireToken: aquireToken,
  getHolidays: getHolidays,
  addHoliday: addHoliday,
  editHoliday: editHoliday,
  deleteHoliday: deleteHoliday
};