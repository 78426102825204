import {
    TagButton,
    TagForm,
    TagText,
} from "@tag/tag-components-react-v2";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";


type ToggleChangeProps = {
    toggleOption: WorkTypesModel | null;
    currDate: string;
    onSubmitChange: (date: string, workTypeId: WorkTypesModel) => void;
    closeModal: () => void;
    messageOnSubmit: () => void;
};
export const ToggleChangeForm = ({
    toggleOption,
    currDate,
    onSubmitChange,
    closeModal,
    messageOnSubmit,
}: ToggleChangeProps) => {

    function submitData() {
        if (toggleOption) {
            onSubmitChange(currDate, toggleOption);
            messageOnSubmit();
            closeModal();
        }
    }

    const formStyle: React.CSSProperties = { border: "0px" };

    return (
        <div>
            <TagForm
                submitButtonHidden
                submitButtonText="Delete"
                submitButtonAccent="access"
                submitButtonAlign="right"
                formStyle={formStyle}
                inline
            >

                <TagText
                    style={{
                        marginTop: "-10px",
                        marginBottom: "10px"
                    }}
                    accent="mojo"

                    type="h5" text="Your manager will be notified about this change!"
                />

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <TagButton
                        buttonStyle={{
                            marginTop: "15px",
                            marginBottom: "-6px",
                            marginLeft: "10px",
                        }}
                        text="Submit"
                        icon="Save"
                        iconAccent="white"
                        accent="viridiangreen"
                        onButtonClick={() => submitData()}
                    />
                    <TagButton
                        buttonStyle={{
                            marginTop: "15px",
                            marginBottom: "-6px",
                            marginRight: "10px",
                        }}
                        text="Exit"
                        accent="title"
                        icon="Exit"
                        iconAccent="porcelain"
                        onClick={() => {
                            closeModal();
                        }}
                    ></TagButton>
                </div>
            </TagForm>
        </div>
    );
};
