import {
  TagEditField,
  TagList,
  TagSearchbox,
  TagPager,
} from "@tag/tag-components-react-v2";

import { useState } from "react";
import { Divider } from "antd";

import { DevLogsModel } from "../../common/Models/DevLogsModel";

export interface DevelopersPageProps {
  logs: DevLogsModel[];
}

export const DevelopersPage = ({ logs }: DevelopersPageProps) => {
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(1);

  const pageSize = 10;

  const pageSearch = logs.filter(
    (a) =>
      a.doneAt.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.action.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.level.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.details.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.doneBy.toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );

  const pageOfData = pageSearch
    .slice((page - 1) * pageSize, page * pageSize)
    .map((obj) => ({
      ...obj,
      doneAt: obj.doneAt,
      action: obj.action,
      level: obj.level,
      details: obj.details,
      doneBy: obj.doneBy,
    }));

  const countAfterSearch = pageSearch.length;
  const pageCountSearch = Math.ceil(countAfterSearch / pageSize);

  const handleSearch = (e: any) => {
    setSearchString(e.detail.criteria);
    setPage(1);
  };

  return (
    <div
      style={{
        padding: "10px 15px 0px 15px",
      }}
    >
      <Divider orientation="left">Logs</Divider>

      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "3px",
          }}
        >
          <TagSearchbox
            style={{ flexGrow: 1, paddingRight: 10 }}
            placeholder="Search by Person, Date, Action, Level or Details..."
            value={searchString}
            clearButton={true}
            onSearch={(e) => handleSearch(e)}
          />
        </div>
        <TagEditField
          value={"Records: " + countAfterSearch.toString()}
          name="Total"
          editor="textbox"
          disabled={true}
          inline={true}
        />
        <TagList
          containerStyle={{ minHeight: "300px" }}
          leftIcon="ReportTable"
          leftIconAccent="atoll"
          leftIconRoundedBackground
          primaryField="doneBy"
          leftField1="level"
          leftField2="action"
          leftField1Inline
          leftField2Inline
          leftField3="details"
          leftField3Type="string"
          leftField3Inline
          pill1Field="doneAt"
          pill1FieldType="string"
          pill1Style={{ minWidth: "150px", justifyContent: "center" }}
          pill1TextStyle={{ textAlign: "center" }}
          pillAlign="left"
          data={pageOfData}
          pageSize={pageSize}
        />
        <TagPager
          page={page}
          pageCount={pageCountSearch}
          accent="viridiangreen"
          showFirstLast
          onPageRequest={(e) => setPage(e.detail.page)}
        />
      </div>
    </div>
  );
};
