import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { TimesheetModel } from "../common/Models/TimesheetModel";
import { IntervalModel } from "../common/IntervalModel";
import { CreateTimesheetModel } from "../common/Models/CreateTimesheetModel";
import { AddTimesheetModel } from "../common/Models/AddTimesheetModel";
import { FillWeekTimesheet } from "../common/Models/FillWeekTimesheet";
import { WorkTypesModel } from "../common/Models/WorkTypesModel";
import { baseUri } from "../msalAuthConfig";


const aquireToken = async (
  instance: IPublicClientApplication,
  request: SilentRequest
): Promise<string> => {
  let token: string = "";
  await instance
    .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
    .then(
      (res) => {
        token = res.accessToken;
      },
      (_) => {
        setTimeout(() => instance.loginRedirect(request), 500);
      }
    );
  return token;
};

export const ParseDate = (date: Date): string => {
  const Add0IfMissing = (d: number) => {
    if (d < 10) return "0" + d;
    return d;
  };

  return (
    date.getFullYear() +
    "-" +
    Add0IfMissing(date.getMonth() + 1) +
    "-" +
    Add0IfMissing(date.getDate())
  );
};

const getWeek = async (
  token: string,
  begin: Date,
  end: Date
): Promise<TimesheetModel[]> => {
  const monday = new Date(begin);
  end = new Date(begin);
  end.setDate(begin.getDate() + 4);
  const url =
    baseUri + `/api/Timesheet/GetRange/${ParseDate(monday)}/${ParseDate(end)}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const getCurrentDay = async (
  token: string,
  begin: Date
): Promise<TimesheetModel> => {
  const url =
    baseUri + `/api/Timesheet/GetRange/${ParseDate(begin)}/${ParseDate(begin)}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  const data = await response.json();
  return data[0];
};

const addTimesheet = async (data: AddTimesheetModel, token: string) => {
  const url = baseUri + `/api/Timesheet/AddOrUpdate`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const updateTimesheet = async (data: CreateTimesheetModel, token: string) => {
  const url = baseUri + `/api/Timesheet/AddOrUpdate`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const validateRange = async (data: IntervalModel, token: string) => {
  const url = baseUri + `/api/Timesheet/ValidateRange`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const fiilWeekTimesheet = async (data: FillWeekTimesheet, token: string) => {
  const url = baseUri + `/api/Timesheet/FillMe`;
  const request = {
    begin: ParseDate(data.begin),
    end: ParseDate(data.end),
  };
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const getWorkTypes = async (token: string) => {
  const url = baseUri + `/api/Timesheet/GetWorkTypes`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    }
  })

  return response.json()
}

const updateWorkType = async (
  date_set: string,
  id: WorkTypesModel,
  token: string
): Promise<any> => {
  const request = {
    date: date_set,
    workTypeId: id,
  };
  const response = await fetch(
    baseUri + `/api/Timesheet/AddOrUpdateWorkLocation`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(request), // body data type must match "Content-Type" header
    }
  );
  
  return response;
};


export const timesheet_service = {
  aquireToken: aquireToken,
  getWeek: getWeek,
  getCurrentDay: getCurrentDay,
  addTimesheet: addTimesheet,
  updateTimesheet: updateTimesheet,
  validateRange: validateRange,
  fiilWeekTimesheet: fiilWeekTimesheet,
  getWorkTypes: getWorkTypes,
  updateWorkType: updateWorkType
};
