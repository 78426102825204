import React from "react";
import ReactECharts from "echarts-for-react";
import { StatsModel } from "../../common/Models/StatsModel";

export interface HolidaysChartProps {
  statistics: StatsModel | undefined;
}

export const HolidaysChart = ({ statistics }: HolidaysChartProps) => {
  const holidays = statistics?.holidayStats!;
  const employeesOnHolidays = holidays?.map((obj) => ({
    value: obj.count,
    name: obj.type.shortName,
  }));

  const option = {
    title: {
      text: "Holidays",
      subtext: "Today",
      left: "center",
      top: "9%",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "25%",
      left: "10%",
      orient: "vertical",
    },
    series: [
      {
        name: "Employees",
        type: "pie",
        radius: ["39%", "50%"],
        avoidLabelOverlap: true,
        data: employeesOnHolidays,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <ReactECharts
      option={option}
      style={{
        height: 400,
        width: "60%",
        paddingTop: "15px",
        paddingLeft: "20px",
      }}
      opts={{ renderer: "svg" }}
    />
  );
};
