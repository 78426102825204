import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { HolidaysAdminModel } from "../common/Models/HolidaysAdminModel";
import { HolidayModel } from "../common/Models/HolidayModel";
import { EmployeeModel } from "../common/Models/EmployeeModel";
import { baseUri } from "../msalAuthConfig";
import { UpdateWorkHours } from "../common/Models/UpdateWorkHours";
import { previousMonday, previousSunday, startOfWeek } from 'date-fns'
import { ManagerStatsModel } from "../common/Models/ManagerStatsModel";
import { ValidationManyModel } from "../common/Models/ValidationManyModel";
import { UpdateTimesheetAdminModel } from "../common/Models/UpdateTimesheetAdminModel";




const aquireToken = async (
  instance: IPublicClientApplication,
  request: SilentRequest
): Promise<string> => {
  let token: string = "";
  await instance
    .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
    .then(
      (res) => {
        token = res.accessToken;
      },
      (_) => {
        setTimeout(() => instance.loginRedirect(request), 500);
      }
    );
  return token;
};

export const ParseDate = (date: Date): string => {
  const Add0IfMissing = (d: number) => {
    if (d < 10) return "0" + d;
    return d;
  };

  return (
    date.getFullYear() +
    "-" +
    Add0IfMissing(date.getMonth() + 1) +
    "-" +
    Add0IfMissing(date.getDate())
  );
};

const addHolidayAdmin = async (
  holiday: HolidayModel,
  token: string,
  email: string
): Promise<any> => {
  const response = await fetch(
    baseUri + `/api/Admin/AddOrUpdateHoliday/${email}`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(holiday), // body data type must match "Content-Type" header
    }
  );
  const data = await response.json();
  return { data: data, status: response.status };
};

const getHolidaysAdmin = async (
  token: string
): Promise<HolidaysAdminModel[]> => {
  const response = await fetch(baseUri + "/api/Admin/GetHolidays", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const deleteHolidayAdmin = async (id: string, token: string) => {
  const response = await fetch(baseUri + `/api/Admin/DeleteHoliday/${id}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });
  let res;
  if (response.status !== 200) {
    res = { statusCode: response.status };
  } else res = { statusCode: response.status };
  return res;
};

const exportReport = async (begin: Date, end: Date, token: string) => {
  const url =
    baseUri + `/api/Timesheet/Export/${ParseDate(begin)}/${ParseDate(end)}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment; filename=raport.xlsx",
      Authorization: "Bearer " + token,
    },
  });
  return response.blob().then((myBlob) => {
    var objectURL = URL.createObjectURL(myBlob);
    var tempLink = document.createElement("a");
    tempLink.href = objectURL;
    tempLink.setAttribute(
      "download",
      `report/from/${ParseDate(begin)}/to/${ParseDate(end)}.xlsx`
    );
    tempLink.click();
  });
};

const importProfile = async (file: any, token: string): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(baseUri + `/api/Profile/Import`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  });
  if (response.status === 200 && response.headers.get("Content-Type") == "application/octet-stream") {
    return {
      data: response.blob().then((myBlob) => {
        var objectURL = URL.createObjectURL(myBlob);
        var tempLink = document.createElement("a");
        tempLink.href = objectURL;
        tempLink.setAttribute(
          "download",
          `import/errors.txt`
        );
        tempLink.click();
      }),
      status: response.status,
      downloadable: true
    }
  }
  else {
    const data = await response.text();
    return { status: response.status, data: data, downloadable: false };
  }
};

const importXD = async(file: any, token: string): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  
  const response = await fetch(baseUri + `/api/Admin/CheckXDHolidays`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  });
  if (response.status === 200 && response.headers.get("Content-Type") == "application/octet-stream") {
    return {
      data: response.blob().then((myBlob) => {
        var objectURL = URL.createObjectURL(myBlob);
        var tempLink = document.createElement("a");
        tempLink.href = objectURL;
        tempLink.setAttribute(
          "download",
          `XD_differences.xlsx`
        );
        tempLink.click();
      }),
      status: response.status,
      downloadable: true
    }
  }
  else {
    const data = await response.text();
    return { status: response.status, data: data, downloadable: false };
  }
};

const getEmployees = async (token: string) => {
  const url = baseUri + `/api/Admin/GetEmployees`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    }
  })

  return response.json();
}

const getStats = async (token: string) => {
  const url = baseUri + `/api/Admin/Stats`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    }
  })

  return response.json();
}

const addOrEditEmployeeAdmin = async (
  employee: EmployeeModel,
  token: string,
  id: number
): Promise<any> => {
  const response = await fetch(
    baseUri + `/api/Admin/AddOrUpdateEmployee/${id}`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(employee), // body data type must match "Content-Type" header
    }
  );
  const data = await response.json();
  return { data: data, status: response.status };
};

const deleteEmployeeAdmin = async (id: number, token: string, leaveDate: Date) => {
  const response = await fetch(baseUri + `/api/Admin/Fire/${id}/${ParseDate(leaveDate)}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });
  let res;
  if (response.status !== 200) {
    res = { statusCode: response.status };
  } else res = { statusCode: response.status };
  return res;
};

const getDepartments = async (token: string) => {
  const url = baseUri + `/api/Department/GetAll`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    }
  })

  return response.json();
}

const updateWorkingHours = async (
  id: number,
  workingHours: UpdateWorkHours,
  token: string
): Promise<any> => {
  const response = await fetch(
    baseUri + `/api/Admin/UpdateWorkingHours/${id}`,
    {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(workingHours), // body data type must match "Content-Type" header
    }
  );
  const data = await response.text();
  return { data: data, status: response.status };
};

const updateWorkLocationSchedule = async (
  id: string,
  typeId: number,
  token: string
): Promise<any> => {
  const body = { workTypeId: typeId };
  const response = await fetch(
    baseUri + `/api/Admin/WorkLocationSchedule/Update/${id}`,
    {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body), // body data type must match "Content-Type" header
    }
  );
  const data = await response.text();
  return { data: data, status: response.status };
};

const exportIncompleteTimesheet = async (token: string) => {
  const url =
    baseUri + `/api/Admin/ExportBadApples`;
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const lastMonday = previousMonday(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const lastSunday = previousSunday(startOfWeek(new Date(), { weekStartsOn: 1 }));
  
  const d = new Date();
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment; filename=raport.xlsx",
      Authorization: "Bearer " + token,
    },
  });
  return response.blob().then((myBlob) => {
    var objectURL = URL.createObjectURL(myBlob);
    var tempLink = document.createElement("a");
    tempLink.href = objectURL;
    tempLink.setAttribute(
      "download",
      `report/for/incomplete-timesheet/${lastMonday.getDate()}-${monthNames[lastMonday.getMonth()]}/${lastSunday.getDate()}-${monthNames[lastSunday.getMonth()]}/and ${monthNames[d.getMonth()]}/.xlsx`
    );
    tempLink.click();
  });
};

const getValidationStatsForAdmin = async (
  token: string,
  begin: Date,
  end: Date
): Promise<ManagerStatsModel[]> => {
  const monday = new Date(begin);
 end = new Date(begin);
 end.setDate(begin.getDate() + 4);
 
  const url =
    baseUri + `/api/Admin/GetValidationStats/${ParseDate(monday)}/${ParseDate(end)}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + token,
    },
  });
  return response.json();
};

const adminValidateMany = async (
  days: ValidationManyModel[],
  token: string
): Promise<any> => {
  const body = [...days]
  const response = await fetch(
    baseUri + `/api/Admin/ValidateMany`,
    {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(body)
    }
  )

 const data = await response.text();
 return { data: data, status: response.status };
}

const adminValidateAll = async (
  begin: Date,
  end: Date,
  statusCode: number,
  token: string,
): Promise<any> => {
  const monday = new Date(begin);
  end = new Date(begin);
  end.setDate(begin.getDate() + 4);
  const response = await fetch (
    baseUri + `/api/Admin/ValidateAll/${ParseDate(monday)}/${ParseDate(end)}?statusCode=${statusCode}`, {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token
      },
    
    }
  )

  const data = await response.text();
  return { data: data, status: response.status };
}

const updateTimesheetAdmin = async (
  id: number,
  day: UpdateTimesheetAdminModel,
  token: string
): Promise<any> => {
  const response = await fetch(
    baseUri + `/api/Admin/AddOrUpdateTimesheet/${id}`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(day), // body data type must match "Content-Type" header
    }
  );
  const data = await response.text();
  return { data: data, status: response.status };
};

const adminFillWeekAll = async (
  begin: Date,
  end: Date,
  token: string,
): Promise<any> => {
  const monday = new Date(begin);
  end = new Date(begin);
  end.setDate(begin.getDate() + 4);
  const response = await fetch (
    baseUri + `/api/Admin/FillMePlenty/${ParseDate(monday)}/${ParseDate(end)}`, {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Accept: "text/json",
        Authorization: "Bearer " + token
      },
    
    }
  )

  const data = await response.text();
  return { data: data, status: response.status };
}


export const admin_service = {
  aquireToken: aquireToken,
  importProfile: importProfile,
  importXD: importXD,
  getEmployees: getEmployees,
  exportReport: exportReport,
  addHolidayAdmin: addHolidayAdmin,
  getHolidaysAdmin: getHolidaysAdmin,
  getStats: getStats,
  deleteHolidayAdmin: deleteHolidayAdmin,
  addOrEditEmployeeAdmin: addOrEditEmployeeAdmin,
  deleteEmployeeAdmin: deleteEmployeeAdmin,
  getDepartments: getDepartments,
  updateWorkingHours: updateWorkingHours,
  updateWorkLocationSchedule: updateWorkLocationSchedule,
  exportIncompleteTimesheet: exportIncompleteTimesheet,
  getValidationStatsForAdmin: getValidationStatsForAdmin,
  adminValidateMany: adminValidateMany,
  adminValidateAll: adminValidateAll,
  updateTimesheetAdmin: updateTimesheetAdmin,
  adminFillWeekAll: adminFillWeekAll
};