import { TagList, TagAlert, TagToast } from "@tag/tag-components-react-v2";
import { HolidayModel } from "../../common/Models/HolidayModel";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";

interface ContentListProps {
  holidays: HolidayModel[];
  onEditButton: (holiday: HolidayModel) => void;
  onDeleteButton: (holiday: HolidayModel) => void;
}

export const ContentList = (props: ContentListProps) => {
  const [error, setError] = useState(false);
  const [dateOver, setDateOver] = useState(false);
  const handleButtonClick = (buttonIndex: number, item: HolidayModel) => {
    if (buttonIndex === 2) props.onEditButton(item);
    if (buttonIndex === 3) props.onDeleteButton(item);
  };

  const mockDataMeta = props.holidays.map((obj) => ({
    begin: obj.begin,
    end: obj.end,
    typeName: obj.type ? obj.type.shortName + " - " + obj.type.name : "",
    accent: obj.type ? obj.type.accent : "",
    icon: obj.type ? obj.type.icon : "",
    model: obj,
  }));

  return (
    <>
      <TagList
        leftIconAccentField="accent"
        leftIconTitleField="icon"
        leftIconField="icon"
        data={mockDataMeta}
        button2Icon="Edit"
        button3Icon="Delete"
        button2HostStyle={{ order: 1 }}
        button3HostStyle={{ order: 2 }}
        rightField2Inline
        rightField1Inline
        rightField1="begin"
        rightField1Type="date"
        rightField2Type="date"
        rightField2="end"
        pill1Field="typeName"
        pill1FieldType="string"
        pill1FieldAccentField="accent"
        onButtonClick={(e) => {
          if (
            e.detail.item.model.type?.requiredRole !== "Admin" &&
            moment(e.detail.item.model.begin).isSameOrAfter(new Date(), "month")
          ) {
            setError(false);
            setDateOver(false);
            handleButtonClick(e.detail.buttonIndex, e.detail.item.model);
          } else if (
            moment(e.detail.item.model.begin).isBefore(new Date().setDate(0))
          ) {
            setError(false);
            setDateOver(true);
          } else {
            setDateOver(false);
            setError(true);
          }
        }}
      />
      <Col xs="auto">
        <Row className={"pt-2"}>
          {error ? (
            <TagToast position="bottom-right">
              <TagAlert
                type="warning"
                text="Rights reserved! Contact HR Team."
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setError(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {dateOver ? (
            <TagToast position="bottom-right">
              <TagAlert
                type="warning"
                text="You can't alter leaves from previous months!"
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setDateOver(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </>
  );
};
