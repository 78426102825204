import styles from "./Loading.module.scss";
export const Loading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <div className={styles.loadingSpinner}></div>
        <span className={styles.text}>Loading...</span>
      </div>
    </div>
  );
};
