import {
  IChangeArgs,
  IComboChangeArgs,
  TagButton,
  TagCombobox,
  TagEditField,
  TagForm,
  TagText,
} from "@tag/tag-components-react-v2";
import React, { useState } from "react";
import { HolidayModel } from "../../common/Models/HolidayModel";

type DayOffFormProps = {
  holiday: HolidayModel | null;
  onCancelButtonClick: () => void;
  onDeleteButtonClick: (holiday: HolidayModel) => void;
};

export const DeleteForm = (props: DayOffFormProps) => {
  const [object, setObject] = useState({
    id: null,
    type: null,
    begin: null,
    end: null,
  });

  const formStyle: React.CSSProperties = { border: "0px" };
  return (
    <TagForm inline submitButtonHidden formStyle={formStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TagEditField
          value={object.begin ? object.begin : props.holiday?.begin}
          name="dateIn"
          label="Start"
          editor="datepick"
          disabled={true}
          onValueChange={(e: CustomEvent<IChangeArgs>) =>
            setObject({
              begin: e.detail.value,
              end: object.end,
              type: object.type,
              id: object.type,
            })
          }
        />

        <TagEditField
          value={object.end ? object.end : props.holiday?.end}
          name="dateOut"
          label="End"
          editor="datepick"
          disabled={true}
          onValueChange={(e: CustomEvent<IChangeArgs>) =>
            setObject({
              end: e.detail.value,
              begin: object.begin,
              type: object.type,
              id: object.type,
            })
          }
        />
      </div>
      <div style={{ margin: "10px 0px 10px 0px" }}>
        <TagText
          text="Day Off type"
          textStyle={{ fontWeight: "bold" }}
        ></TagText>

        <TagCombobox
          value={object.type ? object.type : props.holiday?.typeId}
          textField="name"
          valueField="id"
          placeholder="Pick a day off type"
          disabled={true}
          data={[
            { id: 1, name: "CO – Annual Leave" },
            { id: 3, name: "CPL – Special Leave (RO)" },
            { id: 8, name: "D – Delegation" },
            { id: 9, name: "BB – Big Break"}
          ]}
          onValueChange={(e: CustomEvent<IComboChangeArgs>) =>
            setObject({
              begin: object.begin,
              end: object.end,
              type: e.detail.value,
              id: object.type,
            })
          }
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TagButton
          text="Delete"
          accent="access"
          icon="Delete"
          iconAccent="porcelain"
          onClick={() => {
            if (props.holiday?.id) {
              const holiday = {
                id: object.id ? object.id : props.holiday?.id,
                begin: null,
                end: null,
                typeId: null,
                type: null,
              };
              props.onDeleteButtonClick(holiday);
              setObject({ id: null, begin: null, end: null, type: null });
            }
          }}
        ></TagButton>

        <TagButton
          text="Exit"
          accent="title"
          icon="Exit"
          iconAccent="porcelain"
          onClick={() => {
            props.onCancelButtonClick();
            setObject({ id: null, begin: null, end: null, type: null });
          }}
        ></TagButton>
      </div>
    </TagForm>
  );
};
