import { TagButton, TagIcon, TagText } from "@tag/tag-components-react-v2";
import styles from "./TimesheetNavbar.module.scss";

type TimesheetNavbarProps = {
  startDate: Date;
  endDate: Date;
  handlePrevOrNextDate: (e: boolean) => void;
  handleCurrentWeek: () => void;
};
export const TimesheetNavbar = (props: TimesheetNavbarProps) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
 
  return (
    <div className={styles.container}>
      <TagButton
        style={{ marginLeft: "20px", marginRight: "20px" }}
        text="Go to Current Week"
        accent="viridiangreen"
        size="default"
        onClick={() => props.handleCurrentWeek()}
      />
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <TagIcon
          icon="ChevronLeft"
          accent="title"
          size="default"
          iconStyle={{
            cursor: "pointer",
            height: "20px",
            width: "20px",
          }}
          onClick={() => props.handlePrevOrNextDate(false)}
        />
        <TagText
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          text={
            props.startDate.getDate() +
            " " +
            monthNames[props.startDate.getMonth()] +
            " - " +
            props.endDate.getDate() +
            " " +
            monthNames[props.endDate.getMonth()] +
            " " +
            props.endDate.getFullYear()
          }
        ></TagText>
        <TagIcon
          icon="ChevronRight"
          accent="title"
          size="default"
          iconStyle={{
            cursor: "pointer",
            height: "20px",
            width: "20px",
          }}
          onClick={() => props.handlePrevOrNextDate(true)}
        />
      </div>
    </div>
  );
};
