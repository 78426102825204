import {
  IChangeArgs,
  TagButton,
  TagCombobox,
  TagEditField,
  TagForm,
  TagText,
} from "@tag/tag-components-react-v2";
import { useEffect, useState } from "react";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { EmployeeModel } from "../../common/Models/EmployeeModel";
import { ProfileModel } from "../../common/Models/ProfileModel";

type EditUserProps = {
  user: ProfileModel | null;
  onAddOrEditEmployeeAdmin: (employee: EmployeeModel, id: number) => void;
  closeModal: () => void;
  messageAddUser: () => void;
  departments: DepartmentModel[];
};
export const EditUserForm = ({
  user,
  onAddOrEditEmployeeAdmin,
  closeModal,
  messageAddUser,
  departments,
}: EditUserProps) => {
  const offices = [
    {
      name: "Arad",
    },
    {
      name: "Timisoara",
    },
    {
      name: "Remote",
    },
  ];
  const [formData, setFormData] = useState<EmployeeModel>({
    firstName: "",
    lastName: "",
    email: "",
    managerEmail: "",
    workingHours: 0,
    startDate: "",
    scheduleBegin: "",
    scheduleEnd: "",
    departmentId: "",
    marca: "",
    officeLocation: "",
  });

  useEffect(() => {
    setFormData({
      firstName: user?.firstName!,
      lastName: user?.lastName!,
      departmentId: user?.department.id!,
      email: user?.email!,
      managerEmail: user?.managerEmail!,
      workingHours: user?.workingHours!,
      startDate: user?.startDate!,
      scheduleBegin: user?.scheduleBegin.substring(0, 5)!,
      scheduleEnd: user?.scheduleEnd.substring(0, 5)!,
      marca: user?.marca!,
      officeLocation: user?.officeLocation!,
    });
  }, [user]);

  function handleFormData() {
    const editEmployee: EmployeeModel = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      departmentId: formData.departmentId,
      email: formData.email,
      managerEmail: formData.managerEmail,
      workingHours: formData.workingHours,
      startDate: formData.startDate,
      scheduleBegin: formData.scheduleBegin,
      scheduleEnd: formData.scheduleEnd,
      marca: formData.marca,
      officeLocation: formData.officeLocation,
    };

    onAddOrEditEmployeeAdmin(editEmployee, user?.id!);
    closeModal();
    setFormData({
      firstName: "",
      lastName: "",
      departmentId: "",
      email: "",
      managerEmail: "",
      workingHours: 0,
      startDate: "",
      scheduleBegin: "",
      scheduleEnd: "",
      marca: "",
      officeLocation: "",
    });
    messageAddUser();
  }

  const formStyle: React.CSSProperties = { border: "0px" };
  const editFieldStyle: React.CSSProperties = {
    marginTop: "15px",
  };

  return (
    <div>
      <TagForm
        submitButtonHidden
        submitButtonText="Confirm"
        submitButtonAccent="viridiangreen"
        submitButtonAlign="right"
        formStyle={formStyle}
        inline
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="firstName"
            label="First Name"
            editor="textbox"
            value={formData.firstName}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                firstName: e.detail.value,
              }))
            }
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="lastName"
            label="Last Name"
            editor="textbox"
            value={formData.lastName}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                lastName: e.detail.value,
              }))
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="email"
            label="Email"
            editor="email"
            value={formData.email}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                email: e.detail.value,
              }))
            }
            validation={[{ rule: "email" }]}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="managerEmail"
            label="Manager Email"
            editor="email"
            value={formData.managerEmail}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                managerEmail: e.detail.value,
              }))
            }
            validation={[{ rule: "email" }]}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="marca"
            label="Marca"
            editor="textbox"
            value={formData.marca}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                marca: e.detail.value,
              }))
            }
            validation={[{ rule: "matches", args: "^[a-zA-Z0-9]*$" }]}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <TagText
              text="Department"
              textStyle={{ fontWeight: "bold", marginBottom: "3px" }}
            />
            <TagCombobox
              inputStyle={{ width: "220px" }}
              inputDisabled
              textField="name"
              valueField="id"
              searchable
              placeholder="Select a department"
              value={formData.departmentId}
              data={departments}
              onValueChange={(e) => {
                setFormData((formData: EmployeeModel) => ({
                  ...formData,
                  departmentId: e.detail.value,
                }));
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            containerStyle={editFieldStyle}
            name="startDate"
            label="Start Date"
            editor="datepick"
            value={formData.startDate}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                startDate: e.detail.value?.toDateString(),
              }))
            }
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="workingHours"
            label="Working Hours"
            editor="number"
            disabled={true}
            value={formData.workingHours}
            validation={[{ rule: "matches", args: "[2-8]$" }]}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                workingHours: e.detail.value,
              }))
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Ex: 09:00"
            name="scheduleBegin"
            label="Schedule Begin"
            editor="textbox"
            value={formData.scheduleBegin}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                scheduleBegin: e.detail.value,
              }))
            }
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Ex: 17:00"
            name="scheduleEnd"
            label="Schedule End"
            editor="textbox"
            value={formData.scheduleEnd}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData((formData: EmployeeModel) => ({
                ...formData,
                scheduleEnd: e.detail.value,
              }))
            }
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Office Worker/Home Worker"
            name="workPractice"
            label="Work Practice"
            editor="textbox"
            value={user?.workPractice}
            disabled={true}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TagText
              text="Office Location"
              textStyle={{ fontWeight: "bold", marginBottom: "3px" }}
            />
            <TagCombobox
              inputStyle={{ width: "220px" }}
              inputDisabled
              textField="name"
              valueField="name"
              searchable
              placeholder="Select an office"
              value={formData.officeLocation}
              data={offices}
              onValueChange={(e) => {
                setFormData((formData: EmployeeModel) => ({
                  ...formData,
                  officeLocation: e.detail.value,
                }));
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginLeft: "10px",
            }}
            text="Save"
            icon="CircleTick"
            iconAccent="white"
            accent="viridian-green"
            onButtonClick={() => {
              handleFormData();
            }}
          />
          <TagButton
            buttonStyle={{
              marginTop: "15px",
              marginBottom: "-6px",
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              closeModal();
              setFormData({
                firstName: "",
                lastName: "",
                departmentId: "",
                email: "",
                managerEmail: "",
                workingHours: 0,
                startDate: "",
                scheduleBegin: "",
                scheduleEnd: "",
                marca: "",
                officeLocation: "",
              });
            }}
          ></TagButton>
        </div>
      </TagForm>
    </div>
  );
};
