import {
  IComboChangeArgs,
  TagAlert,
  TagButton,
  TagToast,
  TagText,
  TagCombobox,
  IChangeArgs,
  TagEditField,
  TagForm,
  TagList,
  TagSearchbox,
  TagPager,
  TagModal,
  TagTabs,
} from "@tag/tag-components-react-v2";

import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { Divider } from "antd";
import { HolidayModel } from "../../common/Models/HolidayModel";
import { HolidaysAdminModel } from "../../common/Models/HolidaysAdminModel";
import { ProfileModel } from "../../common/Models/ProfileModel";
import { AddUserForm } from "./AddUserForm";
import { EmployeeModel } from "../../common/Models/EmployeeModel";
import { EditUserForm } from "./EditUserForm";
import { DeleteUserForm } from "./DeleteUserForm";
import { DeleteHolidayForm } from "./DeleteHolidayForm";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { EditUserFormExtension } from "./EditUserFormExtension";
import { WorkLocationExtension } from "./WorkLocationExtension";
import { UpdateWorkHours } from "../../common/Models/UpdateWorkHours";
import { WorkTypesModel } from "../../common/Models/WorkTypesModel";
import { ManagerStatsModel } from "../../common/Models/ManagerStatsModel";
import { ValidateEmployees } from "./ValidateEmployees";
import { ValidationManyModel } from "../../common/Models/ValidationManyModel";
import { UpdateTimesheetAdminModel } from "../../common/Models/UpdateTimesheetAdminModel";

export interface ReportsPageProps {
  onAddHolidayAdmin: (holiday: HolidayModel, email: string) => void;
  onDeleteHolidayAdmin: (id: string) => void;
  onAddOrEditEmployeeAdmin: (employee: EmployeeModel, id: number) => void;
  onDeleteEmployeeAdmin: (id: number, leaveDate: Date) => void;
  onUpdateWorkHours: (id: number, workHours: UpdateWorkHours) => void;
  onUpdateWorkLocation: (id: string, workTypeId: number) => Promise<void>;
  messageForAdmin: any;
  messageAdminDelete: any;
  holidaysAdmin: HolidaysAdminModel[];
  employees: ProfileModel[];
  messageAddEmployeeAdmin: any;
  messageUpdateWorkLocation: any;
  messageDeleteEmployeeAdmin: any;
  departments: DepartmentModel[];
  workTypes: WorkTypesModel[];
  statsForAdmin: ManagerStatsModel[];
  statsForAdminStartDate: Date;
  statsForAdminEndDate: Date;
  onSetStatsForAdminStartDate: (begin: Date) => void;
  onSetStatsForAdminEndDate: (end: Date) => void;
  onAdminValidateMany: (days: ValidationManyModel[]) => void;
  onAdminValidateAll: (begin: Date, end: Date, statusCode: number) => void;
  onAdminFillWeekAll: (begin: Date, end: Date) => void;
  onUpdateTimesheetAdmin: (id: number, day: UpdateTimesheetAdminModel) => void;
  messageForAdminUpdateTimesheet: any;
  loading: boolean;
}

export const EmployeesPage = ({
  onAddHolidayAdmin,
  messageForAdmin,
  holidaysAdmin,
  onDeleteHolidayAdmin,
  messageAdminDelete,
  employees,
  onAddOrEditEmployeeAdmin,
  onDeleteEmployeeAdmin,
  messageAddEmployeeAdmin,
  messageDeleteEmployeeAdmin,
  messageUpdateWorkLocation,
  departments,
  onUpdateWorkHours,
  onUpdateWorkLocation,
  workTypes,
  statsForAdmin,
  statsForAdminStartDate,
  statsForAdminEndDate,
  onSetStatsForAdminStartDate,
  onSetStatsForAdminEndDate,
  onAdminValidateAll,
  onAdminValidateMany,
  onUpdateTimesheetAdmin,
  messageForAdminUpdateTimesheet,
  onAdminFillWeekAll,
  loading,
}: ReportsPageProps) => {
  const [generateMessage, setGenerateMessage] = useState<boolean>(false);
  const [messageOnAdd, setMessageOnAdd] = useState<boolean>(false);
  const [messageOnDelete, setMessageOnDelete] = useState<boolean>(false);
  const [messageOnAddUser, setMessageOnAddUser] = useState<boolean>(false);
  const [messageOnUpdateWorkLocation, setMessageOnUpdateWorkLocation] =
    useState<boolean>(false);
  const [meessageOnDeleteUser, setMessageOnDeleteUser] =
    useState<boolean>(false);

  const [object, setObject] = useState({
    id: null,
    type: null,
    begin: null,
    end: null,
  });

  const [email, setEmail] = useState<string | undefined>();
  const [page, setPage] = useState(1);
  const [pageH, setPageH] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [searchStringAdmin, setSearchStringAdmin] = useState("");
  const [addUserFormCreateModal, setAddUserFormCreateModal] = useState(false);
  const [editUserFormModal, setEditUserFormModal] = useState(false);
  const [deleteUserFormModal, setDeleteUserFormModal] = useState(false);
  const [deleteHolidayFormModal, setDeleteHolidayFormModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileModel | null>(
    null
  );
  const [selectedHoliday, setSelectedHoliday] = useState<string | null>(null);

  employees.sort((a, b) => a.fullName.localeCompare(b.fullName));
  //employees.map((employee) => !employee.isFired);

  const employeesForSearch = employees
    //.filter((e) => e.isFired === false)
    .map((e) => ({
      id: e.id,
      fullName: e.fullName,
      email: e.email,
    }));

  holidaysAdmin.sort((a, b) =>
    a.profile.fullName.localeCompare(b.profile.fullName)
  );
  const pageSize = 5;

  const notFired = employees//.filter((item) => !item.isFired);

  const pageSearch = notFired.filter(
    (a) =>
      a.fullName.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.marca.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.email.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
      a.department.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
  );

  const pageOfData = pageSearch
    .slice((page - 1) * pageSize, page * pageSize)
    .map((obj) => ({
      ...obj,
      scheduleBegin: obj.scheduleBegin.substring(0, 5)
        ? obj.scheduleBegin.substring(0, 5)
        : "00:00",
      scheduleEnd: obj.scheduleEnd.substring(0, 5)
        ? obj.scheduleEnd.substring(0, 5)
        : "00:00",
      departmentName: obj.department.name,
    }));

  const countAfterSearch = pageSearch.length;
  const pageCountSearch = Math.ceil(countAfterSearch / pageSize);

  const handleSearch = (e: any) => {
    setSearchString(e.detail.criteria);
    setPage(1);
  };

  const handleSearchAdmin = (e: any) => {
    setSearchStringAdmin(e.detail.criteria);
    setPageH(1);
  };

  const currentBegin = new Date();
  const pageSizeHolidays = 5;

  const filteredHolidays = holidaysAdmin.filter(
    (a) =>
      a.profile.firstName
        .toLowerCase()
        .indexOf(searchStringAdmin.toLowerCase()) > -1 ||
      a.profile.lastName
        .toLowerCase()
        .indexOf(searchStringAdmin.toLowerCase()) > -1 ||
      a.profile.email.toLowerCase().indexOf(searchStringAdmin.toLowerCase()) >
        -1 ||
      a.profile.marca.toLowerCase().indexOf(searchStringAdmin.toLowerCase()) >
        -1
  );

  const pageOfHolidays = filteredHolidays
    .slice((pageH - 1) * pageSizeHolidays, pageH * pageSizeHolidays)
    .map((obj) => ({
      ...obj,
      begin: obj.holiday.begin,
      end: obj.holiday.end,
      typeName: obj.holiday.type ? obj.holiday.type.shortName : "",
      accent: obj.holiday.type ? obj.holiday.type.accent : "",
      icon: obj.holiday.type ? obj.holiday.type.icon : "",
      model: obj,
      profile: obj.profile.fullName,
      id: obj.holiday.id,
    }));

  const holidaysCount = filteredHolidays.length;
  const holidaysPageCount = Math.ceil(holidaysCount / pageSizeHolidays);

  const handleButton = (buttonIndex: number, item: ProfileModel) => {
    if (buttonIndex === 1) {
      setEditUserFormModal(true);
      setSelectedProfile(item);
    }
    if (buttonIndex === 2) {
      setDeleteUserFormModal(true);
      setSelectedProfile(item);
    }
  };

  const handleSetPageOnDeleteLastUser = () => {
    if (pageOfData.length === 1) {
      setPage(page - 1);
    }
  };

  const handleHoliday = (id: string) => {
    setDeleteHolidayFormModal(true);
    setSelectedHoliday(id);
  };

  return (
    <div
      style={{
        padding: "10px 15px 0px 15px",
      }}
    >
      <Divider orientation="left">Employees</Divider>

      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "3px",
          }}
        >
          <TagSearchbox
            style={{ flexGrow: 1, paddingRight: 10 }}
            placeholder="Search by Name, Email, Marca or Department..."
            value={searchString}
            clearButton={true}
            onSearch={(e) => handleSearch(e)}
          />

          <TagButton
            style={{ paddingRight: "10px" }}
            accent="viridiangreen"
            icon="Plus"
            iconAccent="porcelain"
            rounded
            onClick={() => setAddUserFormCreateModal(true)}
          ></TagButton>
        </div>
        <TagEditField
          value={"Records: " + countAfterSearch.toString()}
          name="Total"
          editor="textbox"
          disabled={true}
          inline={true}
        />
        <TagList
          containerStyle={{ minHeight: "300px" }}
          leftIcon="User"
          leftIconAccent="atoll"
          leftIconRoundedBackground
          primaryField="fullName"
          leftField1="marca"
          leftField2="email"
          leftField1Inline
          leftField2Inline
          pill1Field="departmentName"
          pill1FieldType="string"
          pill1Style={{ minWidth: "150px", justifyContent: "center" }}
          pill1TextStyle={{ textAlign: "center" }}
          pillAlign="right"
          pill2Field="workingHours"
          pill2FieldType="integer"
          pill3Field="startDate"
          pill3FieldType="date"
          pill4Field="scheduleBegin"
          pill4FieldAccent="access"
          pill5Field="scheduleEnd"
          pill5FieldAccent="access"
          data={pageOfData}
          pageSize={pageSize}
          button1Icon="Edit"
          button2Icon="Delete"
          button1HostStyle={{ order: 1 }}
          button2HostStyle={{ order: 2 }}
          onButtonClick={(e) =>
            handleButton(e.detail.buttonIndex, e.detail.item)
          }
        />
        <TagPager
          page={page}
          pageCount={pageCountSearch}
          accent="viridiangreen"
          showFirstLast
          onPageRequest={(e) => setPage(e.detail.page)}
        />
      </div>

      <Divider orientation="left" className="pt-4">
        Leaves
      </Divider>
      <div
        className="row"
        style={{
          display: "flex",
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <div className="col-xs-auto pl-4">
          <TagForm inline submitButtonHidden formStyle={{ border: "none" }}>
            <div style={{ margin: "10px 0px 10px 0px" }}>
              <TagCombobox
                inputDisabled
                clearButton
                searchable
                value={email}
                textField="fullName"
                valueField="email"
                placeholder="Enter Employee Name"
                data={employeesForSearch}
                onValueChange={(e: CustomEvent<IComboChangeArgs>) => {
                  setEmail(e.detail.value);
                }}
              />
            </div>

            <div style={{ margin: "10px 0px 10px 0px" }}>
              <TagEditField
                value={object.begin ? object.begin : currentBegin}
                name="dateIn"
                placeholder="Select a working date..."
                label="Start"
                editor="datepick"
                onValueChange={(e: CustomEvent<IChangeArgs>) =>
                  setObject({
                    begin: e.detail.value?.toDateString(),
                    end: object.end,
                    type: object.type,
                    id: null,
                  })
                }
              />
            </div>

            <div style={{ margin: "10px 0px 10px 0px" }}>
              <TagEditField
                value={object.end ? object.end : currentBegin}
                name="dateOut"
                placeholder="Select a working date..."
                label="End"
                editor="datepick"
                onValueChange={(e: CustomEvent<IChangeArgs>) =>
                  setObject({
                    begin: object.begin,
                    end: e.detail.value?.toDateString(),
                    type: object.type,
                    id: null,
                  })
                }
              />
            </div>

            <div style={{ margin: "10px 0px 10px 0px" }}>
              <TagText
                text="Others"
                textStyle={{ fontWeight: "bold" }}
              ></TagText>

              <TagCombobox
                value={object.type ? object.type : 5}
                textField="name"
                valueField="id"
                placeholder="Pick a disciplinary action"
                data={[
                  { id: 1, name: "CO – Concediu Odihna" },
                  { id: 2, name: "CM – Concediu Medical" },
                  { id: 3, name: "CPL – Concediu Platit Evenimente" },
                  { id: 4, name: "CFS – Concediu Fara Salar" },
                  { id: 7, name: "CIC – Concediu Ingrijire copil" },
                  { id: 5, name: "AN – Absenta nemotivata" },
                  { id: 6, name: "IP – Invoire platita" },
                  { id: 8, name: "D – Delegatie"},
                  { id: 9, name: "BB – Big Break"}
                ]}
                onValueChange={(e: CustomEvent<IComboChangeArgs>) =>
                  setObject({
                    begin: object.begin,
                    end: object.end,
                    type: e.detail.value,
                    id: null,
                  })
                }
              />
            </div>
            <TagButton
              style={{ paddingTop: "10px" }}
              text="Save"
              disabled={email === undefined}
              size="default"
              accent={email === undefined ? "navy" : "viridian-green"}
              icon="CircleTick"
              type="submit"
              iconAccent={"porcelain"}
              onClick={() => {
                const holidayforAdmin = {
                  id: null,
                  begin: object.begin ? object.begin : currentBegin,
                  end: object.end ? object.end : currentBegin,
                  typeId: object.type ? object.type : 5,
                  type: null,
                };
                if (email !== undefined) {
                  onAddHolidayAdmin(holidayforAdmin, email);
                  setMessageOnDelete(false);
                  setGenerateMessage(false);
                  setMessageOnAddUser(false);
                  setMessageOnDeleteUser(false);
                  setMessageOnAdd(true);
                }

                setObject({ id: null, begin: null, end: null, type: null });
                setEmail(undefined);
              }}
            ></TagButton>
          </TagForm>
        </div>
        <div className="col offset-1">
          <TagSearchbox
            style={{ flexGrow: 1, paddingRight: "10px" }}
            placeholder="Search by Name, Email or Marca..."
            value={searchStringAdmin}
            clearButton={true}
            onSearch={(e) => handleSearchAdmin(e)}
          />
          <TagEditField
            value={"Records: " + holidaysCount.toString()}
            name="Total"
            editor="textbox"
            disabled={true}
            inline={true}
          />
          <TagList
            containerStyle={{ minHeight: "285px" }}
            leftIcon="User"
            leftIconAccent="atoll"
            leftIconRoundedBackground
            primaryField="profile"
            primaryFieldInline
            pillAlign="right"
            pill1Field="typeName"
            pill1FieldType="string"
            pill1FieldAccentField="accent"
            data={pageOfHolidays}
            pageSize={pageSizeHolidays}
            rightField2="begin"
            rightField3="end"
            rightField2Type="date"
            rightField3Type="date"
            rightField2Inline
            rightField3Inline
            button1Icon="Delete"
            button1HostStyle={{ order: 2 }}
            onButtonClick={(e) => {
              handleHoliday(e.detail.item.id!);
              if (filteredHolidays.length === 1) {
                setPageH(pageH - 1);
              }
            }}
          />
          <TagPager
            page={pageH}
            pageCount={holidaysPageCount}
            accent="viridiangreen"
            showFirstLast
            onPageRequest={(e) => setPageH(e.detail.page)}
          />
        </div>
      </div>
      <Divider orientation="left" className="pt-4">
        Admin Validation
      </Divider>
      <ValidateEmployees
        statsForAdmin={statsForAdmin}
        statsForAdminStartDate={statsForAdminStartDate}
        statsForAdminEndDate={statsForAdminEndDate}
        onSetStatsForAdminStartDate={onSetStatsForAdminStartDate}
        onSetStatsForAdminEndDate={onSetStatsForAdminEndDate}
        onAdminValidateMany={onAdminValidateMany}
        onAdminValidateAll={onAdminValidateAll}
        onUpdateTimesheetAdmin={onUpdateTimesheetAdmin}
        onAdminFillWeekAll={onAdminFillWeekAll}
        loading={loading}
        messageForAdminUpdateTimesheet={messageForAdminUpdateTimesheet}
      />

      <Col xs="auto">
        <Row className={"pt-2"}>
          {generateMessage ? (
            <TagToast position="bottom-left">
              <TagAlert
                type="success"
                text="Your download will start in a few seconds..."
                showDuration={4000}
                alertType="toast"
                onClosed={() => {
                  setGenerateMessage(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageForAdmin && messageOnAdd ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={messageForAdmin.status == 200 ? "success" : "warning"}
                text={
                  messageForAdmin.status == 200
                    ? messageForAdmin.data
                    : messageForAdmin.data
                }
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnAdd(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <TagModal
        heading="Add a new User"
        borderAccent="viridiangreen"
        width="small"
        onClosed={() => {
          setAddUserFormCreateModal(false);
        }}
        visible={addUserFormCreateModal}
      >
        <AddUserForm
          onAddOrEditEmployeeAdmin={onAddOrEditEmployeeAdmin}
          closeModal={() => setAddUserFormCreateModal(false)}
          messageAddUser={() => {
            setMessageOnDelete(false);
            setGenerateMessage(false);
            setMessageOnDeleteUser(false);
            setMessageOnAdd(false);
            setMessageOnAddUser(true);
          }}
          modalVisibility={addUserFormCreateModal}
          departments={departments}
        />
      </TagModal>
      <TagModal
        heading="Edit User"
        borderAccent="viridiangreen"
        width="small"
        suppressCloseIcon
        onClosed={() => setEditUserFormModal(false)}
        visible={editUserFormModal}
      >
        <TagTabs
          tabs={[
            {
              caption: "General",
              name: "tab1",
              renderContent: () => (
                <div style={{ height: "490px" }}>
                  <EditUserForm
                    user={selectedProfile}
                    onAddOrEditEmployeeAdmin={onAddOrEditEmployeeAdmin}
                    closeModal={() => {
                      setEditUserFormModal(false);
                      setSelectedProfile(null);
                    }}
                    messageAddUser={() => {
                      setMessageOnDelete(false);
                      setGenerateMessage(false);
                      setMessageOnDeleteUser(false);
                      setMessageOnAdd(false);
                      setMessageOnAddUser(true);
                    }}
                    departments={departments}
                  />
                </div>
              ),
              selected: true,
            },
            {
              caption: "Update Working Hours",
              name: "tab2",
              renderContent: () => (
                <div style={{ height: "490px" }}>
                  <EditUserFormExtension
                    user={selectedProfile}
                    closeModal={() => {
                      setSelectedProfile(null);
                      setEditUserFormModal(false);
                    }}
                    onUpdateWorkHours={onUpdateWorkHours}
                    messageAddUser={() => {
                      setMessageOnDelete(false);
                      setGenerateMessage(false);
                      setMessageOnDeleteUser(false);
                      setMessageOnAdd(false);
                      setMessageOnAddUser(true);
                      setSelectedProfile(null);
                    }}
                  />
                </div>
              ),
            },
            {
              caption: "Work Location",
              name: "tab3",
              renderContent: () => (
                <div style={{ height: "490px" }}>
                  <WorkLocationExtension
                    user={selectedProfile}
                    workTypes={workTypes}
                    onUpdateWorkLocation={onUpdateWorkLocation}
                    messageUpdateWorkLocation={() => {
                      setMessageOnDelete(false);
                      setGenerateMessage(false);
                      setMessageOnDeleteUser(false);
                      setMessageOnAdd(false);
                      setMessageOnAddUser(false);
                      setMessageOnUpdateWorkLocation(true);
                    }}
                    closeModal={() => {
                      setSelectedProfile(null);
                      setEditUserFormModal(false);
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      </TagModal>
      <TagModal
        heading="Delete User"
        borderAccent="access"
        width="small"
        suppressCloseIcon
        onClosed={() => setDeleteUserFormModal(false)}
        visible={deleteUserFormModal}
      >
        <DeleteUserForm
          user={selectedProfile}
          onDeleteEmployeeAdmin={onDeleteEmployeeAdmin}
          closeModal={() => {
            setDeleteUserFormModal(false);
            setSelectedProfile(null);
          }}
          messageDeleteUser={() => {
            setMessageOnDelete(false);
            setGenerateMessage(false);
            setMessageOnAddUser(false);
            setMessageOnAdd(false);
            setMessageOnDeleteUser(true);
          }}
          departments={departments}
          setPageOnDeleteLastUser={handleSetPageOnDeleteLastUser}
        />
      </TagModal>
      <TagModal
        heading="Are you sure?"
        borderAccent="access"
        width="small"
        suppressCloseIcon
        onClosed={() => setDeleteHolidayFormModal(false)}
        visible={deleteHolidayFormModal}
      >
        <DeleteHolidayForm
          id={selectedHoliday}
          onDeleteHolidayAdmin={onDeleteHolidayAdmin}
          closeModal={() => {
            setDeleteHolidayFormModal(false);
            setSelectedProfile(null);
          }}
          messageDeleteHoliday={() => {
            setMessageOnAdd(false);
            setGenerateMessage(false);
            setMessageOnAddUser(false);
            setMessageOnDeleteUser(false);
            setMessageOnDelete(true);
          }}
        />
      </TagModal>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageOnAddUser && messageAddEmployeeAdmin ? (
            <TagToast position="bottom-left">
              <TagAlert
                alertStyle={{ maxWidth: "300px" }}
                type={
                  messageAddEmployeeAdmin.status == 200 ? "success" : "warning"
                }
                text={
                  messageAddEmployeeAdmin.status == 200
                    ? "Employee info updated successfully!"
                    : "There was an error!"
                }
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnAddUser(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {meessageOnDeleteUser && messageDeleteEmployeeAdmin ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageDeleteEmployeeAdmin.statusCode == 200
                    ? "success"
                    : "warning"
                }
                text={
                  messageDeleteEmployeeAdmin.statusCode == 200
                    ? "Employee Deleted!"
                    : "Something went wrong!"
                }
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnDeleteUser(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageOnDelete && messageAdminDelete ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageAdminDelete.statusCode == 200 ? "success" : "warning"
                }
                text={
                  messageAdminDelete.statusCode == 200
                    ? "Leave Deleted!"
                    : "Something went wrong!"
                }
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnDelete(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>

      <Col xs="auto">
        <Row className={"pt-2"}>
          {messageOnUpdateWorkLocation && messageUpdateWorkLocation ? (
            <TagToast position="bottom-left">
              <TagAlert
                type={
                  messageUpdateWorkLocation.status == 200
                    ? "success"
                    : "warning"
                }
                text={
                  messageUpdateWorkLocation.status == 200
                    ? "Work Location Modified!"
                    : "Something went wrong!"
                }
                showDuration={6000}
                alertType="toast"
                onClosed={() => {
                  setMessageOnUpdateWorkLocation(false);
                }}
                closeable
              ></TagAlert>
            </TagToast>
          ) : (
            <></>
          )}
        </Row>
      </Col>
    </div>
  );
};
