import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { baseUri } from "../msalAuthConfig";


const aquireToken = async (
  instance: IPublicClientApplication,
  request: SilentRequest
): Promise<string> => {
  let token: string = "";
  await instance
    .acquireTokenSilent({ ...request, account: instance.getAllAccounts()[0] })
    .then(
      (res) => {
        token = res.accessToken;
      },
      (_) => {
        setTimeout(() => instance.loginRedirect(request), 500);
      }
    );
  return token;
};

const login = async (creds: any): Promise<string> => {
  const response = await fetch(baseUri + "/api/Profile/GetAccessToken", {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Accept: "text/json",
    },
    body: JSON.stringify(creds),
  });
  return response.json();
};


export const msalAuth = {
  aquireToken: aquireToken,
  login: login,
};
