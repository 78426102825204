import ReactECharts from "echarts-for-react";
import { StatsModel } from "../../common/Models/StatsModel";

export interface DepartmentsChartProps {
  statistics: StatsModel | undefined;
}

export const DepartmentsChart = ({ statistics }: DepartmentsChartProps) => {
  const departments = statistics?.departmentsStats!;
  const employeesOnDepartments = departments?.map((obj) => ({
    value: obj.count,
    name: obj.department.name,
  }));

  const option = {
    title: {
      text: "Departments",
      subtext: "Romania",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Employees",
        type: "pie",
        radius: "50%",
        data: employeesOnDepartments,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <ReactECharts
      option={option}
      style={{
        height: 400,
        width: "100%",
        paddingTop: "15px",
        paddingLeft: "20px",
      }}
      opts={{ renderer: "svg" }}
    />
  );
};
