import {
  IChangeArgs,
  TagAlert,
  TagButton,
  TagCombobox,
  TagEditField,
  TagForm,
  TagText,
  TagToast,
} from "@tag/tag-components-react-v2";
import moment from "moment";
import { useEffect, useState } from "react";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { EmployeeModel } from "../../common/Models/EmployeeModel";

export interface AddUserProps {
  onAddOrEditEmployeeAdmin: (employee: EmployeeModel, id: number) => void;
  closeModal: () => void;
  messageAddUser: () => void;
  modalVisibility: boolean;
  departments: DepartmentModel[];
}
export const AddUserForm = ({
  onAddOrEditEmployeeAdmin,
  closeModal,
  messageAddUser,
  modalVisibility,
  departments,
}: AddUserProps) => {

  const offices = [
    {
      name: "Arad"
    },
    {
      name: "Timisoara"
    },
    {
      name: "Remote"
    }
  ]

  const [formData, setFormData] = useState<any>({
    firstName: undefined,
    lastName: undefined,
    department: undefined,
    email: undefined,
    managerEmail: undefined,
    workingHours: undefined,
    startDate: undefined,
    scheduleBegin: undefined,
    scheduleEnd: undefined,
    marca: undefined,
    officeLocation: undefined,

  });
  const [errorSchedule, setErrorSchedule] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      firstName: undefined,
      lastName: undefined,
      department: undefined,
      email: undefined,
      managerEmail: undefined,
      workingHours: undefined,
      startDate: undefined,
      scheduleBegin: undefined,
      scheduleEnd: undefined,
      marca: undefined,
      officeLocation: undefined,
    });
  }, [modalVisibility]);

  const isInvalid =
    formData.firstName === undefined ||
    formData.lastName === undefined ||
    formData.department === undefined ||
    formData.email === undefined ||
    formData.managerEmail === undefined ||
    formData.workingHours === undefined ||
    formData.scheduleBegin === undefined ||
    formData.scheduleEnd === undefined ||
    formData.startDate === undefined ||
    formData.marca === undefined ||
    formData.officeLocation === undefined;

  function handleFormData() {
    const addEmployee: EmployeeModel = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      departmentId: formData.department,
      email: formData.email,
      managerEmail: formData.managerEmail,
      workingHours: formData.workingHours,
      startDate: formData.startDate,
      scheduleBegin: formData.scheduleBegin,
      scheduleEnd: formData.scheduleEnd,
      marca: formData.marca,
      officeLocation: formData.officeLocation,
    };
    onAddOrEditEmployeeAdmin(addEmployee, 0);
    if (
      formData.workingHours >= 8 ||
      (formData.scheduleBegin !== undefined &&
        formData.scheduleEnd !== undefined)
    ) {
      setFormData({
        firstName: undefined,
        lastName: undefined,
        department: undefined,
        email: undefined,
        managerEmail: undefined,
        workingHours: undefined,
        startDate: undefined,
        scheduleBegin: undefined,
        scheduleEnd: undefined,
        marca: undefined,
        officeLocation: undefined,
      });

      closeModal();
    }
    messageAddUser();
  }

  const formStyle: React.CSSProperties = { border: "0px" };
  const editFieldStyle: React.CSSProperties = {
    marginTop: "15px",
  };

  return (
    <div>
      <TagForm
        submitButtonHidden
        submitButtonText="Confirm"
        submitButtonAccent="viridiangreen"
        submitButtonAlign="right"
        formStyle={formStyle}

        inline
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="firstName"
            label="First Name"
            editor="textbox"
            value={formData.firstName}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: e.detail.value,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "matches", args: "^[a-zA-Z]+$" }]}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="lastName"
            label="Last Name"
            editor="textbox"
            value={formData.lastName}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: e.detail.value,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "matches", args: "^[a-zA-Z]+$" }]}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="email"
            label="Email"
            editor="email"
            value={formData.email}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: e.detail.value,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "email" }]}
          />
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="managerEmail"
            label="Manager Email"
            editor="email"
            value={formData.managerEmail}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: e.detail.value,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "email" }]}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="marca"
            label="Marca"
            editor="textbox"
            value={formData.marca}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: e.detail.value,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "matches", args: "^[a-zA-Z0-9]*$" }]}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TagText
              text="Department"
              textStyle={{ fontWeight: "bold", marginBottom: "3px" }}
            />
            <TagCombobox
              inputStyle={{ width: "220px" }}
              inputDisabled
              textField="name"
              valueField="id"
              placeholder="Select a department"
              searchable
              value={formData.department}
              data={departments}
              onValueChange={(e) => {
                setFormData({
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  department: e.detail.value,
                  email: formData.email,
                  managerEmail: formData.managerEmail,
                  workingHours: formData.workingHours,
                  startDate: formData.startDate,
                  scheduleBegin: formData.scheduleBegin,
                  scheduleEnd: formData.scheduleEnd,
                  marca: formData.marca,
                  officeLocation: formData.officeLocation,
                });
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            containerStyle={editFieldStyle}
            name="startDate"
            label="Start Date"
            editor="datepick"
            value={formData.startDate}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: e.detail.value?.toDateString(),
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            name="workingHours"
            label="Working Hours"
            editor="number"
            value={formData.workingHours}
            validation={[{ rule: "matches", args: "[2,4,6,8]$" }]}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: e.detail.value,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="09:00"
            name="scheduleBegin"
            label="Schedule Begin"
            editor="textbox"
            value={formData.scheduleBegin}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: e.detail.value,
                scheduleEnd: formData.scheduleEnd,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />

          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="17:00"
            name="scheduleEnd"
            label="Schedule End"
            editor="textbox"
            value={formData.scheduleEnd}
            onValueChange={(e: CustomEvent<IChangeArgs>) =>
              setFormData({
                firstName: formData.firstName,
                lastName: formData.lastName,
                department: formData.department,
                email: formData.email,
                managerEmail: formData.managerEmail,
                workingHours: formData.workingHours,
                startDate: formData.startDate,
                scheduleBegin: formData.scheduleBegin,
                scheduleEnd: e.detail.value,
                marca: formData.marca,
                officeLocation: formData.officeLocation,
              })
            }
            validation={[{ rule: "matches", args: "^[01][0-9]:[0-5][0-9]$" }]}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TagEditField
            editorStyle={{ width: "220px" }}
            containerStyle={editFieldStyle}
            placeholder="Default: Home Worker"
            name="workPractice"
            label="Work Practice"
            editor="textbox"
            disabled={true}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>

            <TagText
              text="Office Location"
              textStyle={{ fontWeight: "bold", marginBottom: "3px" }}
            />
            <TagCombobox
              inputStyle={{ width: "220px" }}
              inputDisabled
              textField="name"
              valueField="name"
              searchable
              placeholder="Select an office"
              value={formData.officeLocation}
              data={offices}
              onValueChange={(e) => {
                setFormData({
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  department: formData.department,
                  email: formData.email,
                  managerEmail: formData.managerEmail,
                  workingHours: formData.workingHours,
                  startDate: formData.startDate,
                  scheduleBegin: formData.scheduleBegin,
                  scheduleEnd: formData.scheduleEnd,
                  marca: formData.marca,
                  officeLocation: e.detail.value,
                });
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <TagButton
            disabled={isInvalid ? true : false}
            buttonStyle={{
              marginTop: "20px",
              marginBottom: "-6px",
              marginLeft: "10px",
            }}
            text="Save"
            icon="CircleTick"
            iconAccent="white"
            onButtonClick={() => {
              const diff = moment
                .utc(
                  moment(formData.scheduleEnd, "HH:mm:ss").diff(moment(formData.scheduleBegin, "HH:mm:ss"))
                )
                .format("HH:mm:ss");
              const hours = moment.duration(diff).asHours();
              if (hours == formData.workingHours) {
                setErrorSchedule(false);
                handleFormData();
              }
              else {
                setErrorSchedule(true);
              }
            }
            }
            accent={isInvalid ? "navy" : "viridian-green"}
          />

          <TagButton
            buttonStyle={{
              marginTop: "20px",
              marginBottom: "-6px",
              marginRight: "10px",
            }}
            text="Exit"
            accent="title"
            icon="Exit"
            iconAccent="porcelain"
            onClick={() => {
              closeModal();
              setErrorSchedule(false);
              setFormData({
                firstName: undefined,
                lastName: undefined,
                department: undefined,
                email: undefined,
                managerEmail: undefined,
                workingHours: undefined,
                startDate: undefined,
                scheduleBegin: undefined,
                scheduleEnd: undefined,
                marca: undefined,
              })
            }
            }
          />
        </div>

        {errorSchedule ? (
          <TagToast position="bottom-left">
            <TagAlert
              alertStyle={{ maxWidth: "300px" }}
              type={"warning"}
              text={"Schedule doesn't match the selected Working Hours"}
              alertType="toast"
              onClosed={() => {
                setErrorSchedule(false);

              }}
            ></TagAlert>
          </TagToast>
        ) : (
          <></>
        )}
      </TagForm>
    </div>
  );
};
