import styles from "./ProfilePage.module.scss";
import {
  TagAlert,
  TagButton,
  TagEditField,
  TagForm,
  TagFormGroup,
  TagIcon,
  TagPills,
  TagText,
} from "@tag/tag-components-react-v2";
import { Col, Container, Row } from "react-bootstrap";
import { TimePicker } from "antd";
import { help } from "../../common/Constants";
import moment from "moment";
import { useState } from "react";
import { DepartmentModel } from "../../common/Models/DepartmentModel";
import { WorkLocationScheduleModel } from "../../common/Models/WorkLocationScheduleModel";

const { RangePicker } = TimePicker;

export interface ProfilePageProps {
  email: string;
  fullName: string;
  managerEmail: string;
  startDate: string;
  department: DepartmentModel;
  shiftBegin: string;
  shiftEnd: string;
  scheduleBegin: string;
  scheduleEnd: string;
  workingHours: Number;
  isFired: boolean;
  workLocationSchedule: WorkLocationScheduleModel[];
  workPractice: string;
  officeLocation: string;
  onUpdate: (begin: string, end: string) => void;
}

export const ProfilePage = ({
  email,
  fullName,
  managerEmail,
  startDate,
  department,
  shiftBegin,
  shiftEnd,
  scheduleBegin,
  scheduleEnd,
  workingHours,
  isFired,
  workLocationSchedule,
  workPractice,
  officeLocation,
  onUpdate,
}: ProfilePageProps) => {
  const [begin, setBegin] = useState<any>(scheduleBegin);
  const [end, setEnd] = useState<any>(scheduleEnd);
  const [save, setSave] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorHours, setErrorHours] = useState<boolean>(false);

  const refreshBegin = (begin: any) => {
    setBegin(begin);
  };

  const refreshEnd = (end: any) => {
    setEnd(end);
  };

  const home = workLocationSchedule
    .filter((w) => w.workType.id === 2)
    .map(({ dayOfWeek }) => dayOfWeek.substr(0, 3));
  const office = workLocationSchedule
    .filter((w) => w.workType.id === 1)
    .map(({ dayOfWeek }) => dayOfWeek.substr(0, 3));

  return (
    <div className={styles.ProfilePage}>
      <Container fluid={true} className={"pt-3"}>
        <Row>
          <Col xs={"auto"} className={"text-align-center"}>
            <h3>{fullName}</h3>
            <TagPills
              pillStyle={{ cursor: "default" }}
              pillDirection="row"
              pillAlign="left"
              pillSize="medium"
              pills={[
                {
                  action: false,
                  text: department.name,
                },
              ]}
            />
          </Col>
          <Col xs={12} className={"pt-4"}>
            <TagForm submitButtonDisabled submitButtonHidden>
              <TagFormGroup>
                <TagEditField
                  name="email"
                  label="Email"
                  value={email}
                  disabled
                />
              </TagFormGroup>
              <TagFormGroup>
                <TagEditField
                  name="managerEmail"
                  label="Manager email"
                  editor="email"
                  value={managerEmail}
                  disabled
                />
              </TagFormGroup>
              <TagFormGroup>
                <TagEditField
                  name="startDate"
                  label="Start Date"
                  editor="datepick"
                  value={startDate}
                  disabled
                />
              </TagFormGroup>
              <TagFormGroup>
                <TagEditField
                  name="hours"
                  label="Working hours"
                  editor="number"
                  value={workingHours}
                  disabled
                />
              </TagFormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TagEditField
                    editorStyle={{ width: "220px" }}
                    name="workPattern"
                    label="Office"
                    editor="textbox"
                    value={office}
                    disabled
                  />
                  <TagIcon
                    style={{ marginBottom: "-8px", marginLeft: "2px" }}
                    icon="MultiBuilding"
                    accent="grey-3"
                    size="large"
                  />
                </div>

                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TagEditField
                    editorStyle={{ width: "220px" }}
                    name="workPattern"
                    label="Home"
                    editor="textbox"
                    value={home}
                    disabled
                  />
                  <TagIcon
                    style={{ marginBottom: "-6px", marginLeft: "2px" }}
                    icon="Home"
                    accent="grey-3"
                    size="large"
                  />
                </div>

                <TagEditField
                  editorStyle={{ width: "220px" }}
                  name="workPractice"
                  label="Work Practice"
                  editor="textbox"
                  value={workPractice}
                  disabled
                />
                <TagEditField
                  editorStyle={{ width: "220px" }}
                  name="officeLocation"
                  label="Office Location"
                  editor="textbox"
                  value={officeLocation}
                  disabled
                />
              </div>
              <TagFormGroup>
                <TagText
                  accent="title"
                  text={"Working schedule"}
                  textStyle={{ marginTop: 0, marginBottom: 0, fontWeight: 700 }}
                />
                <RangePicker
                  style={{ width: "220px" }}
                  minuteStep={15}
                  format={help.format}
                  disabled={
                    workingHours === 2 ||
                    workingHours === 4 ||
                    workingHours === 6 ||
                    isFired
                  }
                  defaultValue={[
                    scheduleBegin === null
                      ? null
                      : moment(scheduleBegin, help.format),
                    scheduleEnd === null
                      ? null
                      : moment(scheduleEnd, help.format),
                  ]}
                  disabledHours={() => {
                    const minHour = parseInt(shiftBegin.split(":")[0]);
                    const maxHour = parseInt(shiftEnd.split(":")[0]);
                    return Array.from({ length: 24 }, (v, k) => k).filter(
                      (hour) => hour < minHour || hour > maxHour
                    );
                  }}
                  hideDisabledOptions
                  onChange={(dates) => {
                    refreshBegin(dates?.[0]?.format("HH:mm:ss"));
                    refreshEnd(dates?.[1]?.format("HH:mm:ss"));
                  }}
                />
              </TagFormGroup>
              <div slot="footer">
                <TagButton
                  size="large"
                  icon="Save"
                  text="Save"
                  disabled={
                    workingHours === 2 ||
                    workingHours === 4 ||
                    workingHours === 6 ||
                    isFired
                  }
                  accent={
                    workingHours === 2 ||
                      workingHours === 4 ||
                      workingHours === 6 ||
                      isFired
                      ? "navy"
                      : "viridiangreen"
                  }
                  color={"white"}
                  iconAccent={"porcelain"}
                  onButtonClick={() => {
                    const diff = moment
                      .utc(
                        moment(end, "HH:mm:ss").diff(moment(begin, "HH:mm:ss"))
                      )
                      .format("HH:mm:ss");
                    const hours = moment.duration(diff).asHours();

                    if (begin && end && hours === workingHours) {
                      onUpdate(begin, end);
                      setError(false);
                      setErrorHours(false);
                      setSave(true);
                    } else if (begin && end && hours !== workingHours) {
                      setError(false);
                      setSave(false);
                      setErrorHours(true);
                    } else {
                      setSave(false);
                      setErrorHours(false);
                      setError(true);
                    }
                  }}
                ></TagButton>
              </div>
            </TagForm>
            <Col xs="auto">
              <Row className={"pt-2"}>
                {save ? (
                  <TagAlert
                    type={"success"}
                    text="Schedule Updated!"
                    inline={false}
                    closeable={true}
                    width="150%"
                    onClosed={() => {
                      setSave(false);
                    }}
                    showDuration={6000}
                  />
                ) : (
                  <></>
                )}
              </Row>
            </Col>

            <Col xs="auto">
              <Row className={"pt-2"}>
                {error ? (
                  <TagAlert
                    type={"danger"}
                    text="Fill the working schedule!"
                    inline={false}
                    closeable={true}
                    width="150%"
                    onClosed={() => {
                      setError(false);
                    }}
                    showDuration={6000}
                  />
                ) : (
                  <></>
                )}
              </Row>
            </Col>

            <Col xs="auto">
              <Row className={"pt-2"}>
                {errorHours ? (
                  <TagAlert
                    type={"danger"}
                    text="Work interval doesn't match your working hours"
                    inline={false}
                    closeable={true}
                    width="150%"
                    onClosed={() => {
                      setErrorHours(false);
                    }}
                    showDuration={0}
                  />
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    </div >
  );
};
